/* 매장사진 */
.StorePhoto {
  position: relative;
  width: 100%;
  height: 245px;
}

.BeforePhoto {
  height: calc(100% - 1.8rem);
  background: #f6f6f6;
  border: 1px dashed var(--ColorTypeD);
  border-radius: 5px;
  display: none;
}

.BeforePhoto.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BeforePhoto img {
  max-width: 52px;
}

.BeforePhoto p {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 12px;
}

.AfterPhoto {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
}

.AfterPhoto.active {
  display: block;
}

.AfterPhoto .StoreBg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.AfterPhoto-Content {
  position: absolute;
  left: 0px;
  bottom: 45px;
  width: 100%;
  padding: 0px 2.2rem;
  justify-content: space-between;
  align-items: flex-end;
}

.btn.btn-camera {
  width: 50px;
}

.PhotoCount {
  margin-bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 65px;
  height: 25px;
  border-radius: 3px;
  font-weight: var(--Medium);
  color: #fff;
  font-size: 12px;
  line-height: 25px;
}

/* 상점 정보 */
.StoreDetail {
  position: relative;
  background: #fff;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
  margin-top: -25px;
}

.StoreTitle > .tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 25px;
  margin-bottom: 15px;
  word-break: keep-all;
}

.StoreRate {
  padding: 0 2rem;
  margin-bottom: 30px;
  justify-content: center;
  align-items: flex-end;
  gap: 0.3rem;
}

.StoreRate img {
  max-width: 24px;
}

.StoreRate .rate-txt {
  margin-left: 10px;
  line-height: 16px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.StoreInfoBtn {
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

/* 사업자등록 */
.StoreRegister {
  position: relative;
  margin: auto;
}

.Register-Process {
  background: #f6f6f6;
  border-radius: 20px;
  position: relative;
  padding: 2.5rem 1.8rem;
  text-align: center;
  display: none;
}

.Register-Process.active {
  display: block;
}

.Register_img.basic {
  max-width: 30px;
}

.Register_img.fail {
  max-width: 62px;
}

.Register_img.success {
  max-width: 62px;
}

.Register_tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 16px;
  margin-bottom: 3px;
  margin-top: 30px;
}

.Register_sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 12px;
  margin-bottom: 20px;
}

/* 23.07.05 StoreRegister CSS */
.StoreRegister .StoreRegister-title {
  margin: 5rem 0;
}

.StoreRegister .StoreRegister-title .tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.StoreRegister .StoreRegister-title .tit img {
  max-width: 22px;
  align-self: center;
}

.StoreRegister .StoreRegister-title .sub {
  padding-left: calc(22px + 0.5rem);
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 13px;
}

.StoreRegister .MapSample {
  display: none;
}

.StoreRegister .StoreRegister-title .form-control.InputType:focus + .MapSample {
  display: block;
}

.StoreRegister .progress {
  --bs-progress-height: 6px;
  --bs-progress-border-radius: 0px;
  --bs-progress-bar-bg: var(--ColorTypeA);
}

.StoreRegister .StoreRegister-sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 15px;
  word-break: break-word;
}

.StoreRegister .StoreRegister-sub .point {
  color: var(--ColorTypeA);
  font-size: 17px;
}

.StoreRegister .StoreRegister-error-txt {
  font-weight: var(--Regular);
  color: var(--ColorTypeA);
  font-size: 13px;
  padding: 0.75rem;
}

.StoreRegister .SR-Contents {
  display: none;
  gap: 0.5rem;
}

.StoreRegister .SR-Contents.active {
  display: flex;
}

.StoreRegister .SRChange.SR5_Tab .StoreRegister-sub {
  display: none;
}

.StoreRegister .StoreRegister-StepDot {
  position: relative;
  margin-top: 15vh;
}

.StoreRegister .StoreRegister-StepDot::before {
  content: "";
  position: absolute;
  left: 10%;
  top: 11px;
  width: 80%;
  border-bottom: 2px dashed #ddd;
}

.StoreRegister .StepDot {
  position: relative;
  width: 22px;
  height: 22px;
  background: rgba(195 195 195 / 30%);
  border-radius: 50%;
  margin: 0px auto 4px;
}

.StoreRegister .StepDotBox.active .StepDot {
  background: rgba(237 21 86 / 30%);
}

.StoreRegister .StepDot::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: var(--ColorTypeD);
  border-radius: 50%;
}

.StoreRegister .StepDotBox.active .StepDot::before {
  background: var(--ColorTypeA);
}

.StoreRegister .StepDotBox p {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 14px;
}

/* 2022.11.14 */

.StoreRegister .StoreRegister-title.mt-20vh {
  margin-top: 20vh;
}

/* 모바일 반응형 */

@media screen and (max-width: 320px) {
  .StoreRegister .StoreRegister-title {
    margin: 3rem 0;
  }

  .StoreRegister .StoreRegister-title.mob-mb-5 {
    margin-bottom: 2rem !important;
  }

  .StoreRegister .StoreRegister-title .tit {
    font-size: 20px;
  }
}

/* 2022.12.08 반응형 작업*/

.StoreRegister {
  max-width: 800px;
  margin: auto;
}

.StoreRegister::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

.StoreRegister .navbar {
  max-width: 800px;
  margin: auto;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  border-bottom: 0;
}

.StoreRegister .Inner {
  max-width: 500px;
}

.StoreRegister.border-none::before {
  display: none;
}

.StoreRegister .StoreRegister-StepBox .Inner .btn.type8,
.StoreRegister .StoreRegister-StepBox .Inner .btn.type6 {
  max-width: 100%;
}

.StoreRegister .btn.btn-default:disabled {
  background-color: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

@media screen and (max-width: 800px) {
  .StoreRegister::before {
    display: none;
  }

  .StoreRegister .navbar {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .StoreRegister .responsiveInner {
    max-width: 320px;
  }
}

/* progressbar background color change */
.StoreRegister .progress-bar {
  background: var(--ColorTypeA);
}

/* 탭 컨텐츠 */
.StoreTab-wrap {
  margin-top: 30px;
  padding-bottom: 8.5rem;
}

.StoreTab-wrap.border-top {
  padding-top: 30px;
  border-top: 1px solid var(--ColorTypeC);
}

.StoreTab .nav-tabs {
  justify-content: space-around;
  border-bottom: 0px;
}

.StoreTab .nav-link {
  font-weight: var(--Regular);
  color: var(--ColorTypeD);
  font-size: 20px;
  border: 0px;
  padding: 0.5rem 0.6rem;
}

.StoreTab .nav-link.active {
  position: relative;
  border: 0px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  border-bottom: 3px solid var(--ColorTypeA);
  background: transparent;
}

.StoreTab .nav-link.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0.2rem;
  width: 6px;
  height: 6px;
  background: var(--ColorTypeA);
  border-radius: 50%;
}

.EditBtnGroup {
  gap: 10px;
}

.EditBtnGroup:nth-child(1) {
  margin-top: 35px;
}

.EditBtnGroup .btn.type8,
.EditBtnGroup .btn.type6 {
  max-width: 100%;
}

.ProductList .row {
  align-items: center;
  border-bottom: 1px solid var(--ColorTypeE);
  margin: 0px;
  padding: 2.5rem 2rem;
}

.prd-badge {
  margin-bottom: 0.5em;
}

.badge.badge-default {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 12px;
  border: 1px solid var(--ColorTypeA);
  border-radius: 12px;
}

.prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  margin-bottom: 0.3rem;
}

.prd-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 12px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.prd-price {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
  font-size: 18px;
}

.prd-btnbox {
  gap: 10px;
}

.ProductList .AlertBox {
  margin-top: 35px;
}

.ProductList .row .col-8 {
  padding: 0;
  padding-left: 0.3rem;
}

.ProductList .row .col-4 {
  padding: 0;
  text-align: right;
}

/* 교환권 등록 */

.addprd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.addprd-sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 12px;
}

.Input-placeholderbox {
  position: relative;
}

.Input-placeholder {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0.75rem 1.75rem;
  font-weight: var(--Regular);
  font-size: 12px;
  color: var(--ColorTypeD);
}

.Input-placeholder + textarea {
  position: relative;
}

.Input-placeholderbox textarea.form-control.InputType {
  background: transparent;
}

textarea.form-control.InputType:not(:empty) + .Input-placeholder {
  opacity: 0;
}

.accordion {
  --bs-accordion-color: var(--ColorTypeB);
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color: var(--ColorTypeD);
  --bs-accordion-border-radius: 3px;
  --bs-accordion-inner-border-radius: 3px;
  --bs-accordion-btn-padding-x: 0.75rem;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-color: var(--ColorTypeB);
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0.75rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--ColorTypeB);
  --bs-accordion-active-bg: #fff;
}

.accordion-button {
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 12px;
}

.accordion-button:not(.collapsed) {
  color: var(--ColorTypeB);
  background: #f1f1f1;
  box-shadow: none;
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  padding-top: 0.5rem;
}

.accordion-body.Input-placeholderbox .Input-placeholder {
  padding: 0.5rem 0.75rem;
}

/* 교환권 등록 가이드 */

.AddGuide {
  padding: 2rem 1.5rem 2rem;
}

.AddGuide-Titlebox .tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.AddGuide-Titlebox .tit img {
  width: 17px;
  height: 23px;
  margin-left: 0.5rem;
}

.AddGuide-Titlebox .sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 12px;
}

.Step-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.Step-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.Step-descbox {
  margin-bottom: 5rem;
}

.Step-desc {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 12px;
}

/* 교환권 순서 편집 */

.EditPrdBox.first {
  border-top: 5px solid var(--ColorTypeE);
}

.EditPrdBox {
  border-bottom: 1px solid var(--ColorTypeE);
}

.EditPrdBox .EditPrd-item .row .txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.EditPrdBox .EditPrd-item .row img {
  width: 25px;
}

/* StoreMain 반응형 보완 */
/* 매장사진 */
.StoreMainPage .StorePhoto {
  position: relative;
  width: 100%;
  height: 245px;
}

.StoreMainPage .BeforePhoto {
  height: calc(100% - 1.8rem);
  background: #f6f6f6;
  border: 1px dashed var(--ColorTypeD);
  border-radius: 5px;
  display: none;
}

.StoreMainPage .BeforePhoto.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.StoreMainPage .BeforePhoto img {
  max-width: 52px;
}

.StoreMainPage .BeforePhoto p {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 13px;
}

.StoreMainPage .AfterPhoto {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
}

.StoreMainPage .AfterPhoto.active {
  display: block;
}

.StoreMainPage .AfterPhoto .StoreBg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.StoreMainPage .AfterPhoto-Content {
  position: absolute;
  left: 0px;
  bottom: 45px;
  width: 100%;
  padding: 0px 2.2rem;
  justify-content: space-between;
  align-items: flex-end;
}

.StoreMainPage .btn.btn-camera {
  width: 50px;
}

.StoreMainPage .PhotoCount {
  margin-bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 65px;
  height: 25px;
  border-radius: 3px;
  font-weight: var(--Medium);
  color: #fff;
  font-size: 13px;
  line-height: 25px;
}

/* 상점 정보 */
.StoreMainPage .StoreDetail {
  position: relative;
  background: #fff;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
  margin-top: -25px;
}

.StoreMainPage .StoreTitle > .tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 25px;
  margin-bottom: 15px;
  word-break: break-word;
}

.StoreMainPage .StoreRate {
  padding: 0 2rem;
  margin-bottom: 30px;
  justify-content: center;
  align-items: flex-end;
  gap: 0.3rem;
}

.StoreMainPage .StoreRate img {
  max-width: 24px;
}

.StoreMainPage .StoreRate .rate-txt {
  margin-left: 10px;
  line-height: 16px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.StoreMainPage .StoreInfoBtn {
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

/* 사업자등록 */
.StoreMainPage .StoreRegister {
  position: relative;
  margin: auto;
}

.StoreMainPage .Register-Process {
  background: #f6f6f6;
  border-radius: 20px;
  position: relative;
  padding: 2.5rem 1.8rem;
  text-align: center;
  display: none;
}

.StoreMainPage .Register-Process.active {
  display: block;
}

.StoreMainPage .Register_img.basic {
  max-width: 30px;
}

.StoreMainPage .Register_img.fail {
  max-width: 62px;
}

.StoreMainPage .Register_img.success {
  max-width: 62px;
}

.StoreMainPage .Register_tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 16px;
  margin-bottom: 3px;
  margin-top: 30px;
}

.StoreMainPage .Register_sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
  margin-bottom: 20px;
}

/* 탭 컨텐츠 */
.StoreMainPage .StoreTab-wrap {
  margin-top: 30px;
  padding-bottom: 8.5rem;
}

.StoreMainPage .StoreTab-wrap.boder-top {
  border-top: 1px solid var(--ColorTypeD);
  padding-top: 1.5rem;
}

.StoreMainPage .StoreTab-wrap.border-top {
  padding-top: 30px;
  border-top: 1px solid var(--ColorTypeC);
}

.StoreMainPage .StoreTab .nav-tabs {
  justify-content: space-around;
  border-bottom: 0px;
}

.StoreMainPage .StoreTab .nav-link {
  font-weight: var(--Regular);
  color: var(--ColorTypeD);
  font-size: 20px;
  border: 0px;
  padding: 0.5rem 0.6rem;
}

.StoreMainPage .StoreTab .nav-link.active {
  position: relative;
  border: 0px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  border-bottom: 3px solid var(--ColorTypeA);
  background: transparent;
}

.StoreMainPage .StoreTab .nav-link.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0.2rem;
  width: 6px;
  height: 6px;
  background: var(--ColorTypeA);
  border-radius: 50%;
}

.StoreMainPage .EditBtnGroup {
  gap: 10px;
}

.StoreMainPage .EditBtnGroup:nth-child(1) {
  margin-top: 35px;
}

.StoreMainPage .EditBtnGroup .btn.type8,
.StoreMainPage .EditBtnGroup .btn.type6 {
  max-width: 100%;
}

.StoreMainPage .ProductList .row {
  align-items: center;
  border-bottom: 1px solid var(--ColorTypeE);
  margin: 0px;
  padding: 2.5rem 2rem;
}

.StoreMainPage .prd-badge {
  margin-bottom: 0.5em;
}

.StoreMainPage .badge.badge-default {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 13px;
  border: 1px solid var(--ColorTypeA);
  border-radius: 12px;
}

.StoreMainPage .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.StoreMainPage .prd-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 13px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.StoreMainPage .prd-price {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
  font-size: 18px;
}

.StoreMainPage .prd-btnbox {
  gap: 10px;
}

.StoreMainPage .ProductList .AlertBox.active {
  margin-top: 2rem;
}

.StoreMainPage .ProductList .row .col-8 {
  padding: 0;
  padding-left: 0.3rem;
  padding-right: 0.5rem;
}

.StoreMainPage .ProductList .row .col-4 {
  padding: 0;
  text-align: right;
  border-radius: 5px;
  overflow: hidden;
}

.StoreMainPage .StoreTab-content .ProductList {
  border-bottom: 5px solid var(--ColorTypeE);
}

.StoreMainPage .StoreTab-content .ProductList:last-child {
  border-bottom: 0;
}

.StoreMainPage .prd-catename {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  word-break: break-word;
  margin-bottom: -1.25rem;
}

.StoreMainPage .ProductCateWrap {
  width: 100%;
  margin-bottom: -1.25rem;
  background-color: #fff;
}

.StoreMainPage .ProductCateWrap.fixed {
  position: fixed;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 1rem !important;
}

.StoreMainPage .ProductCategory {
  overflow: auto;
  white-space: nowrap;
  gap: 0px 2rem;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.StoreMainPage .ProductCategory::-webkit-scrollbar {
  display: none;
}

.StoreMainPage .ProductCategory a {
  position: relative;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.StoreMainPage .ProductCategory a.active {
  font-weight: var(--Bold);
  color: var(--ColorTypeA);
}

.StoreMainPage .ProductCategory a.active::before {
  content: "";
  position: absolute;
  right: -0.5rem;
  top: 0;
  width: 6px;
  height: 6px;
  background: var(--ColorTypeA);
  border-radius: 50%;
}

.StoreMainPage .ProductCategory a.AllCate {
  position: sticky;
  left: 0;
  z-index: 10;
}

.StoreMainPage .ProductCategory a.AllCate::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 160%;
  height: 100%;
  background: linear-gradient(to right, #fff 80%, transparent 100%);
  z-index: -1;
}

/* 교환권 등록 */

.StoreMainPage .addprd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.StoreMainPage .addprd-sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
}

/* 교환권 등록 가이드 */

.StoreMainPage .AddGuide {
  padding: 2rem 1.5rem 2rem;
}

.StoreMainPage .AddGuide-Titlebox .tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.StoreMainPage .AddGuide-Titlebox .tit img {
  width: 17px;
  height: 23px;
  margin-left: 0.5rem;
}

.StoreMainPage .AddGuide-Titlebox .sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 13px;
}

.StoreMainPage .Step-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.StoreMainPage .Step-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.StoreMainPage .Step-descbox {
  margin-bottom: 5rem;
}

.StoreMainPage .Step-desc {
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  font-size: 13px;
}

/* 교환권 순서 편집 */

.StoreMainPage .EditPrdBox.first {
  border-top: 5px solid var(--ColorTypeE);
}

.StoreMainPage .EditPrdBox {
  border-bottom: 1px solid var(--ColorTypeE);
}

.StoreMainPage .EditPrdBox .EditPrd-item .row .txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  word-break: break-word;
}

.StoreMainPage .EditPrdBox .EditPrd-item .row img {
  width: 25px;
}

/* 모바일 반응형 */

@media screen and (max-width: 320px) {
  .StoreMainPage .Register-Process {
    padding: 2.5rem 1.5rem;
  }
}

/* 2022.12.08 반응형 작업*/

.StoreMainPage {
  max-width: 800px;
  margin: auto;
}

.StoreMainPage::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

.StoreMainPage .navbar {
  max-width: 800px;
  margin: auto;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

@media screen and (max-width: 800px) {
  .StoreMainPage::before {
    display: none;
  }

  .StoreMainPage .navbar {
    border-left: 0px;
    border-right: 0px;
  }
}
