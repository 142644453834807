/* 매장사진 */
.TicketPage .TicketPhoto {
    position: relative;
    width: 100%;
    height: 40vh;
    z-index: 0;
}

.TicketPage .TicketPhoto-Slider {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
}

.TicketPage .TicketPhoto-Content {
    position: absolute;
    left: 0px;
    bottom: 45px;
    width: 100%;
    padding: 0px 2.2rem;
    justify-content: space-between;
    align-items: flex-end;
}

.TicketPage .btn.btn-camera {
    width: 65px;
}

.TicketPage .PhotoCount {
    position: relative;
    left: auto;
    bottom: auto;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 110px;
    height: 50px;
    border-radius: 3px;
    font-weight: var(--Medium);
    color: #fff;
    font-size: 22px;
    line-height: 50px;
}


/* 상점 정보 */
.TicketPage .StoreDetail {
    position: relative;
    background: #fff;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    margin-top: -25px;
    z-index: 5;
}

.TicketPage .StoreTitle {
    margin-bottom: 3rem;
}

.TicketPage .StoreTitle>.logo {
    max-width: 85px;
    margin-right: 20px;
}

.TicketPage .StoreTitle>.tit {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 32px;
    line-height: 1.2;
    word-break: break-word;
}

.TicketPage .StoreInfo {
    gap: 0 30px;
}

.TicketPage .StoreInfo .tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeD);
}

.TicketPage .StoreInfo .desc {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeC);
}

.TicketPage .StoreInfo .desc br.Mob {
    display: none;
}

.StoreTab-wrap{
    padding-bottom : 1.5rem;
}

/* 탭 컨텐츠 */
.TicketPage .StoreTab-wrap.b-top {
    margin-top: 3rem;
    border-top: 1px solid var(--ColorTypeE);
}

.TicketPage .StoreTab {
    background-color: var(--ColorTypeE);
    border-radius: 100px;
    padding: 10px;
    margin-top: 3rem;
}

.TicketPage .StoreTab .nav-tabs {
    justify-content: space-between;
    border-bottom: 0px;
}

.TicketPage .StoreTab .nav-link {
    font-weight: var(--Regular);
    font-size: 25px;
    color: var(--ColorTypeC);
    border: 0px;
    padding: 1rem 0;
    width: calc(50% - 15px);
    border-radius: 100px;
}

.TicketPage .StoreTab .nav-link.active {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    border: 0px;
    background: #fff;
}

.TicketPage .ProductList {
    margin-top: 3rem;
}

.TicketPage .ProductList .row {
    margin-bottom: 5rem;
}

.TicketPage .ProductList .row .col-7 {
    padding-right: 30px;
}

.TicketPage .ProductList .row .col-5 {
    margin-top: 1.5rem;
}

.TicketPage .prd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 32px;
    margin-bottom: 0.3rem;
    word-break: break-word;
}

.TicketPage .prd-sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 17px;
    margin-bottom: 1rem;
    word-break: break-word;
}

.TicketPage .prd-price {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    font-size: 24px;
}

.TicketPage .prd-btnbox {
    gap: 10px;
}

.TicketPage .btn.type14,
.TicketPage .btn.type13 {
    font-size: 18px;
}

.TicketPage .StoreQR {
    position: sticky;
    bottom: 1.5rem;
    margin-top: -70px;
    /* margin-bottom: 1.5rem; */
    z-index: 10;
}

.TicketPage .StoreQR img.QR {
    width: 70px;
    border-radius: 50%;
    box-shadow: 3px 3px 10px 2px rgb(0 0 0 / 20%);
}

.TicketPage .StorePay .btn-primary {
    font-size: 30px;
    background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
    border: 0px;
}

.TicketPage .StorePay .btn-primary img.plus {
    max-width: 25px;
    margin-right: 15px;
}

.TicketPage .StoreTab .nav-link.active::before{
    content: "";
    position: absolute;
    right: 0;
    top: 0rem;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    display: none;
}

.TicketPage .ProductList .row {
    align-items: start; 
    border-bottom: 0px solid var(--ColorTypeE);
    margin: 0px;
    padding: 0;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .TicketPage .btn.btn-camera {
        width: 50px;
    }

    .TicketPage .TicketPhoto {
        height: 245px;
    }

    .TicketPage .PhotoCount {
        width: 65px;
        height: 25px;
        font-size: 13px;
        line-height: 25px;
    }

    .TicketPage .StoreTitle {
        margin-bottom: 1.5rem;
    }

    .TicketPage .StoreTitle>.logo {
        max-width: 50px;
        margin-right: 15px;
    }

    .TicketPage .StoreTitle>.tit {
        font-size: 22px;
    }

    .TicketPage .StoreInfo .tit {
        width: 60px;
        margin-right: 15px;
        font-size: 17px;
    }

    .TicketPage .StoreInfo .desc {
        font-size: 17px;
        width: calc(100% - 75px);
    }

    .TicketPage .StoreInfo .desc br.Mob {
        display: block;
    }

    .TicketPage .StoreTab {
        padding: 7px;
        margin-top: 2rem;
    }

    .TicketPage .StoreTab .nav-link {
        font-size: 17px;
        padding: 0.5rem 0;
        width: calc(50% - 5px);
    }

    .TicketPage .ProductList {
        margin-top: 2rem;
    }

    .TicketPage .ProductList .row {
        margin-bottom: 3rem;
    }

    .TicketPage .ProductList .row .col-7 {
        padding-right: 10px;
    }

    .TicketPage .prd-tit {
        font-size: 20px;
    }

    .TicketPage .prd-sub {
        font-size: 13px;
        margin-bottom: 0.3rem;
    }

    .TicketPage .prd-price {
        font-size: 18px;
    }

    .TicketPage .btn.type14,
    .TicketPage .btn.type13 {
        font-size: 13px;
        padding: 0.375rem 0.75rem !important;
    }

    .TicketPage .StoreQR img.QR {
        width: 50px;
    }

    .TicketPage .StorePay .btn-primary {
        font-size: 20px;
        padding: 1rem 0 !important;
    }

    .TicketPage .StoreTab-wrap.b-top {
        margin-top: 2rem;
    }

}



@media screen and (max-width: 320px) {}


.TicketPage .StoreTab .nav-link {
    line-height: 1.15;
}
.TicketPage .StoreDetail {
 box-shadow: none;
}
.TicketPage .tab-content {
 padding-top: 0;
}
.TicketPage .StoreTab-wrap {
 padding-bottom: 0;
}
.TicketPage .ProductList .row {
 margin-bottom: 5rem;
}
.TicketPage .StoreQR {
 margin-bottom: 1.5rem;
}
.TicketPage .TicketPhoto {
 -webkit-transform-style: preserve-3d;
 -webkit-transform: translateZ(-5px);
}
.TicketPage .StoreDetail {
 -webkit-transform-style: preserve-3d;
 -webkit-transform: translateZ(-5px);
}

@media screen and (max-width: 599px) {
    .TicketPage .StoreInfo > .row {
    padding: 0 1rem;
    }
    .TicketPage .StoreInfo .tit {
    width: 100%;
    margin-right: 0;
    }
    .TicketPage .StoreInfo .desc {
    width: 100%;
    }
}