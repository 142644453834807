.SignupPage .SignUp-title {
    margin: 3rem auto 2rem
}

.SignupPage .SignUp-title .tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 20px;
}

.SignupPage .SignUp-title .tit img {
    max-width: 22px;
    align-self: center;
}

.SignupPage .SignUp-title .sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 14px;
}

.SignupPage .SignUp-Infobox {
    padding-left: calc(1rem + 28px + 0.25rem);
}

.SignupPage .SignUp-checkbox-desc {
    font-weight: var(--Regular);
    font-size: 13px;
    color: var(--ColorTypeC);
    word-break: keep-all;
}

.SignupPage .SignUp-Infobox .form-check-group {
    gap: 0.5rem 0;
}

.SignupPage .SignUp-Infobox .form-check-group .form-check {
    width: 33.333%;
}

/* 아이디 확인 */

.SignupPage .Sign-AlertBox {
    margin: 18vh auto;
}

.SignupPage .SignUpBox img.Emoji {
    max-width: 70px;
}

.SignupPage .Sign-AlertBox-tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.SignupPage .Sign-AlertBox-tit .MemberId {
    font-size: 25px;
    color: var(--ColorTypeA);
}


/* 2022.11.14 */

.SignupPage .SignUp-title .tit img.pw {
    max-width: 15px;
}




/* 모바일 반응형 */

@media screen and (max-width: 320px) {

    .SignupPage .SignUp-Infobox {
        padding-left: 1rem;
    }

}


/* 2022.12.07 반응형 작업*/

.SignupPage {
    max-width: 800px;
    margin: auto;
}

.SignupPage::before {
    content: "";
    width: 100%;
    max-width: 800px;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-left: 1px solid var(--ColorTypeD);
    border-right: 1px solid var(--ColorTypeD);
    z-index: -1;
}


@media screen and (max-width: 800px) {

    .SignupPage::before {
        display: none;
    }

}