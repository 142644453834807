.TAdPaymentPage {
    position: relative;
}

.TAdPaymentPage .sub-navbar {
    border-color: transparent;
}

.TAdPaymentPage .AdPaymentBox+.AdPaymentBox {
    border-top: 20px solid #f2f3f5;
}

.TAdPaymentPage .ad_tit {
    font-size: 22px;
    line-height: 1.3;
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
}

.TAdPaymentPage .ad_checkbox {
    display: flex;
    align-items: center;
    gap: 0 0.75rem;
}

.TAdPaymentPage .ad_check {
    margin-top: 0;
    width: 26px;
    height: 26px;
    border-width: 2px;
    border-color: var(--ColorTypeD);
}

.TAdPaymentPage .ad_check:checked {
    border-color: #2cc0ba;
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/signup_check_color_w.png) no-repeat center / 65% auto #2cc0ba;
}

.TAdPaymentPage .ad_label {
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TAdPaymentPage .ad_checkbox .btn-more {
    background-color: #f3f4f6;
    padding: 0.15rem 0.75rem;
    border-radius: 30px;
    font-size: 15px;
}

.TAdPaymentPage .ad-input {
    background-color: #f3f4f6;
    border-color: #f3f4f6;
    border-radius: 5px;
    padding: 1rem;
}

.demo .TAdPaymentPage .ad-input {
    font-size: 20px;
    height: auto;
}

.TAdPaymentPage .ad_radiobox {
    display: flex;
    gap: 0 0.75rem;
}

.TAdPaymentPage .ad_radio {
    margin-top: 0;
    width: 30px;
    height: 30px;
    border-width: 2px;
    border-color: var(--ColorTypeD);
}

.TAdPaymentPage .ad_radio:checked {
    border-color: #2cc0ba;
    background-color: #2cc0ba;
    background-size: 1.25rem;
}

.TAdPaymentPage .ad_radiolabel {
    font-size: 22px;
    color: var(--ColorTypeB);
}

.TAdPaymentPage .ad_radio[disabled]~.ad_radiolabel {
    opacity: 1;
    color: var(--ColorTypeD);
}

.TAdPaymentPage .ad_radiolabel .info-txt {
    font-size: 18px;
    line-height: 1;
    font-weight: var(--Medium);
    color: #f19100;
    background-color: #fff3e7;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
}

.TAdPaymentPage .pay_infobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_infobox .info_txt {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 0 0.75rem;
}

.TAdPaymentPage .pay_infobox .info_txt>img {
    max-width: 24px;
}

.TAdPaymentPage .pay_infobox .time_txt {
    font-size: 18px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
    display: flex;
    align-items: center;
    gap: 0 0.25rem;
}

.TAdPaymentPage .pay_infobox .time_txt>img {
    max-width: 22px;
    padding-top: 3px;
}

.TAdPaymentPage .pay_address {
    padding: 2rem 0 1.5rem;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_address .txt1 {
    margin-bottom: 0.75rem;
}

.TAdPaymentPage .pay_address .txt1>img {
    max-width: 24px;
}

.TAdPaymentPage .pay_address .add1 {
    font-size: 28px;
    line-height: 1.3;
    color: var(--ColorTypeB);
    margin-bottom: 0;
    word-break: break-word;
}

.TAdPaymentPage .pay_address .add2 {
    font-size: 22px;
    line-height: 1.3;
    color: var(--ColorTypeB);
    margin-bottom: 1.5rem;
    word-break: break-word;
}

.TAdPaymentPage .pay_phoneinfo {
    padding: 2rem 0 0;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_phoneinfo .numberBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TAdPaymentPage .pay_phoneinfo .numberBox .number {
    font-size: 28px;
    line-height: 1.3;
    color: var(--ColorTypeB);
    margin-bottom: 0;
}

.TAdPaymentPage .pay_phoneinfo .numberBox .btn-numchange {
    background-color: #f3f4f6;
    padding: 0.25rem 0.75rem;
    border-radius: 30px;
    font-size: 17px;
}

.TAdPaymentPage .pay_phoneinfo .numberCheck {
    margin-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_phoneinfo .numberTxt {
    margin: 1.5rem 0;
    font-size: 18px;
    color: #818181;
}

.TAdPaymentPage .pay_request {
    padding-top: 2rem;
}

.TAdPaymentPage .pay_request-box .ad_checkbox {
    margin: 1rem 0 1.5rem;
    padding: 1rem;
    border-radius: 5px;
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/pot.png) no-repeat calc(100% - 1rem) calc(100% - 0.25rem) / 2.1rem #e6f3e9;
}

.TAdPaymentPage .pay_request-box .ad_checkbox .ad_label {
    font-size: 22px;
}

.TAdPaymentPage .pay_request-sub {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_request-sub:last-child {
    margin-bottom: 0;
}

.TAdPaymentPage .pay_request-sub .tit {
    font-size: 20px;
    color: #818181;
    margin-bottom: 0.75rem;
}

.TAdPaymentPage .pay_request-sub .ad_checkbox {
    margin-top: 1rem;
}


.TAdPaymentPage .pay_request-Select {
    border: 1px solid var(--ColorTypeD);
    border-radius: 3px;
    transition: all 0.4s ease;
    box-shadow: none;
}

.TAdPaymentPage .pay_request-Select .selectBtn {
    padding: 1rem;
    padding-right: 1.5rem;
    width: 100%;
    border-radius: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
}

.TAdPaymentPage .pay_request-Select .selectBtn::after {
    content: "";
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png) no-repeat center right / 1rem;
    width: 1rem;
    height: 1rem;
    transition: all 0.2s ease-in-out;
    transform: rotate(180deg);
}

.TAdPaymentPage .pay_request-Select .selectBtn.collapsed::after {
    transform: rotate(0deg);
}

.TAdPaymentPage .pay_request-Select .selectInputbox>input {
    display: none;
}

.TAdPaymentPage .pay_request-Select .selectInputbox>label {
    padding: 0.25rem 1rem 1rem;
    padding-right: 1.5rem;
    font-size: 20px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
    position: relative;
    width: 100%;
}

.TAdPaymentPage .pay_request-Select .selectInputbox>label::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    background-color: transparent;
    width: 4px;
    height: 100%;
}

.TAdPaymentPage .pay_request-Select .selectInputbox>input:checked+label {
    color: #2cc0ba;
}

.TAdPaymentPage .pay_request-Select .selectInputbox>input:checked+label::before {
    background-color: #2cc0ba;
}

.TAdPaymentPage .pay_method {
    padding-top: 2rem;
}

.TAdPaymentPage .pay_method-box {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_method-box .ad_tit {
    margin-bottom: 2rem;
}

.TAdPaymentPage .pay_method-box .ad_radiobox .baemin_img {
    margin-top: 1.5rem;
    padding-left: 1.25rem;
    width: 90%;
}

.TAdPaymentPage .pay_method-box .ad_inputbox {
    position: relative;
    margin-top: 1.5rem;
}

.TAdPaymentPage .pay_method-box .ad_inputbox .ad-input2 {
    padding: 1rem;
    padding-right: 1.5rem;
    width: 100%;
    height: auto;
    border-radius: 5px;
    font-size: 20px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
}

.TAdPaymentPage .pay_method-box .ad_inputbox .btn-paychange {
    background-color: #f3f4f6;
    padding: 0.25rem 0.75rem;
    border-radius: 30px;
    font-size: 17px;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.TAdPaymentPage .pay_method-info {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .pay_method-info>p {
    font-size: 18px;
    position: relative;
    padding-left: 15px;
    color: #818181;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.TAdPaymentPage .pay_method-info>p+p {
    margin-top: 0.5rem;
}

.TAdPaymentPage .pay_method-info>p::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 0.58em;
    background-color: #818181;
    border-radius: 50%;
}

.TAdPaymentPage .pay_method-info p.color {
    color: #2cc0ba;
}

.TAdPaymentPage .pay_method-info>p.color::before {
    background-color: #2cc0ba;
}

.TAdPaymentPage .pay_method-info p.color>img {
    max-width: 8px;
    margin-left: 6px;
}

.TAdPaymentPage .additional-item {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 0;
    align-items: center;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TAdPaymentPage .additional-item .txt {
    font-size: 22px;
    color: var(--ColorTypeB);
    width: 40%;
}

.TAdPaymentPage .additional-item .cont {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 0.5rem;
}

.TAdPaymentPage .additional-item .cont>span {
    font-size: 22px;
    color: var(--ColorNavIcon);
}

.TAdPaymentPage .additional-item .cont>img {
    max-width: 10px;
}

.TAdPaymentPage .additional-item.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.TAdPaymentPage .additional-item.disabled .cont>img {
    display: none;
}

.TAdPaymentPage .additional-item.CashReceipts .cont {
    gap: 0 1.25rem;
}

.TAdPaymentPage .additional-item.CashReceipts .cont .ad_radiobox {
    align-items: center;
    gap: 0 0.5rem;
}

.TAdPaymentPage .additional-item.CashNum .txt {
    width: 80%;
}

.TAdPaymentPage .additional-item.CashNum .cont .btn-CashReceipts {
    background-color: #f3f4f6;
    padding: 0.25rem 0.75rem;
    border-radius: 30px;
    font-size: 17px;
}

.TAdPaymentPage .price-box {
    padding-top: 2rem;
}

.TAdPaymentPage .price-box .ad_tit {
    margin-bottom: 2rem;
}

.TAdPaymentPage .price-item {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    color: var(--ColorTypeB);
}

.TAdPaymentPage .price-item+.price-item {
    margin-top: 1rem;
}

.TAdPaymentPage .price-item .txt {
    width: 40%;
}

.TAdPaymentPage .price-item .txt .btn-tip {
    background-color: #f3f4f6;
    padding: 0.25rem 0.75rem;
    border-radius: 30px;
    font-size: 17px;
}

.TAdPaymentPage .price-item .cont {
    flex: 1;
    text-align: right;
}

.TAdPaymentPage .price-item.total {
    border-top: 1px solid var(--ColorTypeE);
    padding-top: 1.5rem;
    margin: 2rem 0;
}

.TAdPaymentPage .price-item.total .txt {
    font-weight: var(--Bold);
}

.TAdPaymentPage .price-item.total .cont {
    font-weight: var(--Bold);
}

.TAdPaymentPage .AdInfoBox-item {
    padding: 1.5rem 0;
    border-top: 1px solid #d9d9d9;
    background-color: #f2f3f5;
}

.TAdPaymentPage .AdInfoBox-item .btn-infoBtn {
    padding-right: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: var(--Regular);
    color: var(--ColorNavIcon);
}

.TAdPaymentPage .AdInfoBox-item .btn-infoBtn::after {
    content: "";
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png) no-repeat center right / 1rem;
    width: 1rem;
    height: 1rem;
    transition: all 0.2s ease-in-out;
    transform: rotate(180deg);
    opacity: 0.4;
}

.TAdPaymentPage .AdInfoBox-item .btn-infoBtn.collapsed::after {
    transform: rotate(0deg);
}

.TAdPaymentPage .AdInfoBox-item .infoCont {
    font-size: 18px;
    font-weight: var(--Regular);
    color: var(--ColorNavIcon);
    margin-top: 1.5rem;
}

.TAdPaymentPage .AdInfoBox-item .last-info {
    text-align: center;
    font-size: 17px;
    font-weight: var(--Regular);
    color: var(--ColorNavIcon);
}

.TAdPaymentPage .AdPaymentBtn {
    padding: 1.25rem 0;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 1.25rem);
    padding-bottom: calc(env(safe-area-inset-bottom) + 1.25rem);
    position: sticky;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #fff;
    border-top-color: var(--ColorTypeD);
    z-index: 10;
}

.TAdPaymentPage .AdPaymentBtn::after {
    content: "";
    position: fixed;
}

.TAdPaymentPage .AdPaymentBtn>.btn {
    text-align: center;
    font-size: 28px;
    line-height: 1;
    font-weight: var(--Semibold);
    color: #fff;
    background-color: #2cc0ba;
    width: 100%;
    padding: 1.5rem 0.5rem;
}

.TAdPaymentPage .additional-item.file {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem 0;
}

.TAdPaymentPage .additional-item.file .txt {
    width: 100%;
}

.TAdPaymentPage .additional-item.file .cont {
    width: 100%;
}

.TAdPaymentPage .additional-item.file .BaeminFile {
    display: none;
}

.TAdPaymentPage .additional-item.file .BaeminFile-Label {
    cursor: pointer;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid var(--ColorTypeD);
    font-weight: var(--Regular);
    font-size: 22px;
    color: var(--ColorNavIcon);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
    background-color: #fff;
}

.TAdPaymentPage .additional-item.file .BaeminFile-Label.active {
    color: var(--ColorNavIcon);
}



@media screen and (max-width: 599px) {

    .TAdPaymentPage .px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TAdPaymentPage .ad_tit {
        font-size: 17px;
    }

    .TAdPaymentPage .ad_checkbox {
        gap: 0 0.5rem;
    }

    .TAdPaymentPage .ad_check {
        width: 22px;
        height: 22px;
    }

    .TAdPaymentPage .ad_label {
        font-size: 15px;
    }

    .TAdPaymentPage .ad_checkbox .btn-more {
        padding: 0.15rem 0.6rem;
        font-size: 12px;
    }

    .TAdPaymentPage .ad_radiobox {
        gap: 0 0.5rem;
    }

    .TAdPaymentPage .ad_radio {
        width: 26px;
        height: 26px;
    }

    .TAdPaymentPage .ad_radiolabel {
        font-size: 17px;
        flex: 1;
    }

    .TAdPaymentPage .ad_radiolabel .info-txt {
        font-size: 13px;
    }

    .TAdPaymentPage .ad_radiolabel .info-icon {
        max-width: 15px;
    }

    .TAdPaymentPage .pay_infobox {
        padding: 1.25rem 0;
    }

    .TAdPaymentPage .AdPaymentBox+.AdPaymentBox {
        border-top: 12px solid #f2f3f5;
    }

    .TAdPaymentPage .pay_infobox .info_txt {
        gap: 0 0.5rem;
    }

    .TAdPaymentPage .pay_infobox .info_txt>img {
        max-width: 20px;
    }

    .TAdPaymentPage .pay_infobox .time_txt {
        font-size: 15px;
    }

    .TAdPaymentPage .pay_infobox .time_txt>img {
        max-width: 17px;
        padding-top: 2px;
    }

    .TAdPaymentPage .pay_address {
        padding: 1.25rem 0 1rem;
    }

    .TAdPaymentPage .pay_address .txt1 {
        margin-bottom: 0.5rem;
    }

    .TAdPaymentPage .pay_address .txt1>img {
        max-width: 20px;
    }

    .TAdPaymentPage .pay_address .add1 {
        font-size: 20px;
    }

    .TAdPaymentPage .pay_address .add2 {
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .demo .TAdPaymentPage .ad-input {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_phoneinfo {
        padding: 1.25rem 0 0;
    }

    .TAdPaymentPage .pay_phoneinfo .numberCheck {
        margin-top: 0.5rem;
        padding-bottom: 1.25rem;
    }

    .TAdPaymentPage .pay_phoneinfo .numberBox .number {
        font-size: 20px;
    }

    .TAdPaymentPage .pay_phoneinfo .numberBox .btn-numchange {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_phoneinfo .numberTxt {
        margin: 1rem 0;
        font-size: 13px;
    }

    .TAdPaymentPage .pay_request {
        padding-top: 1.25rem;
    }

    .TAdPaymentPage .pay_request-box .ad_checkbox {
        margin: 0.75rem 0 1rem;
        padding: 0.75rem;
        background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/pot.png) no-repeat calc(100% - 0.75rem) calc(100% - 0.25rem) / 1.8rem #e6f3e9;
    }

    .TAdPaymentPage .pay_request-box .ad_checkbox .ad_label {
        font-size: 16px;
    }

    .TAdPaymentPage .pay_request-sub {
        margin-bottom: 1.25rem;
        padding-bottom: 1rem;
    }

    .TAdPaymentPage .pay_request-sub .tit {
        font-size: 15px;
        color: #818181;
        margin-bottom: 0.5rem;
    }

    .TAdPaymentPage .pay_request-Select .selectBtn {
        padding: 0.75rem;
        padding-right: 1rem;
        font-size: 15px;
    }

    .TAdPaymentPage .pay_request-Select .selectBtn::after {
        background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png) no-repeat center right / 0.75rem;
        width: 0.75rem;
    }

    .TAdPaymentPage .pay_request-Select .selectInputbox>label {
        padding: 0.25rem 0.75rem 1rem;
        font-size: 15px;
    }

    .TAdPaymentPage .pay_request-Select .selectInputbox>label::before {
        width: 3px;
    }

    .TAdPaymentPage .pay_method {
        padding-top: 1.25rem;
    }

    .TAdPaymentPage .pay_method-box {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }

    .TAdPaymentPage .pay_method-box .ad_tit {
        margin-bottom: 1.25rem;
    }

    .TAdPaymentPage .pay_method-box .ad_radiobox .baemin_img {
        margin-top: 1rem;
        padding-left: 0.75rem;
    }

    .TAdPaymentPage .pay_method-box .ad_inputbox {
        margin-top: 1rem;
    }

    .TAdPaymentPage .pay_method-box .ad_inputbox .ad-input2 {
        padding: 0.75rem;
        padding-right: 1rem;
        font-size: 15px;
    }

    .TAdPaymentPage .pay_method-box .ad_inputbox .btn-paychange {
        padding: 0.15rem 0.6rem;
        font-size: 12px;
    }

    .TAdPaymentPage .pay_method-info {
        padding-bottom: 1rem;
    }

    .TAdPaymentPage .pay_method-info>p {
        font-size: 13px;
        padding-left: 12px;
    }

    .TAdPaymentPage .pay_method-info p.color>img {
        max-width: 6px;
        margin-left: 5px;
    }

    .TAdPaymentPage .pay_method-info>p+p {
        margin-top: 0.25rem;
    }

    .TAdPaymentPage .additional-item {
        padding: 0.75rem 0;
    }

    .TAdPaymentPage .additional-item .txt {
        font-size: 17px;
    }

    .TAdPaymentPage .additional-item .cont>span {
        font-size: 15px;
    }

    .TAdPaymentPage .additional-item .cont>img {
        max-width: 8px;
    }

    .TAdPaymentPage .additional-item.CashNum .cont .btn-CashReceipts {
        padding: 0.15rem 0.6rem;
        font-size: 12px;
    }

    .TAdPaymentPage .price-box {
        padding-top: 1.25rem;
    }

    .TAdPaymentPage .price-box .ad_tit {
        margin-bottom: 1.25rem;
    }

    .TAdPaymentPage .price-item {
        font-size: 17px;
    }

    .TAdPaymentPage .price-item+.price-item {
        margin-top: 0.5rem;
    }

    .TAdPaymentPage .price-item .txt .btn-tip {
        padding: 0.15rem 0.6rem;
        font-size: 12px;
    }

    .TAdPaymentPage .price-item.total {
        padding-top: 1rem;
        margin: 1.25rem 0;
    }

    .TAdPaymentPage .AdInfoBox-item {
        padding: 1rem 0;
    }

    .TAdPaymentPage .AdInfoBox-item .btn-infoBtn {
        padding-right: 0.5rem;
        font-size: 15px;
    }

    .TAdPaymentPage .AdInfoBox-item .btn-infoBtn::after {
        background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png) no-repeat center right / 0.7rem;
        width: 0.75rem;
    }

    .TAdPaymentPage .AdInfoBox-item .infoCont {
        font-size: 14px;
        margin-top: 1rem;
    }

    .TAdPaymentPage .AdInfoBox-item .last-info {
        font-size: 13px;
    }

    .TAdPaymentPage .AdPaymentBtn {
        padding: 0.75rem 0;
        padding-bottom: calc(constant(safe-area-inset-bottom) + 0.75rem);
        padding-bottom: calc(env(safe-area-inset-bottom) + 0.75rem);
    }

    .TAdPaymentPage .AdPaymentBtn>.btn {
        font-size: 20px;
        padding: 1rem 0.5rem;
    }

    .TAdPaymentPage .additional-item.file .BaeminFile-Label {
        padding: 0.75rem;
        font-size: 15px;;
    }

}

@media screen and (max-width: 320px) {

    .TAdPaymentPage .ad_tit {
        font-size: 16px;
    }

    .demo .TAdPaymentPage .ad-input {
        font-size: 13px;
    }

    .TAdPaymentPage .pay_infobox .info_txt {
        gap: 0 0.3rem;
        width: 57%;
    }

    .TAdPaymentPage .pay_infobox .info_txt>img {
        max-width: 17px;
    }

    .TAdPaymentPage .pay_infobox .time_txt {
        font-size: 14px;
        gap: 0 0.2rem;
    }

    .TAdPaymentPage .pay_infobox .time_txt>img {
        max-width: 15px;
        padding-top: 1px;
    }

    .TAdPaymentPage .pay_address .add1 {
        font-size: 18px;
    }

    .TAdPaymentPage .pay_address .add2 {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_phoneinfo .numberBox .number {
        font-size: 18px;
    }

    .TAdPaymentPage .pay_phoneinfo .numberBox .btn-numchange {
        font-size: 13px;
    }

    .TAdPaymentPage .pay_phoneinfo .numberTxt {
        font-size: 12px;
    }

    .TAdPaymentPage .ad_checkbox {
        gap: 0 0.25rem;
    }

    .TAdPaymentPage .ad_check {
        width: 18px;
        height: 18px;
    }

    .TAdPaymentPage .ad_label {
        font-size: 14px;
    }

    .TAdPaymentPage .ad_radiobox {
        gap: 0 0.25rem;
    }

    .TAdPaymentPage .ad_radio {
        width: 24px;
        height: 24px;
    }

    .TAdPaymentPage .ad_radiolabel {
        font-size: 15px;
    }

    .TAdPaymentPage .ad_radiolabel .info-txt {
        font-size: 12px;
    }

    .TAdPaymentPage .pay_request-box .ad_checkbox .ad_label {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_request-box .ad_checkbox {
        background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/pot.png) no-repeat calc(100% - 0.75rem) calc(100% - 0.25rem) / 1.3rem #e6f3e9;
    }

    .TAdPaymentPage .pay_request-sub .tit {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_request-Select .selectBtn {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_request-Select .selectInputbox>label {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_method-box .ad_inputbox .ad-input2 {
        font-size: 14px;
    }

    .TAdPaymentPage .pay_method-info>p {
        font-size: 12px;
    }

    .TAdPaymentPage .additional-item .txt {
        font-size: 15px;
    }

    .TAdPaymentPage .additional-item .cont>span {
        font-size: 14px;
    }

    .TAdPaymentPage .price-item {
        font-size: 15px;
    }

    .TAdPaymentPage .AdInfoBox-item .btn-infoBtn {
        font-size: 13px;
    }

    .TAdPaymentPage .AdInfoBox-item .infoCont {
        font-size: 12px;
        margin-top: 0.5rem;
    }

    .TAdPaymentPage .AdInfoBox-item .last-info {
        font-size: 12px;
    }

    .TAdPaymentPage .AdPaymentBtn>.btn {
        font-size: 17px;
    }
}