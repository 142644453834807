.demo .container-fluid.pt-70px {
    padding-top: 90px;
}

.demo .pt-4-5 {
    padding-top: 3rem !important;
}

.demo .pe-4-5 {
    padding-right: 3rem !important;
}

.demo .pb-4-5 {
    padding-bottom: 3rem !important;
}

.demo .ps-4-5 {
    padding-left: 3rem !important;
}

.demo .px-4-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.demo .py-4-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.demo .p-4-5 {
    padding: 3rem !important;
}

.demo .mt-4-5 {
    margin-top: 3rem !important;
}

.demo .me-4-5 {
    margin-right: 3rem !important;
}

.demo .mb-4-5 {
    margin-bottom: 3rem !important;
}

.demo .ms-4-5 {
    margin-left: 3rem !important;
}

.demo .mx-4-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.demo .my-4-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.demo .m-4-5 {
    margin: 3rem !important;
}


/* 메뉴 */
.demo .navbar {
    background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
    border-bottom: 0px;
    padding: 1.5rem 1rem;
}

.demo .navbar.bg-none {
    background: #fff;
    border-bottom: 1px solid var(--ColorTypeD);
}

.demo .Navbar-Toggler {
    padding: 0;
    font-size: 0;
    line-height: 1;
    color: inherit;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    transition: var(--bs-navbar-toggler-transition);
    width: 28px;
}

.demo .offcanvas .btn-close {
    box-sizing: content-box;
    width: 28px;
    height: 28px;
    background: no-repeat;
}

.demo .offcanvas .btn-close img.close {
    width: 25px;
}

.demo .offcanvas {
    --bs-offcanvas-width: 520px;
    max-width: 60%;
}

.demo .offcanvas-header {
    border-bottom: 2px solid var(--ColorTypeE);
}

.demo .offcanvas-header img.logo {
    width: 50px;
    margin-right: 15px;
}

.demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 65px);
    font-weight: var(--Medium);
    font-size: 24px;
    line-height: 1.2;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.demo .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
}

.demo .offcanvas-body .nav-link .imgbox {
    font-size: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.af {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.img1 {
    width: 30px;
    margin-right: 18px;
    margin-left: 2px;
}

.demo .offcanvas-body .nav-link .imgbox img.img2 {
    width: 35px;
    margin-right: 15px;
}

.demo .offcanvas-body .nav-link .imgbox img.img3 {
    width: 30px;
    margin-right: 18px;
    margin-left: 2px;
}

.demo .offcanvas-body .nav-link span {
    width: calc(100% - 50px);
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
}

.demo .offcanvas-body .nav-link.active .imgbox img.be {
    opacity: 0;
}

.demo .offcanvas-body .nav-link.active .imgbox img.af {
    opacity: 1;
}

.demo .offcanvas-body .nav-link.active span {
    color: var(--ColorTypeA);
}

.demo .offcanvas-body .btn-default {
    font-size: 25px;
    height: 65px;
    border-radius: 50px;
}

.demo .sub-navbar {
    background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
    border-bottom: 0px;
    padding: 1.5rem 1rem;
}

.demo .navbar-title {
    line-height: 34px;
    color: #fff;
    font-size: 25px;
}

.demo .btn-back {
    width: 14px;
}

.demo .sub-navbar .btn-close {
    width: 25px;
}

.demo .form-control.InputType {
    height: 55px;
    font-size: 17px;
}

.demo .form-control.InputType~.price-txt {
    height: 55px;
    line-height: 55px;
    font-weight: var(--Regular);
    font-size: 17px;
    color: var(--ColorTypeB);
}

.demo .Input-placeholder {
    font-size: 17px;
}

.demo .modal-dialog.modal-sm {
    --bs-modal-width: 500px;
}

.demo .modal-title {
    font-size: 25px;
}

.demo .modal-title img.icon {
    display: block;
    width: 50px;
    margin: 0 auto 1rem;
}

.demo .modal-body {
    font-size: 18px;
}

.demo .btn-modal-secondary,
.demo .btn-modal-primary {
    font-size: 20px;
}

.demo .Inner {
    max-width: 500px;
}

.demo .btn-primary,
.demo .btn-default,
.demo .btn-light {
    height: 55px;
    font-size: 18px;
}

.demo .AlertBox.active {
    height: 60px;
}

.demo .AlertBox {
    font-size: 18px;
}



/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .demo .container-fluid.pt-70px {
        padding-top: 70px;
    }

    .demo .pt-4-5 {
        padding-top: 2rem !important;
    }

    .demo .pe-4-5 {
        padding-right: 2rem !important;
    }

    .demo .pb-4-5 {
        padding-bottom: 2rem !important;
    }

    .demo .ps-4-5 {
        padding-left: 2rem !important;
    }

    .demo .px-4-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .demo .py-4-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .demo .p-4-5 {
        padding: 2rem !important;
    }

    .demo .mt-4-5 {
        margin-top: 2rem !important;
    }

    .demo .me-4-5 {
        margin-right: 2rem !important;
    }

    .demo .mb-4-5 {
        margin-bottom: 2rem !important;
    }

    .demo .ms-4-5 {
        margin-left: 2rem !important;
    }

    .demo .mx-4-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .demo .my-4-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .demo .m-4-5 {
        margin: 2rem !important;
    }

    .demo .navbar {
        padding: 1rem 0.75rem;
    }

    .demo .offcanvas {
        max-width: 75%;
    }

    .demo .offcanvas-header img.logo {
        width: 35px;
        margin-right: 5px;
    }

    .demo .offcanvas-header .offcanvas-title {
        width: calc(100% - 40px);
        font-size: 18px;
    }

    .demo .offcanvas-body {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img1 {
        width: 20px;
        margin-right: 13px;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img2 {
        width: 25px;
        margin-right: 12px;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img3 {
        width: 20px;
        margin-right: 13px;
    }

    .demo .offcanvas-body .nav-link span {
        font-size: 20px;
    }

    .demo .offcanvas-body .btn-default {
        font-size: 18px;
        height: 50px;
    }

    .demo .sub-navbar {
        padding: 1rem 0.75rem;
    }

    .demo .navbar-title {
        font-size: 20px;
    }

    .demo .btn-back {
        width: 12px;
    }
           
    .demo .sub-navbar .btn-close {
        width: 18px;
    }

    .demo .form-control.InputType {
        height: 40px;
        padding: 0.75rem;
        font-size: 13px;
    }

    .demo .form-control.InputType~.price-txt {
        font-size: 13px;
        height: 40px;
        line-height: 40px;
    }

    .demo .Input-placeholder {
        font-size: 13px;
    }

    .demo .modal-dialog.modal-sm {
        --bs-modal-width: 300px;
    }

    .demo .modal-title {
        font-size: 18px;
    }

    .demo .modal-title img.icon {
        width: 35px;
        margin: 0 auto 0.5rem;
    }


    .demo .modal-body {
        font-size: 13px;
    }

    .demo .btn-modal-secondary,
    .demo .btn-modal-primary {
        font-size: 16px;
    }

    .demo .TLoginPage .Inner {
        max-width: 320px;
    }

    .demo .btn-primary,
    .demo .btn-default,
    .demo .btn-light {
        height: 50px;
        font-size: 14px;
    }

    .demo .AlertBox.active {
        height: 50px;
    }

    .demo .AlertBox {
        font-size: 14px;
    }

}



@media screen and (max-width: 320px) {

    .demo .offcanvas-body .btn-default {
        height: 40px;
    }

    .demo .btn-primary,
    .demo .btn-default,
    .demo .btn-light {
        height: 40px;
    }

    .demo .form-checkbox {
        height: 40px;
    }



}

.demo .btn.type11,
.demo .btn.type12,
.demo .btn.type13,
.demo .btn.type14 {
    height: auto;
    width: auto;
}