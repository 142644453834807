/* 20221028 css 공통 */

* {
  margin: 0;
  padding: 0;
  outline: none;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.Inner {
  width: 90%;
  margin: auto;
  max-width: 320px;
}

.MockupTitle {
  box-sizing: border-box;
  border-bottom: 2px solid #666;
  padding-bottom: 0.5em;
}

.MockupList > li {
  border-bottom: 1px solid #ddd;
}

.MockupList > li > a {
  display: block;
  position: relative;
  padding: 1em 0px;
  padding-left: 100px;
  box-sizing: border-box;
}

.MockupDate {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: var(--Bold);
}

.MockupState {
  background: var(--ColorTypeA);
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 10px;
}

.MockupState.on {
  background: var(--ColorTypeB);
}

/*Font Pretendard */

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
    format("woff");
  font-weight: 100;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff")
    format("woff");
  font-weight: 200;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
    format("woff");
  font-weight: 300;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
  font-weight: 500;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
  font-weight: 700;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  transform: rotate(0.04deg);
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  transform: rotate(0.04deg);
  font-style: normal;
}

body {
  font-family: "Pretendard", sans-serif;
}

:root {
  --ColorTypeA: #ed1556;
  --ColorTypeB: #231815;
  --ColorTypeC: #606060;
  --ColorTypeD: #c3c3c3;
  --ColorTypeE: #e8e8e8;

  --Light: 100;
  --Regular: 400;
  --Medium: 500;
  --Semibold: 600;
  --Bold: 700;
  --Extrabold: 800;

  --ColorNavIcon: #a1a0a1;
}

.pt-2-5 {
  padding-top: 0.75rem !important;
}

.pe-2-5 {
  padding-right: 0.75rem !important;
}

.pb-2-5 {
  padding-bottom: 0.75rem !important;
}

.ps-2-5 {
  padding-left: 0.75rem !important;
}

.px-2-5 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.py-2-5 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.pt-4-5 {
  padding-top: 2rem !important;
}

.pe-4-5 {
  padding-right: 2rem !important;
}

.pb-4-5 {
  padding-bottom: 2rem !important;
}

.ps-4-5 {
  padding-left: 2rem !important;
}

.px-4-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.py-4-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.m-4-5 {
  padding: 2rem !important;
}

.mt-4-5 {
  margin-top: 2rem !important;
}

.me-4-5 {
  margin-right: 2rem !important;
}

.mb-4-5 {
  margin-bottom: 2rem !important;
}

.ms-4-5 {
  margin-left: 2rem !important;
}

.mx-4-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.my-4-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.m-4-5 {
  margin: 2rem !important;
}

.form-control.InputType {
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.form-control.InputType::placeholder {
  color: var(--ColorTypeD);
}

.form-control.InputType:focus {
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

.form-control.InputType.active {
  border-color: var(--ColorTypeA);
  box-shadow: none;
}

.form-control.InputType.active ~ .form-text.info-txt {
  display: block;
}

.AlertBox {
  width: 100%;
  height: 0px;
  border-radius: 30px;
  border: 1px solid var(--ColorTypeE);
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 10px 1px rgb(0 0 0 / 20%);
  opacity: 0;
  transform: translateY(-1rem);
}

.AlertBox.active {
  opacity: 1;
  transform: translateY(0);
  height: 45px;
  transition: all 0.35s ease;
}

.btn-default {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeB);
  color: #fff;
}

.btn-default:hover,
.btn-default.btn:first-child:active,
:not(.btn-check) + .btn-default:active {
  color: #fff;
  background: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

.btn-primary {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeA);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeA);
}

.btn-primary:hover,
.btn-primary.btn:first-child:active,
:not(.btn-check) + .btn-primary:active {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

.btn-secondary {
  width: 100%;
  height: 50px;
  background: var(--ColorTypeD);
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Medium);
  color: #fff;
  font-size: 14px;
}

.btn-secondary:hover,
.btn-secondary.btn:first-child:active,
:not(.btn-check) + .btn-secondary:active {
  color: #fff;
  background-color: var(--ColorTypeD);
  border-color: var(--ColorTypeD);
}

.btn-light {
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeC);
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 14px;
}

.btn-light:hover,
.btn-light.btn:first-child:active,
:not(.btn-check) + .btn-light:active {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeC);
}

.type10.btn-light:hover,
.type10.btn-light.btn:first-child:active,
:not(.btn-check) + .btn-light:active {
  border-color: var(--ColorTypeC);
}

.btn-check + .btn-light.type10.btn:hover {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeD);
}

.btn-check:checked + .type10.btn-light.btn,
.btn-check + .btn-light.type10.btn:hover {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

a.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-clear {
  border: 0px;
  background: none;
  padding: 0px;
}

.btn-add-close {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 20%);
  font-size: 0px;
}

.btn.type5 {
  max-width: 110px;
  font-weight: var(--Regular);
}

.btn.type6 {
  max-width: 155px;
  height: 50px;
}

.btn.type7 {
  max-width: 155px;
  height: 50px;
}

.btn.type8 {
  max-width: 155px;
  height: 50px;
  font-weight: var(--Regular);
}

.btn.type9 {
  max-width: 155px;
  height: 50px;
}

.btn.type10 {
  height: 30px;
  border-radius: 30px;
  border-color: var(--ColorTypeD);
  font-size: 13px;
  line-height: 30px;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0;
}

.btn.type11 {
  width: auto;
  height: auto;
  font-size: 13px;
  border-radius: 30px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1rem;
}

.btn.type12 {
  width: auto;
  height: auto;
  font-size: 13px;
  border-radius: 30px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1rem;
}

.btn.type13 {
  width: auto;
  height: auto;
  font-size: 13px;
}

.btn.type14 {
  width: auto;
  height: auto;
  font-size: 13px;
}

.btn.type15 {
  border-radius: 30px;
}

.btn.type16 {
  border-radius: 30px;
}

.form-text.info-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 13px;
  padding: 0.25rem 0.75rem;
  display: none;
}

.container.menu {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 70px;
}

/* navbar top */
.navbar {
  background: #fff;
  border-bottom: 1px solid var(--ColorTypeD);
  padding: 1rem 0.75rem;
}

.navbar-brand {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.navbar-brand.hide {
  opacity: 0;
  pointer-events: none;
}

/* .navbar-toggler {
    padding: 0;
    font-size: 0;
    line-height: 1;
    color: inherit;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    transition: var(--bs-navbar-toggler-transition);
    width: 28px;
  } */

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas {
  max-width: 85%;
}

.offcanvas.offcanvas-end {
  border-left: none;
}

.btn-close {
  background-size: 27px auto;
}

.offcanvas-header .btn-close {
  margin-right: 3px;
}

/* modal */
.modal-dialog.modal-sm {
  width: 80%;
  margin: auto;
}

.modal-content {
  border: 1px solid var(--ColorTypeD);
}

.modal-header {
  padding: 1.5rem 1.5rem 0;
  border-bottom: 0px;
}

.modal-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  width: 100%;
}

.modal-body {
  padding: 0.5rem 1.5rem;
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
}

.modal-footer {
  border-top: 0px;
  padding: 1rem 1.5rem;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.3;
}

.btn-modal-secondary,
.btn-modal-primary {
  width: 50%;
  margin: 0.375rem 0;
}

.btn-modal-secondary {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.btn-modal-secondary:hover,
.btn-modal-secondary.btn:first-child:active,
:not(.btn-check) + .btn-modal-secondary:active {
  color: var(--ColorTypeB);
}

.btn-modal-primary {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 16px;
}

.btn-modal-primary:hover,
.btn-modal-primary.btn:first-child:active,
:not(.btn-check) + .btn-modal-primary:active {
  color: var(--ColorTypeA);
}

.btn-modal-primary.btn-clear:first-child:hover,
.btn-modal-primary.btn-clear:first-child:active,
.btn-modal-primary.btn-clear:first-child:focus {
  background-color: transparent;
  color: var(--ColorTypeA);
}

/* navber bottom  */

.b-navbar {
  transition: all 0.35s ease;
}

.b-navbar.hide {
  opacity: 0;
  margin-bottom: -100px;
}

.b-navbar {
  background: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
}

.b-navbar ul {
  padding: 0.75rem 1.5rem;
}

.b-navbar ul li {
  position: relative;
  width: 14%;
  max-width: 45px;
}

.b-navbar ul li a {
  display: block;
}

.b-navbar ul li a img:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}

.b-navbar ul li.active a img:nth-child(1) {
  opacity: 0;
}

.b-navbar ul li.active a img:nth-child(2) {
  display: block;
}

/* 서프페이지 navbar */

.sub-navbar {
  padding: 1rem 0.75rem;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--ColorTypeD);
}

.sub-navbar .btn-close {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: auto;
  padding: 0px;
  margin: 0px;
  opacity: 1;
  border-radius: 0;
}

.sub-navbar .btn-close.ver2 {
  width: 25px;
}

.navbar-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.btn-back {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.addprd-inputbox.d-flex {
  gap: 0.5rem;
}

.btn.btn-refresh {
  width: 30px;
}

.btn-reset {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
  border-bottom: 1px solid var(--ColorTypeC);
  border-radius: 0;
  padding: 0.4rem 0px;
  align-self: center;
}

.h-40px {
  height: 40px !important;
}

.h-50px {
  height: 50px !important;
}

.h-30px {
  height: 30px !important;
}

.gap-10px {
  gap: 0px 10px;
}

.btn-reset img {
  width: 14px;
  margin-right: 5px;
}

/* 사진선택 폼 */

.PhotoList .row {
  width: 100%;
  margin: 0;
  gap: 1.2rem;
}

.PhotoList .col-4 {
  width: calc((100% - 2.6rem) / 3);
  padding: 0px;
  position: relative;
}

.PhotoList .col-4 .badge-delete {
  width: 16px;
  height: 16px;
  font-size: 0px;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}

.PhotoList .col-4 .PhotoImgbox {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .PhotoImgbox img {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .badge-number {
  width: 19px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
}

.PhotoList-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 14px;
}

.File-Box {
  background: #f6f6f6;
  border: 1px dashed var(--ColorTypeD);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File-Box img {
  max-width: 25px;
  margin: 0px auto;
}

.File-Box p {
  font-weight: var(--Medium);
  color: var(--ColorTyepB);
  font-size: 13px;
  margin-bottom: 0;
}

.File-Box.active {
  background: var(--ColorTypeC);
  border: 1px solid var(--ColorTypeC);
}

.File-Box img:nth-child(2) {
  display: none;
}

.File-Box.active img:nth-child(1) {
  display: none;
}

.File-Box.active img:nth-child(2) {
  display: block;
}

.File-Box.active p {
  color: #fff;
}

/* etc */

.fixed-bottom.mb-footer {
  margin-bottom: calc(95px + 8vh);
}

/* textarea */

.Input-placeholderbox {
  position: relative;
}

.Input-placeholder {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0.75rem 1.75rem;
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeD);
}

.Input-placeholder + textarea {
  position: relative;
}

.Input-placeholderbox textarea.form-control.InputType {
  background: transparent;
}

textarea.form-control.InputType:not(:empty) + .Input-placeholder {
  opacity: 0;
}

/* accordion (input-text) */
.accordion {
  --bs-accordion-color: var(--ColorTypeB);
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color: var(--ColorTypeD);
  --bs-accordion-border-radius: 3px;
  --bs-accordion-inner-border-radius: 3px;
  --bs-accordion-btn-padding-x: 0.75rem;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-color: var(--ColorTypeB);
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/store-main/store_dropdown.png);
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0.75rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--ColorTypeB);
  --bs-accordion-active-bg: #fff;
}

.accordion-button {
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 13px;
}

.accordion-button:not(.collapsed) {
  color: var(--ColorTypeB);
  background: #fff;
  box-shadow: none;
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  padding-top: 0.5rem;
}

.accordion-body.Input-placeholderbox .Input-placeholder {
  padding: 0.5rem 0.75rem;
}

/* accordion ver2 (input-checkbox)*/

.accordion.ver2 {
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.ver2 .accordion-item {
  border: 0px;
}

.ver2 .accordion-headerd.d-flex {
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  border-radius: var(--bs-accordion-border-radius);
}

.ver2 .accordion-headerd .form-check-input.CheckInput1 {
  margin: 0.75rem;
}

.ver2 .accordion-button {
  font-size: 16px;
}

.ver2 .accordion-button::after {
  background-position: center;
}

.ver2 .accordion-body {
  padding: 0rem 1rem 0.75rem;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:active {
  filter: none;
}

.form-check-input.CheckInput1 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_circle.png);
  border: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput1:checked[type="checkbox"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

.form-check-input.CheckInput2 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check.png);
  background-size: 16px auto;
  border: 0px;
  margin-top: 0;
  margin-left: 0px;
  margin-right: 0.25rem;
  float: none;
  width: 28px;
  height: 28px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput2:checked[type="checkbox"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.form-check-input.CheckInput2.small {
  background-size: 12px auto;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
}

.form-check-input.CheckInput2.small:checked[type="checkbox"] {
  border: 1px solid #ddd;
}

.form-check-input.CheckInput3 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check.png);
  border: 0px;
  margin-top: 0;
  margin-left: 0px;
  margin-right: 0.25rem;
  float: none;
  width: 22px;
  height: 22px;
  flex: none;
  box-shadow: none;
}

.form-check-input.CheckInput3:checked[type="checkbox"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.CheckLabel {
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.CheckInfoBtn {
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeD);
  border-bottom: 1px solid var(--ColorTypeD);
  position: absolute;
  right: 0.75rem;
}

.CheckInfoBtn:hover {
  color: var(--ColorTypeD);
}

.input-group-text {
  font-size: 16px;
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  background-color: transparent;
  border: 0px;
}

.form-select.Selectbox {
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
  line-height: 1;
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  background-size: 14px auto;
}

.form-select.Selectbox:focus {
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

select:required:invalid {
  color: var(--ColorTypeD);
}

select option {
  color: var(--ColorTypeB);
}

/* 서브 페이지 공통 */
.PageTit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.PageTit2 {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
}

.PageSib {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 17px;
}

/* 하단 결제 박스 */

.b-paybox {
  background: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
  opacity: 0;
  margin-bottom: -100px;
  transition: all 0.35s ease;
}

.b-paybox.show {
  opacity: 1;
  margin-bottom: 0;
}

.b-paybox .bar-icon {
  width: 45px;
  height: 5px;
  background-color: var(--ColorTypeD);
  border-radius: 3px;
}

.b-paybox .btnbox {
  gap: 0px 10px;
}

.h-45px {
  height: 45px !important;
}

/* 모바일 반응형 */
@media screen and (max-width: 320px) {
  br.Mob320 {
    display: none;
  }

  /* button */
  .btn-primary {
    height: 40px;
  }

  .btn-default {
    height: 40px;
  }

  .btn-secondary {
    height: 40px;
  }

  .btn-light {
    height: 40px;
  }

  .btn.type6 {
    height: 40px;
  }

  .btn.type7 {
    height: 40px;
  }

  .btn.type8 {
    height: 40px;
  }

  .form-control.InputType.h-50px {
    height: 40px !important;
  }

  .h-45px {
    height: 40px !important;
  }

  .modal-dialog.modal-sm {
    width: 90%;
  }

  .ver2 .accordion-button {
    font-size: 14px;
  }

  .ver2 .accordion-body {
    padding: 0rem 0rem 0.5rem;
  }

  .form-check.d-flex.align-items-center.Mob320 .CheckLabel {
    padding-right: 45px;
    word-break: keep-all;
  }

  .PageTit {
    font-size: 22px;
  }

  .PageSib {
    font-size: 14px;
  }
}

/* StoreAlertPage CSS start */
/* ------------------------ */
.StoreAlertPage .Store-AlertPage {
  margin: 20vh auto;
}

.StoreAlertPage img.Emoji {
  max-width: 100px;
}

.StoreAlertPage .StoreAlert-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.StoreAlertPage .StoreAlert-desc {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 13px;
}

.StoreAlertPage .StoreAlert-sub {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 13px;
}

/* 모바일 반응형 */

@media screen and (max-width: 320px) {
  .StoreAlertPage .Store-AlertPage {
    margin: 18vh auto;
  }
}

/* 2022.12.08 반응형 작업*/

.StoreAlertPage {
  max-width: 800px;
  margin: auto;
}

.StoreAlertPage::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

@media screen and (max-width: 800px) {
  .StoreAlertPage::before {
    display: none;
  }
}
/* ---------------------- */
/* StoreAlertPage CSS end */
