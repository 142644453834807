.MS-Main .MSPrdList {
    margin-bottom: 7rem;
}

.MS-Main .MSPrdList .MStitbox>.tit .tit-icon {
    display: inline-block;
    vertical-align: baseline;
    width: 26px;
    margin-left: 5px;
    margin-top: -10px;
}

.MS-Main .MSPrdList .MSitem .MScom {
    font-weight: var(--Bold);
    font-size: 18px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.MS-Main .MSPrdList .MSitem .MSname {
    font-weight: var(--Regular);
    font-size: 14px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.MS-Main .MSPrdList .MSitem .MSprice {
    font-weight: var(--Regular);
    font-size: 14px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.MS-Main .MSTypeList {
    margin-bottom: 15rem;
}

.MS-Main .MSTypeList .MStitbox>.tit .tit-icon2 {
    width: 26px;
    display: inline-block;
    margin-left: 5px;
    margin-top: -10px;
}

.MS-Main .MSTypeList .MSType .type-txt {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.MS-Main .MSForm {
    margin-bottom: 7rem;
}

.MS-Main .MSForm .MStitbox>.tit .sample-link {
    font-weight: var(--Regular);
    font-size: 14px;
    color: var(--ColorTypeC);
    align-self: end;
    margin-left: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--ColorTypeC);
}

.MS-Main .MSForm .MS-File {
    display: none;
}

.MS-Main .MSForm .MS-File-Label {
    cursor: pointer;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 1.5rem;
    border-radius: 3px;
    border: 1px solid var(--ColorTypeD);
    font-weight: var(--Regular);
    font-size: 16px;
    color: var(--ColorTypeD);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
    background-color: #fff;
}

.MS-Main .MSForm .MS-File-Label.active {
    color: var(--ColorTypeB);
}

.MS-Main .MSForm .MS-File-submit.btn-default {
    font-size: 20px;
}

.MS-Main .MSForm .MS-File-submit.btn-primary {
    font-size: 20px;
}

.MS-Main .MSForm .MS-File-submit.btn-default img.plus {
    max-width: 16px;
}

.MS-Main .MSForm .MSSampleLink {
    font-weight: var(--Regular);
    font-size: 14px;
    color: var(--ColorTypeC);
    text-decoration: underline;
    text-underline-position: under;
    transition: all 0.4s ease;
    opacity: 0;
    pointer-events: none;
}

.MS-Main .MSForm .MSSampleLink.show {
    opacity: 1;
    pointer-events: initial;
}


.MS-Main .MSForm .MSFormNav {
    gap: 0px 0.75rem;
}

.MS-Main .MSForm .MSFormNav.nav-pills .nav-link {
    border-radius: 30px;
    border: 1px solid var(--ColorTypeB);
    color: var(--ColorTypeB);
    font-weight: var(--Semibold);
    height: 40px;
    background-color: #fff;
}

.MS-Main .MSForm .MSFormNav.nav-pills .nav-link.active,
.MS-Main .MSForm .MSFormNav.nav-pills .show>.nav-link {
    background-color: var(--ColorTypeB);
    color: #fff;
}

.MS-Main .form-control.InputType.MSInput {
    height: 50px;
    font-size: 16px;
    padding: 0 1.5rem;
}

.MS-Main .form-control.InputType.MSInput::-webkit-outer-spin-button,
.MS-Main .form-control.InputType.MSInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MS-Main .contentNumlist>.tit {
    font-weight: var(--Semibold);
    font-size: 20px;
    letter-spacing: -0.5px;
    color: var(--ColorTypeB);
}

.MS-Main .contentNumlist>.listbox {
    background-color: #F7F7F7;
    border: 1px solid var(--ColorTypeD);
    border-radius: 5px;
    height: 250px;
    overflow-y: scroll;
    position: relative;
    padding: 1.5rem;
}

.MS-Main .contentNumlist>.listbox.scroll::-webkit-scrollbar {
    width: 20px;
}

.MS-Main .contentNumlist>.listbox.scroll::-webkit-scrollbar-thumb {
    background: var(--ColorTypeC);
    border-radius: 10px;
    background-clip: padding-box;
    border: 7px solid transparent;
}


.MS-Main .contentNumlist>.listbox .blank-txt {
    font-size: 18px;
    color: var(--ColorTypeD);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.MS-Main .contentNumlist>.listbox .num {
    font-size: 18px;
    letter-spacing: -0.45px;
    color: var(--ColorTypeC);
    margin-bottom: 0.25rem;
}

.MS-Main .contentNumlist>.listbox .num:last-child {
    margin-bottom: 0;
}

.MS-Main {
    position: relative;
}

.MS-Main .MSBackground {
    position: absolute;
    left: 0;
    top: 8rem;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.MS-Main .MSBackground img {
    position: absolute;
}

.MS-Main .MSBackground .bg-img1 {
    right: 8%;
    top: 5%;
}

.MS-Main .MSBackground .bg-img2 {
    left: 5%;
    top: 47%;
}

.MS-Main .MSBackground .bg-img3 {
    left: 5%;
    top: 65%;
}

.MS-Main .MSBackground .bg-img4 {
    right: 10%;
    bottom: 5%;
}


/* 머니쇼 홍보물용 페이지 추가 CSS */

.MS-Main .MSSubTitle {
    font-weight: var(--Bold);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.MS-Main .MSFormOption .radioItem {
    position: relative;
}

.MS-Main .MSFormOption .radioItem .form-check-input {
    position: absolute;
    left: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 1rem;
    height: 1rem;
}

.MS-Main .MSFormOption .radioItem .form-check-label {
    width: 100%;
    border: 1px solid var(--ColorTypeD);
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem 1.5rem 2.5rem;
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeD);
    cursor: pointer;
    word-break: break-word;
}

.MS-Main .MSFormOption .radioItem .form-check-input-diabled {
    position: absolute;
    left: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 1rem;
    height: 1rem;
}

.MS-Main .MSFormOption .radioItem .form-check-label-diabled {
    width: 100%;
    border: 1px solid var(--ColorTypeD);
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem 1.5rem 2.5rem;
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeD);
    cursor: pointer;
    word-break: break-word;
}

.MS-Main .MSFormOption .radioItem .form-check-label-diabled:checked {
    border: 1px solid var(--ColorTypeD);
}

.MS-Main .MSFormOption .radioItem .form-check-input:checked {
    background-color: var(--ColorTypeA);
    border-color: var(--ColorTypeA);
}

.MS-Main .MSFormOption .radioItem .form-check-input:checked+.form-check-label {
    border-color: var(--ColorTypeA);
    color: var(--ColorTypeA);
}

.MS-Main .MSFormOption .inputBox input {
    width: calc(50% - 1rem)
}

.MS-Main .MS-Infotxt {
    font-weight: var(--Regular);
    font-size: 16px;
    color: var(--ColorTypeB);
}


.MS-Main .MS-Infotxt span {
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
}

/* 모바일 반응형 */

@media screen and (max-width: 1080px) {

    .MS-Main .MSBackground {
        opacity: 0;
    }

    .MS-Main .MSTypeList {
        margin-bottom: 8rem;
    }

}


@media screen and (max-width: 768px) {

    .MS-Main .MSFormOption .inputBox input {
        width: 100%
    }
}