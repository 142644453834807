.TLoginPage .LogoImg {
    margin-top: 5vh;
    margin-bottom: 7vh;
}

.TLoginPage .LogoImg img {
    width: 100%;
    max-width: 130px;
}

.TLoginPage .InfoSearch {
    font-weight: var(--Regular);
    font-size: 18px;
    color: var(--ColorTypeC);
}

.TLoginPage .LoginBox>.row {
    margin-bottom: 12px;
    --bs-gutter-x: 0;
}

.TLoginPage .LoginBox>.row:nth-child(1) {
    margin-top: 12px;
}

.TLoginPage .InfoSearch>a {
    position: relative;
}

.TLoginPage .InfoSearch>a:hover {
    color: inherit;
}

.TLoginPage .InfoSearch>a::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 16px;
    background: var(--ColorTypeC);
    margin: 0px 0.7rem;
}

.TLoginPage .InfoSearch>a:last-child::after {
    display: none;
}

.TLoginPage .btn-primary {
    margin-bottom: 12px;
    margin-top: 20px;
}

.TLoginPage .LoginBox>.row {
    margin-bottom: 12px;
    --bs-gutter-x: 0;
}

.TLoginPage .JoinBox>.InfoTxt {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeC);
    text-align: center;
    position: relative;
}

.JoinBox>.InfoTxt::before {
    content: "";
    width: 0%;
    /* height: 1px;
    background: var(--ColorTypeC);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1; */
}


/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .TLoginPage .LogoImg img {
        max-width: 100px;
    }

    .TLoginPage .InfoSearch {
        font-size: 14px;
    }

    .TLoginPage .btn-primary {
        margin-top: 10px;
    }

    .TLoginPage .JoinBox.mt-5 {
        margin-top: 2rem !important;
    }

    .TLoginPage .JoinBox>.InfoTxt {
        font-size: 14px;
    }

}

@media screen and (max-width: 320px) {

    .TLoginPage .LogoImg {
        margin-bottom: 6vh;
    }

    .TLoginPage .LogoImg img {
        max-width: 85px;
    }

}