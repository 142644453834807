.MS-Intro {
    position: absolute;
    background: url(https://www.flexdaycdn.net/public/images/moneyshow/MS_intro_bg2.png) repeat center/ 60%;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MS-Intro .inner {
    width: 90%;
    max-width: 320px;
}

.MS-Intro .MS_intro_logo {
    width: 70%;
    max-width: 227px;
    margin: auto;
}

.MS-Intro .MS_intro_txt {
    font-size: 2rem;
    letter-spacing: -0.75px;
    color: #E1DACF;
    text-align: center;
    margin: 4rem auto 0.5rem;
}

.MS-Intro .MS_intro_img {
    width: 85%;
    margin: auto;
    max-width: 281px;
}

.MS-Intro .MS_intro_gentz {
    width: 75%;
    max-width: 241px;
    margin: 2rem auto 2.5rem;
}

.MS-Intro .MS_intro_btn {
    background-color: #E1DACF;
    font-weight: bold;
    font-size: 1.4rem;
    color: #69221D;
    border-radius: 40px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
}

.MS-Intro .MS_intro_btn:hover {
    color: #69221D;
    background-color: #fff;
}

.MS-Intro .MS_intro_btn>img {
    width: 10px;
    margin-left: 1.25rem;
}

.MS-Intro .flexLogo {
    position: absolute;
    right: 3rem;
    bottom: 2.5rem;
    width: 15%;
    max-width: 121px;
}

@media screen and (max-width: 1024px) {

    .MS-Intro .inner {
        width: 40%;
    }

    .MS-Intro .MS_intro_txt {
        font-size: 1.5rem;
    }

    .MS-Intro .MS_intro_btn {
        font-size: 1.25rem;
    }

    .MS-Intro .flexLogo {
        max-width: 50px;
    }

}


@media screen and (max-width: 1024px) and (orientation: portrait) {}

@media screen and (max-width: 1024px) and (orientation: landscape) {

    .MS-Intro .MS_intro_logo {
        width: 40%;
    }

    .MS-Intro .MS_intro_txt {
        font-size: 1.3rem;
        margin: 2rem auto 0;
    }

    .MS-Intro .MS_intro_img {
        width: 45%;
    }

    .MS-Intro .MS_intro_gentz {
        width: 45%;
        margin: 1rem auto 1.5rem;
    }

    .MS-Intro .MS_intro_btn {
        font-size: 1rem;
        max-width: 70%;
        margin: auto;
    }

    .MS-Intro .flexLogo {
        width: 10%;
        bottom: 1.5rem;
    }

}