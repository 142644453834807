.TPaymentPage .TicketBox .PageTit2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 28px;
}

.TPaymentPage .TicketBox .PageTit2 img {
    margin-right: 5px;
}

.TPaymentPage .TicketBox.Recipient .PageTit2 img.man {
    width: 17px;
    margin-bottom: 5px;
}

.TPaymentPage .TicketBox.Check .PageTit2 img.rice {
    width: 22px;
    margin-bottom: 5px;
}

.TPaymentPage .TicketBox {
    border-bottom: 10px solid var(--ColorTypeE);
}

.TPaymentPage .TicketBox.Check .row {
    gap: 0 30px
}

.TPaymentPage .TicketBox.Check .row .prd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 25px;
    margin-bottom: 0.3rem;
    word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 17px;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    font-size: 24px;
}

.TPaymentPage .TicketBox.Info .PageTit2 img.rice {
    width: 22px;
    margin-bottom: 5px;
}

.TPaymentPage .TicketBox.Info .Total .tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price {
    font-weight: var(--Medium);
    font-size: 17px;
    color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price span {
    font-weight: var(--Semibold);
    font-size: 24px;
}

.TPaymentPage .form-radiobox .RadioLabel {
    width: 100%;
    height: 55px;
    padding: 0.75rem;
    border-radius: 3px;
    border: 1px solid var(--ColorTypeD);
    font-weight: var(--Regular);
    font-size: 17px;
    color: var(--ColorTypeB);
    transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3 {
    transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3:checked[type=radio] {
    background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
    background-color: #fff;
    border-color: transparent;
}

.TPaymentPage .form-check-input.CheckInput3:checked[type=radio]+.RadioLabel {
    border-color: var(--ColorTypeA);
}

.TPaymentPage .accordion.ver3 {
    --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-icon-width: 1rem;
    --bs-accordion-btn-icon-transform: rotate(180deg);
    --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TPaymentPage .accordion-button {
    font-size: 17px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentPage .TicketBox .PageTit2 {
        font-size: 20px;
    }

    .TPaymentPage .TicketBox.Check .row {
        gap: 0 15px
    }

    .TPaymentPage .TicketBox.Check .row .prd-tit {
        font-size: 18px;
    }

    .TPaymentPage .TicketBox.Check .row .prd-sub {
        font-size: 13px;
    }

    .TPaymentPage .TicketBox.Check .row .prd-price {
        font-size: 18px;
    }

    .TPaymentPage .form-control.InputType {
        height: 50px;
    }

    .TPaymentPage .form-checkbox {
        height: 50px;
    }

    .TPaymentPage .form-radiobox .RadioLabel {
        font-size: 14px;
    }

    .TPaymentPage .TicketBox.Info .Total .tit {
        font-size: 14px;
    }

    .TPaymentPage .TicketBox.Info .Total .price {
        font-size: 13px;
    }

    .TPaymentPage .TicketBox.Info .Total .price span {
        font-size: 16px;
    }

    .TPaymentPage .accordion-button {
        font-size: 13px;
    }



}

@media screen and (max-width: 320px) {



}