.demo .TAddTicketPage .addprd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 25px;
}

.demo .TAddTicketPage .PhotoList .col-4 {
    width: calc((100% - 2.6rem)/4);
}

.demo .TAddTicketPage .AddPrd-Formitem .btn-default {
    font-size: 30px;
}



/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  
    .demo .TAddTicketPage .AddPrdBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TAddTicketPage .addprd-tit {
        font-size: 16px;
    }

    .demo .TAddTicketPage .PhotoList .col-4 {
        width: calc((100% - 2.6rem)/3);
    }

    .demo .TAddTicketPage .AddPrd-Formitem .btn-default {
        font-size: 18px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 50px !important;
    }

}