.PosionCenter {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.LogoImg {
    margin-bottom: 4rem;
    /* margin-top: 5%; */
}

.LogoImg img {
    width: 100%;
    max-width: 300px;
}


.DelBtn {
    --bs-btn-padding-y: 10px;
}

.DelIcon {
    height: 30px;
}

.InfoSearch {
    font-weight: var(--Regular);
    font-size: 14px;
    color: var(--ColorTypeC);
}

.LoginBox>.row {
    margin-bottom: 12px;
    --bs-gutter-x: 0;
}

.LoginBox>.row:nth-child(1) {
    margin-top: 12px;
}

.InfoSearch>a {
    position: relative;
}

.InfoSearch>a::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 16px;
    background: var(--ColorTypeC);
    margin: 0px 0.7rem;
}

.InfoSearch>a:last-child::after {
    display: none;
}

.LoginBox>.row {
    margin-bottom: 12px;
    --bs-gutter-x: 0;
}

.card {
    background-color: #fff;
}

.card-text {
    margin-top: 0%;
    position: absolute;
    top: 85%;
    left: 50%;
    margin-left: -150px;
    color: var(--ColorTypeA);
    /* background-color:rgba(255,255,255,0.6); */
    width: 300px;
}

.card-overlay-img {
    position: absolute;
    left: 5%;
}

.JoinBox>.InfoTxt {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeC);
    text-align: center;
    position: relative;
}

.JoinBox>.InfoTxt::before {
    content: "";
    width: 100%;
    height: 1px;
    background: var(--ColorTypeC);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.JoinBox>.InfoTxt span {
    background: #fff;
    padding: 0px 12px;
}


/* 모바일 반응형 */

@media screen and (max-width: 320px) {

    /* 로그인 */
    .LogoImg {
        margin-bottom: 8vh;
    }

    .LogoImg img {
        max-width: 95px;
    }

    .JoinBox.mob-mt-5 {
        margin-top: 2rem !important;
    }

}


/* 23.06.08 개선 */


.Pos2.LoginPage2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding-bottom: 5rem;
}

.Pos2.LoginPage2 .Inner {
    max-width: 450px;
}

.Pos2.LoginPage2 .LogoImg img {
    max-width: 285px;
    margin-left: -1rem;
}

.Pos2.LoginPage2 .form-control.InputType {
    border-radius: 8px;
}

.Pos2.LoginPage2 .LoginBox .form-control.InputType::placeholder {
    font-size: 17px;
}

.Pos2.LoginPage2 #submitBtn {
       margin-top: 2rem;
    height: 70px;
    border-radius: 8px;
    font-size: 22px;
}

.Pos2.LoginPage2 #submitBtn:focus {
    border-color: var(--ColorTypeB);
    background-color: var(--ColorTypeB);
}


@media screen and (max-width: 599px) {
    .Pos2 .Inner {
        max-width: 320px;
    }

}