@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    transform: rotate(0.04deg);
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Pretendard', sans-serif;
}

.MMS-wrap p {
    margin: 0;
}

.MMS-wrap {
    font-size: 16px;
    width: 100%;
    max-width: 520px;
    margin: auto;
    padding: 1.25rem 2.5rem 2.5rem;
    box-sizing: border-box;
    background: url(https://www.flexdaycdn.net/public/images/static/mms/gentz-bg.png) no-repeat center/cover;
}

.MMS-wrap .MMS-Line {
    width: 100%;
    height: 35px;
    border: 10px solid #AE863F;
    background-color: #896730;
    box-sizing: border-box;
    border-radius: 30px;
    margin-top: 2rem;
}

.MMS-wrap .MMS-Contents {
    width: 90%;
    margin: -18px auto 0;
}

.MMS-wrap .MMS-QR {
    background-color: #fff;
    padding: 2.5rem 0 1.8rem;
    box-sizing: border-box;
}

.MMS-wrap .QR-Img {
    width: 50%;
    max-width: 175px;
    margin: auto;
    display: block;
}

.MMS-wrap .QR-Number {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    text-decoration: inherit;
    color: inherit;
}

.MMS-wrap .MMS-Deco {
    margin-top: -5px;
    margin-bottom: -5px;
}

.MMS-wrap .MMS-Deco img {
    width: 100%;
}

.MMS-wrap .MMS-Items {
    background-color: #fff;
    padding: 2rem 3rem 1.8rem;
    box-sizing: border-box;
}

.MMS-wrap .MMS-Item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.MMS-wrap .MMS-Item.last {
    margin-bottom: 0px;
}

.MMS-wrap .MMS-Item .MMS-Tit {
    font-size: 1.1rem;
    color: #606060;
    font-weight: 500;
    min-width: 30%;
}

.MMS-wrap .MMS-Item .MMS-Desc {
    font-size: 1.2rem;
    color: #000000;
    font-weight: 600;
    text-align: right;
    word-break: break-word;
    line-height: 1.3;
}

.MMS-wrap .MMS-Item .MMS-Desc.info {
    width: 100%;
}

.MMS-wrap .MMS-Item .MMS-Desc .small {
    display: block;
    font-size: 0.95rem;
    font-weight: 400;
    color: #767676;
    margin-top: 0.5rem;
    word-break: keep-all;
}

.MMS-wrap .MMS-B-Logo {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.MMS-wrap .MMS-B-Logo img {
    width: 75%;
    max-width: 155px;
    margin: auto;
    display: block;
}

.MMS-wrap .MMS-Flexday {
    margin-top: 3.8rem;
}

.MMS-wrap .MMS-Flexday img {
    width: 100px;
    margin: auto;
    display: block;
}

.MMS-wrap a[href^=tel] {
    color: inherit;
    text-decoration: none;
}

html {
    font-size: 16px;
}

@media screen and (max-width: 440px) {
    html {
        font-size: 15px;
    }
}

@media screen and (max-width: 400px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 380px) {
    html {
        font-size: 13px;
    }
}

@media screen and (max-width: 350px) {
    html {
        font-size: 12px;
    }
}