.LoginPage .PosionCenter {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.LoginPage .LogoImg {
  margin-bottom: 4rem;
}

.LoginPage .LogoImg img {
  width: 50%;
  max-width: 110px;
}

.LoginPage .LogoImg label {
  color: var(--ColorTypeC);
  opacity: 0.8;
  font-size: 0.85rem;
}

.LoginPage .InfoSearch {
  font-weight: var(--Regular);
  font-size: 14px;
  color: var(--ColorTypeC);
}

.LoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.LoginPage .LoginBox > .row:nth-child(1) {
  margin-top: 12px;
}

.LoginPage .InfoSearch > a {
  position: relative;
}

.LoginPage .InfoSearch > a::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: var(--ColorTypeC);
  margin: 0px 0.7rem;
}

.LoginPage .InfoSearch > a:last-child::after {
  display: none;
}

.LoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.LoginPage .JoinBox > .InfoTxt {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeC);
  text-align: center;
  position: relative;
}

.LoginPage .JoinBox > .InfoTxt::before {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--ColorTypeC);
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.LoginPage .JoinBox > .InfoTxt span {
  background: #fff;
  padding: 0px 12px;
}

/* 모바일 반응형 */

@media screen and (max-width: 320px) {
  /* 로그인 */
  .LoginPage .LogoImg {
    margin-bottom: 8vh;
  }

  .LoginPage .LogoImg img {
    max-width: 95px;
  }

  .LoginPage .JoinBox.mob-mt-5 {
    margin-top: 2rem !important;
  }
}
