.TPaymentConfirmPage .Payment-title img {
    max-width: 60px;
}

.TPaymentConfirmPage .PageTit2 {
    font-size: 30px;
    line-height: 1.3;
}

.TPaymentConfirmPage .PageSub2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    font-size: 20px;
}

.TPaymentConfirmPage .PaymentResult {
    background-color: #f7f7f7;
    border-radius: 3px;
}

.TPaymentConfirmPage .PaymentResult-Tit {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    border-bottom: 1px solid var(--ColorTypeC);
    margin-bottom: 30px;
}

.TPaymentConfirmPage .PaymentResult-Item .tit {
    font-weight: var(--Medium);
    font-size:20px;
    color: var(--ColorTypeB);
}

.TPaymentConfirmPage .PaymentResult-Item .price {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
 
    .TPaymentConfirmPage .Payment-title img {
        max-width: 40px;
    }

    .TPaymentConfirmPage .PageTit2 {
        font-size: 25px;
    }

    .TPaymentConfirmPage .PageSub2 {
        font-size: 15px;
    }

    .TPaymentConfirmPage .PaymentResult-Tit {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .TPaymentConfirmPage .PaymentResult-Item .tit {
        font-size: 14px;
    }

    .TPaymentConfirmPage .PaymentResult-Item .price {
        font-size: 14px;
    }


}

@media screen and (max-width: 320px) {



}