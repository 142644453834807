/* 결제-결제내역 */
.PaymentPage .PayPriceTotal {
    border-bottom: 1px solid var(--ColorTypeD);
}

.PaymentPage .PayPriceTotal .row .tit {
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeB);
}

.PaymentPage .PayPriceTotal .row .price {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeB);
}

.PaymentPage .PayPriceTotal .row .price .price-total {
    font-weight: var(--Semibold);
    font-size: 17px;
    word-break: break-word;
}

.PaymentPage .PayPriceAdd {
    background-color: #f7f7f7;
    border-bottom: 1px solid var(--ColorTypeD);
}

.PaymentPage .PayPriceAdd .row .tit {
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeB);
}

.PaymentPage .PayPriceAdd .row .price {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeB);
}

.PaymentPage .PayPriceAdd .row .price .price-add {
    font-weight: var(--Semibold);
    font-size: 20px;
    color: var(--ColorTypeA);
    word-break: break-word;
}

.PaymentPage .PaymentList > .row > .col-sm-12 {
    position: relative;
}
.PaymentPage .PaymentList > .row > .col-sm-12::after {
    content: "";
    background: linear-gradient(to top, rgba(0,0,0,0.05) 5%, transparent 90%);
    width: 100%;
    height: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.7;
}

.PaymentPage .PaymentList-Item {
    background: #f7f7f7;
    border-radius: 3px;
}

.PaymentPage .PaymentList-Item .prd-titbox .name {
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.PaymentPage .PaymentList-Item .prd-titbox .use {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeB);
}

.PaymentPage .PaymentList-Item .prd-infobox .price {
    font-weight: var(--Semibold);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
    width: calc(100% - 110px);
}

.PaymentPage .PaymentList-Item .prd-infobox .date {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeB);
    width: 100px;
}

.PaymentPage .Payment-Confirm.d-flex .btn-light {
    font-size: 18px;
}

.PaymentPage .Payment-Confirm.d-flex .btn-default {
    font-size: 18px;
}

.PaymentPage .qr-scan {
    width: 20px;
}

/* 결제 - 결제완료 */
.PaymentPage .PaymentResult {
    background-color: #f7f7f7;
    border-radius: 3px;
}

.PaymentPage .PaymentResult-Item .tit {
    font-weight: var(--Medium);
    font-size: 16px;
    color: var(--ColorTypeB);
}

.PaymentPage .PaymentResult-Item .price {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.PaymentPage .PaymentResult-Item.add-price .tit {
    font-weight: var(--Semibold);
    color: var(--ColorTypeA);
}

.PaymentPage .PaymentResult-Item .price span {
    font-weight: var(--Semibold);
    font-size: 18px;
}

.PaymentPage .PaymentResult-Item.add-price .price {
    color: var(--ColorTypeA);
}


.PaymentPage .Payment-info-txt {
    font-weight: var(--Medium);
    font-size: 14px;
    color: var(--ColorTypeB);
}

.PaymentPage .Payment-info-txt a {
    border-bottom: 1px solid var(--ColorTypeB);
}

.PaymentPage .Payment-info-txt a:hover {
    color: var(--ColorTypeB);
}

/* 결제 - 교환권 확인 */

.PaymentPage .GitfConfirm .imgbox .txtbox::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #000;
    opacity: 0.5;
}

.PaymentPage .GitfConfirm .imgbox .center-txt {
    font-weight: var(--Semibold);
    font-size: 35px;
    color: var(--ColorTypeB);
    line-height: 1.2;
    background-color: rgba(255,255,255,0.6);
    width: 50%;
    height: 50%;
    border-radius: 50%;
}

.PaymentPage .GitfConfirm .imgbox .alert-txt {
    font-weight: var(--Regular);
    font-size: 13px;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    text-align: center;
    width: 100%;
}

.PaymentPage .GitfConfirm .infobox .tit {
    font-weight: var(--Medium);
    font-size: 15px;
    color: var(--ColorTypeC);
}

.PaymentPage .GitfConfirm .infobox .content {
    font-weight: var(--Medium);
    font-size: 15px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.PaymentPage .Payment-info-txt2 {
    font-weight: var(--Medium);
    font-size: 13px;
    color: var(--ColorTypeC);
}


/* 모바일 반응형 */

@media screen and (max-width: 320px) {

    .PageTit {
        font-size: 22px;
    }

    .PageSib {
        font-size: 14px;
    }

    .Payment-Inputbox .price-txt {
        margin-top: 0.5rem !important;
    }

    .PaymentPage .col-md-6.col-sm-12.mb-4-5.px-4-5 {
        padding: 0 1rem !important;
    }

    .PaymentPage .Payment-Confirm.d-flex .btn-light {
        font-size: 15px;
    }

    .PaymentPage .Payment-Confirm.d-flex .btn-default {
        font-size: 15px;
    }

}


/* 2022.12.08 - 현금영수증 부분 추가 */


.PaymentPage.ver2 .PaymentTop {
    position: sticky;
    top: 0;
    z-index: 10;
    padding-top: 1rem;
    background-color: #fff;
}

.PaymentPage.ver2 .Payment-Confirm {
    border-top: 1px solid var(--ColorTypeD);
}

.PaymentPage.ver2 .Payment-AddInfo .info-txt {
    font-weight: var(--Regular);
    font-size: 16px;
    color: var(--ColorTypeC);
}

.PaymentPage.ver2 .CashReceiptsBox {
    background-color: #f7f7f7;
}

.PaymentPage.ver2 .CashReceiptCheck .form-check-input {
    float: none;
    margin: 0;
    vertical-align: middle;
}

.PaymentPage.ver2 .CashReceiptCheck .form-check-input:checked {
    background-color: var(--ColorTypeA);
    border-color: var(--ColorTypeA);
}

.PaymentPage.ver2 .CashReceiptCheck .form-check-label {
    font-size: 14px;
    color: var(--ColorTypeB);
}

.PaymentPage.ver2 .CashReceiptInput {
    align-items: center;
    position: absolute;
    opacity: 0;
    z-index: -999;
    pointer-events: none;
}

.PaymentPage.ver2 .CashReceiptCheck .form-check-input#CashReceipts1:checked~.CashReceiptInput {
    display: flex;
    position: initial;
    opacity: initial;
    z-index: initial;
    pointer-events: initial;
}

.PaymentPage.ver2 .CashReceiptInput p {
    font-size: 14px;
    color: var(--ColorTypeB);
    word-break: keep-all;
}

.PaymentPage.ver2 .CashReceiptInput p>.ColorA {
    color: var(--ColorTypeA);
}

.PaymentPage.ver2 .CashReceiptInput .InputType {
    min-width: 190px;
}

.PaymentPage.ver2 input[type="number"]::-webkit-outer-spin-button,
.PaymentPage.ver2 input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.CheckVisitor .CheckVisitor-title {
    margin-top: 20vh;
}

.CheckVisitor .CheckVisitor-title .tit {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 25px;
}

.CheckVisitor .CheckVisitor-title .sub {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 15px;
}

.CheckVisitor .BtnGroup {
    gap: 0px 10px;
}

.CheckVisitor .BtnGroup .form-check-label {
    padding: 0.6rem 0;
}

.CheckVisitor .BtnGroup .form-check-label:hover {
    border: 1px solid var(--ColorTypeC);
    background: #fff;
}

.CheckVisitor .BtnGroup .btn-check:checked+.btn.form-check-label {
    color: #fff;
    background: var(--ColorTypeB);
    border-color: var(--ColorTypeB);
}

.CheckVisitor .confirm-txt {
    font-size: 15px;
    color: var(--ColorTypeC);
}

.PaymentPage .Payment-Contents .Payment-Inputbox .form-control {
    font-size: 16px;
}
.PaymentPage .Payment-Contents .Payment-Inputbox .form-control::placeholder {
    font-size: 16px;
}
.PaymentPage .Payment-Contents .Payment-Inputbox .price-txt {
    top: 50% !important;
    transform: translateY(-50%);
    margin-top: 0px !important;
}

.PaymentPage.ver2 .Payment-Confirm .btn {
    width: 50%;
    font-size: 20px;
    height: 65px;
}