.TPaymentListPage {
    position: relative;
}

.TPaymentListPage::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    z-index: -1;
    min-height: 100vh;
}

.TPaymentListPage .PayCateTab .nav-pills {
    background-color: #fff;
    border-bottom: 1px solid var(--ColorTypeE);
}

.TPaymentListPage .PayCateBtn {
    font-weight: var(--Regular);
    font-size: 25px;
    color: var(--ColorTypeD);
    border-radius: 0;
    --bs-nav-link-padding-x: 0.5rem;
    position: relative;
}

.TPaymentListPage .nav-pills .PayCateBtn.active {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    border-radius: 0;
    background-color: transparent;
    border-bottom: 3px solid var(--ColorTypeA);
}

.TPaymentListPage .PayCateBtn.active::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0.2rem;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
}

.TPaymentListPage .PayListItem {
    margin-bottom: 1rem;
}

.TPaymentListPage .PayListItem .infobox {
    background-color: #fff;
    border: 2px solid var(--ColorTypeE);
    border-bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.TPaymentListPage .PayListItem .infobox .store-name {
    font-weight: var(--Medium);
    font-size: 22px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TPaymentListPage .PayListItem .infobox .prd-name {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeA);
    word-break: break-word;
}

.TPaymentListPage .PayListItem .infobox .prd-price {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TPaymentListPage .PayListItem .infobox .prd-price span {
    margin-right: 5px;
}

.TPaymentListPage .PayListItem .infobox .ViewBtn {
    width: 14px;
}

.TPaymentListPage .PayListItem .middlebox {
    background-color: #fff;
    position: relative;
}

.TPaymentListPage .PayListItem .middlebox .circle {
    background-color: #f7f7f7;
    border: 2px solid var(--ColorTypeE);
    width: 15px;
    height: 30px;
    border-radius: 0 90px 90px 0;
}

.TPaymentListPage .PayListItem .middlebox .circle.left {
    border-radius: 0 90px 90px 0;
    border-left: 0px;
}

.TPaymentListPage .PayListItem .middlebox .circle.right {
    border-radius: 90px 0 0 90px;
    border-right: 0px;
}

.TPaymentListPage .PayListItem .middlebox .line {
    width: calc(100% - 30px);
    border: 1px dashed var(--ColorTypeD);
    position: relative;
}

.TPaymentListPage .PayListItem .btnbox {
    background-color: #fff;
    border: 2px solid var(--ColorTypeE);
    border-top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.TPaymentListPage .PayListItem .btn-default {
    background: #fff;
    border: 0px;
    color: var(--ColorTypeB);
}

.TPaymentListPage .PayListItem .btn-default:hover, .TPaymentListPage .PayListItem .btn-default.btn:first-child:active{
    background: #fff;
    color: var(--ColorTypeB);
}

.TPaymentListPage .PayListItem .middlebox::before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    z-index: -1;
}

.TPaymentListPage .PayListItem.used .infobox-txt {
    opacity: 0.5;
}

.TPaymentListPage .PayListItem.used .infobox .prd-name {
    color: var(--ColorTypeB);
}

.TPaymentListPage .PayListItem.used .middlebox::before {
    background-color: #f7f7f7;
    z-index: 1;
}

.TPaymentListPage .PayListItem.used .btnbox {
    display: none;
}

.TPaymentListPage .PayListItem.used .middlebox .line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to top, transparent, rgba(0,0,0,0.1));
    width: 100%;
    height: 4px;
    z-index: 3;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentListPage .PayCateBtn {
        font-size: 20px;
    }

    .TPaymentListPage .PayListItem .infobox .store-name {
        font-size: 18px;
    }

    .TPaymentListPage .PayListItem .infobox .prd-name {
        font-size: 22px;
    }

    .TPaymentListPage .PayListItem .infobox .prd-price {
        font-size: 16px;
    }

    .TPaymentListPage .PayListItem .infobox .ViewBtn {
        width: 12px;
    }

}

@media screen and (max-width: 320px) {

}

.TPaymentListPage .PayCateBtn {
    line-height: 1.15;
}
.TPaymentListPage .tab-content {
    padding-top: 0;
}