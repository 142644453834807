.TPayDetailPage .PayDetail {
    border: 1px solid var(--ColorTypeD);
    border-radius: 5px;
    box-shadow: 2px 2px 5px 3px rgb(195 195 195 / 20%);
}

.TPayDetailPage .PayDetail-Tit {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    border-bottom: 1px solid var(--ColorTypeE);
    margin-bottom: 30px;
    padding-bottom: 15px;
}

.TPayDetailPage .PayDetail .row {
    gap: 0 15px
}

.TPayDetailPage .PayDetail .row .prd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 22px;
    margin-bottom: 0.3rem;
    word-break: break-word;
}

.TPayDetailPage .PayDetail .row .prd-sub {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 22px;
    margin-bottom: 0;
    word-break: break-word;
}

.TPayDetailPage .PayDetail-Item .tit {
    font-weight: var(--Medium);
    font-size:20px;
    color: var(--ColorTypeB);  
    width: 80px;
}

.TPayDetailPage .PayDetail-Item .price {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
    width: calc(100% - 80px);
}

.TPayDetailPage .PayInfotxt {
    font-weight: var(--Regular);
    font-size: 17px;
    color: var(--ColorTypeC);
    margin-bottom: 5rem;
    text-indent: -12px;
    padding-left: 12px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPayDetailPage .PayDetail-Tit {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .TPayDetailPage .PayDetail .row .prd-tit {
        font-size: 15px;
    }

    .TPayDetailPage .PayDetail .row .prd-sub {
        font-size: 15px;
    }

    .TPayDetailPage .PayDetail .row .prd-price {
        font-size: 18px;
    }

    .TPayDetailPage .PayDetail-Item .tit {
        font-size: 14px;
    }

    .TPayDetailPage .PayDetail-Item .price {
        font-size: 14px;
    }

    .TPayDetailPage .PayInfotxt {
        font-size: 14px;
    }



}

@media screen and (max-width: 320px) {

   

}