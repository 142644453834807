.PrdCatePage .PrdCate-InputBox {
  margin: 4rem 0;
}

.PrdCatePage .PrdCateBtn {
  font-weight: var(--Regular);
  font-size: 20px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
}

.PrdCatePage .nav-pills .PrdCateBtn.active {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
  border: 0;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 3px solid var(--ColorTypeA);
}

.PrdCatePage .PrdCateBtn.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0.2rem;
  width: 6px;
  height: 6px;
  background: var(--ColorTypeA);
  border-radius: 50%;
}

.PrdCatePage .PrdCate-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
}

.PrdCatePage .PrdCateBlank {
  margin-top: 15vh;
}

.PrdCatePage .PrdCateBlank .desc {
  font-weight: var(--Bold);
  font-size: 14px;
  color: var(--ColorTypeB);
}

.PrdCatePage .EditGiftBox {
  border-bottom: 1px solid var(--ColorTypeE);
}

.PrdCatePage .EditGiftBox.first {
  border-top: 5px solid var(--ColorTypeE);
}

.PrdCatePage .EditGiftBox .row .txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  word-break: break-word;
}

.PrdCatePage .EditGiftBox .row img {
  width: 25px;
}

.PrdCatePage .EditGiftBox2 {
  border-bottom: 1px solid var(--ColorTypeE);
}

.PrdCatePage .EditGiftBox2.first {
  border-top: 1px solid var(--ColorTypeE);
}

.PrdCatePage .EditGiftBox2 .row .txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  word-break: break-word;
  padding-right: 20px;
}

.PrdCatePage .EditGiftBox2 .row img {
  width: 22px;
}

.PrdCatePage .EditGiftBox2 .d-flex .txt {
  position: relative;
  width: calc(100% - 140px);
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  word-break: break-word;
}

.PrdCatePage .EditGiftBox2 .d-flex .txt .form-control.InputType {
  width: calc(100% + 0.75rem);
  position: absolute;
  left: -0.75rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.PrdCatePage .EditGiftBox2 .d-flex .txt.active .form-control.InputType {
  display: block;
}

.PrdCatePage .EditGiftBox2 .d-flex .btnbox {
  width: 120px;
}

.PrdCatePage .EditGiftBox2 .CateList {
  background-color: var(--ColorTypeE);
}

.PrdCatePage .EditGiftBox2 .CateList .cate-name {
  width: calc(100% - 46px);
  word-break: break-word;
  font-size: 14px;
  color: var(--ColorTypeB);
}

.PrdCatePage .EditGiftBox2 .CateList .delete {
  width: 26px;
}

/* modal */
.PrdCatePage .modal-dialog.modal-sm.modal-bottom {
  position: absolute;
  left: 2.5%;
  bottom: 4%;
  width: 95%;
}

.PrdCatePage .modal.fade .modal-dialog.modal-bottom {
  transform: translate(0, 50px);
}

.PrdCatePage .modal.show .modal-dialog.modal-bottom {
  transform: none;
}

.PrdCatePage .modal-dialog.modal-bottom.modal-sm .modal-content {
  background-color: transparent;
}

.PrdCatePage .modal-txt1 {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  width: 100%;
  margin-bottom: 0;
  padding: 0.75rem 0;
}

.PrdCatePage .modal-dialog.modal-bottom.modal-sm .modal-body .txtbox {
  border-bottom: 1px solid var(--ColorTypeD);
}

.PrdCatePage
  .modal-dialog.modal-bottom.modal-sm
  .modal-body
  .txtbox:last-child {
  border-bottom: 0;
}

/* 2022.12.08 반응형 작업*/

.PrdCatePage {
  max-width: 800px;
  margin: auto;
}

.PrdCatePage .navbar {
  max-width: 800px;
  margin: auto;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.PrdCatePage::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

.PrdCatePage .fixed-bottom {
  max-width: 800px;
  margin: auto;
}

.PrdCateTab .nav-tabs {
  border-bottom: 0;
}

@media screen and (max-width: 800px) {
  .PrdCatePage::before {
    display: none;
  }
  .PrdCatePage .navbar {
    border-left: 0;
    border-right: 0;
  }
}
