/*Font Pretendard */

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    transform: rotate(0.04deg);
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    transform: rotate(0.04deg);
    font-style: normal;
}


body {
    margin: 0;
    font-family: 'Pretendard', sans-serif;
}

.MMS-wrap2 p {
    margin: 0;
}

.MMS-wrap2 {
    width: 100%;
    max-width: 520px;
    margin: auto;
}

.MMS-wrap2 .MMS-Top {
    padding: 1.25rem 2.5rem 3rem;
    box-sizing: border-box;
    background: url(https://www.flexdaycdn.net/public/images/static/mms/gentz-bg.png) no-repeat center/cover;
    padding-top: 2rem;
}

.MMS-wrap2 .MMS-Product {
    position: relative;
    width: 55%;
    max-width: 245px;
    margin: 20px auto 0;
}

.MMS-wrap2 .MMS-PrdDeco {
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
    width: 2.75rem;
}

.MMS-wrap2 .MMS-PrdImg {
    width: 100%;
    font-size: 0;
    margin: auto;
    border: 3px solid #7A211F;
    padding: 3px;
    box-sizing: border-box;
}

.MMS-wrap2 .MMS-PrdImg img {
    width: 100%;
}

.MMS-wrap2 .MMS-GentsLogo {
    width: 75%;
    max-width: 155px;
    margin: auto;
    display: block;
    margin-top: 15px;
}

.MMS-wrap2 .MMS-Bottom {
    padding: 3.5rem 10% 0;
    box-sizing: border-box;
    background-color: #7A211F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.MMS-wrap2 .MMS-Items {
    width: 48%;
}

.MMS-wrap2 .MMS-Item {
    margin-bottom: 25px;
}

.MMS-wrap2 .MMS-Item.last {
    margin-bottom: 0px;
}

.MMS-wrap2 .MMS-Item .MMS-Tit {
    font-size: 1.1rem;
    color: #EDE7DD;
    font-weight: 400;
    margin-bottom: 0.4rem;
}

.MMS-wrap2 .MMS-Item .MMS-Desc {
    font-size: 1.25rem;
    color: #EDE7DD;
    font-weight: 600;
    word-break: break-word;
    line-height: 1.3;
}

.MMS-wrap2 .MMS-Item .MMS-Desc .small {
    display: block;
    font-size: 0.95rem;
    font-weight: 400;
    margin-top: 0.25rem;
    word-break: keep-all;
}

.MMS-wrap2 .MMS-QR {
    width: 49%;
    max-width: 170px;
}

.MMS-wrap2 .QR-ImgBox {
    border: 3px solid #EDE7DD;
    padding: 3px;
    box-sizing: border-box;
}

.MMS-wrap2 .QR-Img {
    width: 100%;
    margin: auto;
    display: block;
    background-color: #EDE7DD;
    padding: 7px;
    box-sizing: border-box;
}

.MMS-wrap2 .QR-Number {
    font-size: 1.3rem;
    color: #EDE7DD;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.MMS-wrap2 .MMS-Items.info {
    width: 100%;
}

.MMS-wrap2 .MMS-Flexday {
    padding: 3.5rem 0 1.5rem;
    box-sizing: border-box;
    background: url(https://www.flexdaycdn.net/public/images/static/mms/gentz-bg2.png) no-repeat center/cover;
    margin-top: -2px;
}

.MMS-wrap2 .MMS-Flexday img {
    width: 100px;
    margin: auto;
    display: block;
}

.MMS-wrap2 a[href^=tel] {
    color: inherit;
    text-decoration: none;
}