:root {
  --ColorTypeA: #ED1566;
  --ColorTypeB: #231815;
  --ColorTypeC: #606060;
  --ColorTypeD: #C3C3C3;
  --ColorTypeE: #E8E8E8;

  --Light     : 100;
  --Regular   : 400;
  --Medium    : 500;
  --Semibold  : 600;
  --Bold      : 700;
  --Extrabold : 800;
}

a{
  text-decoration:none;
  color : #000;
}

.invalid-feedback{
  display: block;
}

.no-scroll {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

ul{
  padding-left:0px;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

label{
  /* white-space:nowrap; */
  margin-bottom : 0.1rem;
}

.CustomLabel{
  color : #7F8080;
  margin-bottom : 0.8rem;
}

.form-label{
  /* white-space:nowrap; */
  margin-bottom : 0.1rem;
}

.fixed-bottom{
  z-index:99;
}

/* @font-face {
  font-family: "Noto Sans KR";
  transform : rotate(0.04deg);
  src:  url('./fonts/NotoSansKR-Regular.otf'),
        url('./fonts/NotoSansKR-Bold.otf'),
        url('./fonts/NotoSansKR-Light.otf'),
        url('./fonts/NotoSansKR-Medium.otf'),
        url('./fonts/NotoSansKR-Black.otf'),
        url('./fonts/NotoSansKR-Thin.otf');
} */

/* @font-face {
  font-family: "Pretendard", sans-serif;
  transform : rotate(0.04deg); 
  src: url('./fonts/Pretendard-Regular.woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
} */

* {
  /* font-family: 'Noto Sans KR', sans-serif; */
  /* font-family: 'Pretendard-Regular'; */
  /* font-size: 12px; */
  /* font-size : small; */
  
}

ul {
  list-style:none;
  margin-bottom: 0px;
}

.UlStyle {
  margin-bottom: 0px;
  padding-left:0.5rem;
  list-style-type : disc;
}

.UlStyle li {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}

h4{
  font-weight: bold;
}

.form-label{
  color : #7F8080;
}

.form-control::placeholder {
  color : #B2B2B2;
  font-size: 8pt;
}

.form-check-label{
  color : #B1B1B1;
  font-size: 8pt;
  border-radius: 50%;
}

.form-control{
  border:none;
  border-bottom: 0.5px solid #B1B1B1;
  border-radius : 0
}
.form-check-input[type=checkbox]{
  border-radius : 50%;
}
.form-control:disabled, .form-control[readonly]{
  background-color : #ffffff;
}
.MainSpace{
  margin-top : 75px;
}

.form-select{
  border-color : #CA5361;
}

.form-check-input:checked{
  background-color : #CA5361;
  border-color : #CA5361;
}

.accordion-button:not(.collapsed){
  background-color : #EAA9B1;
  color: #ffffff;
}
.UserNavgate{
  background-color: #CA5361;
  color:#ffffff;
}
.SearchNavgate{
  background-color: #ffffff;
  color:#ffffff;
}

.SearchNavgate input {
  border: none;
}
/* .navbar-brand{
  color:#ffffff;
} */
.PointText{
  color : var(--ColorTypeA) !important;
}

.btn-default{
  color : #000000;
  --bs-btn-color: var(--ColorTypeA);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: var(--ColorTypeA);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--ColorTypeA);
  --bs-btn-hover-border-color: var(--ColorTypeA);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--ColorTypeA);
  --bs-btn-active-border-color: var(--ColorTypeA);
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--ColorTypeA);
  --bs-btn-disabled-border-color: var(--ColorTypeA);
}

.btn-radio{
  min-height:33px !important;
  max-height:35px !important;
}

.btn-primary{
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--ColorTypeA);
  --bs-btn-border-color: var(--ColorTypeA);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--ColorTypeA);
  --bs-btn-hover-border-color: var(--ColorTypeA);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--ColorTypeA);
  --bs-btn-active-border-color: var(--ColorTypeA);
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--ColorTypeA);
  --bs-btn-disabled-border-color: var(--ColorTypeA);
  /* font-weight: bold; */
  /* font-size : 12pt; */
  font-size : small;
}


/* .btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background-color: #E2A5AC !important;
  border-color: #E2A5AC;
} */

.form-control:focus {
  /* outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE; */

  border-bottom : 0.5px solid #CA5361;
}

.ColorArea{
  background-color: #F1F1F1;
  padding-top : 20px;
  padding-bottom : 20px;
  margin-top : 20px;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%);
}

.TableTitle{
  font-weight : bold;
  color : #000;
}

.FooterArea{
  background-color: #F1F1F1;
  padding-top : 20px;
  padding-bottom : 20px;
  margin-top : 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.HeaderArea{
  background-color: #F1F1F1;
  padding-top : 20px;
  padding-bottom : 20px;
  margin-bottom : 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.Footer{
  color : #7F8080;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.Box{
  border: 0.5px solid #B2B2B2;
  border-radius: 10px;
  padding-top:15px;
  padding-bottom: 15px;
  padding-left:15px;
  padding-right:15px;
}

.InputBox{
  background-color: #B2B2B2;
}

.Footer > tbody > tr> td{
  border-bottom-width:0px;
  padding-top : 0;
  padding-bottom : 0;
  font-size:8pt;
}

/* .row > .col {
  padding-top: 15px;
  padding-bottom : 15px;
} */

.TextRight{
  text-align: right;
}

.postCodeStyle{
  display: 'block';
  position: 'relative';
  top: '0%';
  width: '100%';
  height: '400px';
  padding: '7px';
}

.ContentCenter{
  padding-top:5px;
  padding-left:5px;
  padding-right:5px;
  padding-bottom:5px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f6f6f6;
  --bs-btn-hover-border-color: #f6f6f6;
  --bs-btn-focus-shadow-rgb: 130,138,145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f6f6f6;
  --bs-btn-active-border-color: #f6f6f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f6f6f6;
  --bs-btn-disabled-border-color: #f6f6f6;
  color:#000000;
  /* border:0.5px solid #b4b3b3;  */
}

.btn-secondary:hover{
  background-color:#CA5361;
  border-color:#CA5361;
  color:#ffffff;
}

.InputGroupTextCtm{
  width:50%;
  border-radius:0;
  border:0px;
}

.InputGroupTextCtm70{
  width:70%;
  border-radius:0;
  border:0px;
}
.InputGroupTextCtm30{
  width:30%;
  border-radius:0;
  border:0px;
}

.TextTitle{
  color:#000000;
  font-weight: bold;
}

.LabelTitle{
  color:#000000;
  font-weight: bold;
}

.LineBreak{
  width:100%;
  height:10px;
  background-color:#F2F2F2;
}

dd{
  font-size: 8pt;
  color:#989898;
}

.shadow-lg{
  margin-top:20px;
  margin-bottom:20px;
  padding-left:5px;
  padding-top:5px;
  padding-right:5px;
  padding-bottom:5px;
  box-shadow:0 1rem 3rem rgba(0,0,0,0.075)!important;
}

.tab-content{
  padding-top:20px;
  padding-right : 0px;
  padding-left : 0px;
}

.TextTable{
  font-size:13px;
}

.TextTable tbody tr{
  border-style:none;
}
.TextTable tbody tr td{
  border-bottom-width: 0px;
  min-width:90px;
}
.progress{
  height:6px;
}
.progress-bar{
  background: linear-gradient(to right, #F6921E, #F9D600 100%);
}
.CustomRadio{
  font-size: 16px !important;
  font-weight: bold;
}
.CustomRadio > .form-check-label{
  font-size: 16px !important;
  font-weight: bold;
  color:#000;
}
.BigPlaceholder {
  color : #000000 !important;
  font-size: 16pt;
}
.Gradient{
  color: rgb(255,196,0);
  color: linear-gradient(to right, #F6921E, #ffdd00, #F9D600 100%);
}
.UnGradient{
  color: #454B4B;
  color: linear-gradient(to right, #454B4B, #ffffff, #ffffff 100%);
}

.ReviewScore{
  font-size:40pt;
  text-align: center;
  vertical-align: middle;
}

.AlignCenter{
  text-align: center !important;
  vertical-align: middle !important;
  align-items:center !important;
}

.AlignRight{
  text-align: right !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.AlignLeft{
  text-align: left !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.AlignCenterCB{
  display:flex;
  align-items:center;
}

pre{
  white-space:pre-wrap;
  font-family: 'Noto Sans KR', sans-serif;
  /* font-size: 12px; */
  font-size : small;
}

.Logo{
  padding-left:5px;
  padding-top:5px;
  padding-right:5px;
  padding-bottom:5px;
}

.ReviewImg{
  margin-top:5px;
}

.list-group-horizontal{
    --bs-list-group-border-color: rgba(0, 0, 0, 0);
    --bs-list-group-border-width: 1px;
    padding-right:0px;
}
.list-group-item{
  padding-right:0px;
}
.DefaultCheckBox .form-check{
  padding-left : 1.7em;
  margin-bottom: 0;
}
.DefaultCheckBox input{
  /* font-size: 12pt; */
  font-size : small;
  color:#000000;
  margin-left: -1em !important;
}
.DefaultCheckBox label{
  /* font-size: 12pt; */
  font-size : small;
  color:#000000;
}

.Ranking ul{
  padding-left:0px;
}

.FlexMainColor{
  color:#CA5361;
}
.FlexSubColor{
  color:#B2B2B2;
}
.WBox{
  background-color: #ffffff;
}
.BlackLabel{
  color:#000000;
}

.DefaultShadow{
  box-shadow:0 0rem 0.5rem 0.5rem rgba(0,0,0,0.03)!important
}
.DefaultRaund{
  border-radius: 10px;
}

.input-group-text{
  background-color : #ffffff;
  border : none;
}

.Label404{
  color : #454B4B;
}

.TextPointerType2{
  color : #454B4B;
  font-size: 8px;
  background-color: #f1f1f1;
  padding : var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #000000;
  --bs-nav-link-hover-color: #000000;
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.badge{
  color : #454B4B;
}
.badgesel{
  color : #CA5361;
  font-size : 12px;
}

.ItemImgSize{
  max-width: 100px;
  max-height: 100px;
}
.UnderBarUl li{
  font-size: 16px;
  /* border-bottom : 1px solid #B2B2B2; */
}
.w-40{
  width:40%;
}

.w-20{
  width:20%;
}
.w-15{
  width:15%;
}

.carousel-item > img{
  max-height: 260px;
}

.carousel-item .carousel-caption{
  left : 10%;
  z-index: 999;
}

.DefailtItemList{
  width:80px;
  height:80px;
  background-color: #CA5361;
  border-radius: 5px;
  color: #ffffff;
  font-size:18pt;
  font-weight: border;
}

.LogoImg{
  /* width:100px; */
  margin-top: 15%;
  margin-bottom: 10%;
}
.form-label{
  color:#7f8080;
  margin-bottom : 0.8rem;
}

h6{
  font-size : 1.8rem;
}

h6 span {
  font-size : 1.8rem;
}

.btn-group-lg>.btn, .btn-lg{
  --bs-btn-padding-y: 16px;
  --bs-btn-padding-x: 1rem;
  /* --bs-btn-font-size: 1rem; */
  --bs-btn-border-radius: 0.5rem;
  font-size: small;
}

.nav-item > .nav-link{
  font-size : small;
  color: #7F8080;
}

.SubTitle{
  font-size : small;
  color: #7F8080;
}

.FooterAccordion .accordion-button{
  background-color: #f1f1f1;
}

.FooterAccordion .accordion-item .accordion-header .accordion-button:not(.collapsed){
  background-color: #F1F1F1 !important;
}

.FooterAccordion .accordion-button:not(.collapsed){
  color:#000;
}
.FooterAccordion .accordion-body {
  background-color: #f1f1f1;
  color:#000;
}

.Hide {
  display: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-top:2px solid #CA5361;
}

.thumbnail {
  position: relative;
  padding-top: 100%;  /* 1:1 ratio */
  overflow: hidden;
}

.thumbnail > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  height: 100%;
}

.SmTxt{
  font-size : x-small;
  color:#919191;
}

.underreview{
  font-size: calc(1rem + 4.5vw);
}

.underreview > .Label{
  font-size: calc(1rem + 1.5vw) !important;
}

/* 20221018 css 디자인 변경에 따른 작성 */

@font-face {
  font-family: 'PretendardRegular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.LoginLogo{
  margin-top: 10%;
  margin-bottom: 10%;
}

#TmpFont{
  font-family: 'PretendardRegular' !important;
}

.w-30{
  width: 30%!important;
}



.form-control-lg{
  /* min-height : calc(2em + 2rem); */
  min-height : 18px;
  font-size: 14px;
  padding : 16px 1rem;
}

.InputType1{
  border-radius: 3px;
  border : 1px solid var(--ColorTypeA);
}
.InputType1:focus{
  border : 0.5px solid #CA5361 !important;
}

.InputType1::placeholder{
  font-size:12px !important;
  vertical-align: middle !important;
  font-weight:100px !important;
}

.InputType2{
  border-radius: 3px;
  border : 1px solid var(--ColorTypeC);
}

.InputType2Icon{
  border-radius: 3px;
  border : 1px solid var(--ColorTypeC);
  background: url('https://www.flexdaycdn.net/public/images/icon/SearchIcon.png') no-repeat scroll 6px 6px;
  padding-left:40px;
}
.InputType2:focus{
  border : 0.5px solid #231815 !important;
}
.InputType2::placeholder{
  font-size:12px !important;
  vertical-align: middle !important;
  font-weight:100px !important;
}

.form-control::placeholder {
  color : var(--ColorTypeD);
  font-size: 9pt;
}

.ButtonType1{
  border-radius: 3px;
  border-color: var(--ColorTypeA);
  background-color: var(--ColorTypeA);
  font-weight: 400 !important;
  font-size: 14px;
}

.ButtonType2{
  border-radius: 3px;
  border-color: var(--ColorTypeB);
  background-color: var(--ColorTypeB);
  font-weight: 500;
  font-size: 14px;
}

.ButtonType4{
  border-radius: 3px;
  border-color: var(--ColorTypeB);
  background-color: var(--ColorTypeB);
  font-weight: 500;
  font-size: 10px;
}

.ButtonType5{
  border-radius: 3px;
  border-color: var(--ColorTypeB);
  background-color: var(--ColorTypeB);
  font-weight: 500;
  font-size: 14px;
}
.ButtonType6{
  border-radius: 3px;
  border-color: var(--ColorTypeD);
  background-color: var(--ColorTypeD);
  font-weight: 500;
  font-size: 14px;
}
.ButtonType7{
  border-radius: 3px;
  border-color: var(--ColorTypeD);
  background-color: #ffffff;
  color:#000;
  font-weight: 500;
  font-size: 14px;
}


.SpaceHr{
  padding-top:7px;
  color: var(--ColorTypeC);
}

.alert-info{
  border-radius: 40px;
  border : 0.5px solid var(--ColorTypeA);
  border-color: var(--ColorTypeE);
  font-weight: var(--Bold);
  font-size:12px;
  color:var(--ColorTypeA);
  background-color: #ffffff;
  text-align: center;
  box-shadow:0 1rem 3rem rgba(0,0,0,0.3)!important;
}

#TmpFont .nav-item .nav-link{
  font-size:14px !important;
  font-weight: var(--Regular);
  color:var(--ColorTypeC);
}

#TmpFont .AlignCenter span{
  font-size:12px !important;
}

.PageIcon{
  position: absolute;
  left : 5px;
  margin-left : 8.3%;
  width:25px;
  height:25px;
}

.PageTite{
  margin-left : 30px;
  font-size: 18px;
  font-weight: 600;
  color: var(--ColorTypeB);
}
.NoIconPageTite{
  font-size: 18px;
  font-weight: 600;
  color: var(--ColorTypeB);
}
.TextContent{
  font-size: 12px;
  font-weight: 600;
  color: var(--ColorTypeB);
}
.TextContent2{
  font-size: 10px;
  font-weight: 400;
  color: var(--ColorTypeB);
}

.PageSubTitle{
  margin-left : 30px;
  font-size: 10px;
  font-weight: 400;
  color: var(--ColorTypeB);
}
.NoIconPageSubTitle{
  font-size: 14px;
  font-weight: 400;
  color: var(--ColorTypeB);
}

.PGB{
  border-radius: 0px;
}

.PGB .progress-bar{
  background : var(--ColorTypeA);
  border-radius: 0px;
}

.FooterBtnArea{
  position : absolute;
  clear:left;
  float:left;
  left:0;
  bottom:70px;
}

.DescriptionA{
  color:var(--ColorTypeA);
  font-size: 10px;
}


.btn-info{
  color : var(--ColorTypeB);
  background-color: #ffffff;
  font-size:12px;
  --bs-btn-color: var(--ColorTypeA);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: var(--ColorTypeE);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--ColorTypeA);
  --bs-btn-hover-border-color: var(--ColorTypeE);
  /* --bs-btn-focus-shadow-rgb: 49,132,253; */
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--ColorTypeA);
  --bs-btn-active-border-color: var(--ColorTypeA);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-btn-border-radius:30px;
}

.btn-info:checked{
  color : #ffffff;
  background-color : var(--ColorTypeA);
  border-color : var(--ColorTypeA);
}

.btn-info:hover{
  color : #ffffff;
  background-color:var(--ColorTypeA);
}



.MmsArea{
  width:512px;
  height: 698px;
}

.MmsArea img{
  width:512px;
  height: 698px;
}

.MmsArea .Content{
  position : absolute;
}

.dragitem {
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
  color: white;
}

.react-datepicker-wrapper input{
  width:100%;
}

/* 톤앤 매너 패치 20221104 */

ol,
ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}


.Inner {
  width: 90%;
  margin: auto;
  max-width: 320px;
}

.MockupTitle {
  box-sizing: border-box;
  border-bottom: 2px solid #666;
  padding-bottom: 0.5em;
}

.MockupList>li {
  border-bottom: 1px solid #ddd;
}

.MockupList>li>a {
  display: block;
  position: relative;
  padding: 1em 0px;
  padding-left: 100px;
  box-sizing: border-box;
}

.MockupDate {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: var(--Bold);
}

.MockupState {
  background: var(--ColorTypeA);
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 10px;
}

.MockupState.on {
  background: var(--ColorTypeB);
}


/*Font Pretendard */

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: 'Pretendard', sans-serif;
  margin: 0;
  padding: 0;
  outline: none;
}

:root {
  --ColorTypeA: #ED1556;
  --ColorTypeB: #231815;
  --ColorTypeC: #606060;
  --ColorTypeD: #C3C3C3;
  --ColorTypeE: #E8E8E8;

  --Light: 100;
  --Regular: 400;
  --Medium: 500;
  --Semibold: 600;
  --Bold: 700;
  --Extrabold: 800;

  --ColorNavIcon: #A1A0A1;
}

.pt-4-5 {
  padding-top: 2rem !important;
}

.pr-4-5 {
  padding-right: 2rem !important;
}

.pb-4-5 {
  padding-bottom: 2rem !important;
}

.pl-4-5 {
  padding-left: 2rem !important;
}

.px-4-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.py-4-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.form-control.InputType {
  width: 100%;
  height: 40px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 12px;
  color: var(--ColorTypeB);
}

.form-control.InputType::placeholder {
  color: var(--ColorTypeD);
}

.form-control.InputType:focus {
  border-color: var(--ColorTypeB);
  box-shadow: none;
}

.form-control.InputType.active {
  border-color: var(--ColorTypeA);
  box-shadow: none;
}

.form-control.InputType.active+.form-text.info-txt {
  display: block;
}

.AlertBox {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  border: 1px solid var(--ColorTypeE);
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 10px 1px rgb(0 0 0 / 20%);
}

.btn-default {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeB);
  color: #fff;
}

.btn-default:hover,
.btn-default.btn:first-child:active,
:not(.btn-check)+.btn-default:active {
  color: #fff;
  background: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

.btn-primary {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: var(--ColorTypeA);
  font-weight: var(--Medium);
  font-size: 14px;
  border-color: var(--ColorTypeA);
}

.btn-primary:hover,
.btn-primary.btn:first-child:active,
:not(.btn-check)+.btn-primary:active {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

.btn-secondary {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #000000;
  font-weight: var(--Medium);
  color: #000000;
  font-size: 14px;
}

.btn-secondary:hover,
.btn-secondary.btn:first-child:active,
:not(.btn-check)+.btn-secondary:active {
  color: #fff;
  background-color: var(--ColorTypeD);
  border-color: var(--ColorTypeD);
}

.btn-light {
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeC);
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 14px;
}

.btn-light:hover,
.btn-light.btn:first-child:active,
:not(.btn-check)+.btn-light:active {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeC);
}

.type10.btn-light:hover,
.type10.btn-light.btn:first-child:active,
:not(.btn-check)+.btn-light:active {
  border-color: var(--ColorTypeD);
}

.btn-check+.btn-light.type10.btn:hover {
  color: var(--ColorTypeC);
  background-color: #fff;
  border-color: var(--ColorTypeD);
}

.btn-check:checked+.type10.btn-light.btn,
.btn-check+.btn-light.type10.btn:hover {
  color: #fff;
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
}

a.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-clear {
  border: 0px;
  background: none;
  padding: 0px;
}

.btn-add-close {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 20%);
  font-size: 0px;
}

.btn.type5 {
  max-width: 110px;
  font-weight: var(--Regular);
}

.btn.type6 {
  max-width: 155px;
  height: 50px;
}

.btn.type7 {
  max-width: 155px;
  height: 50px;
}

.btn.type8 {
  max-width: 155px;
  height: 50px;
  font-weight: var(--Regular);
}

.btn.type10 {
  height: 30px;
  border-radius: 30px;
  border-color: var(--ColorTypeD);
  font-size: 13px;
  line-height: 30px;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0;
}

.btn.type13 {
  width: auto;
  height: auto;
  font-size: 12px;
}

.btn.type14 {
  width: auto;
  height: auto;
  font-size: 12px;
}

.btn.type15 {
  border-radius: 30px;
}

.btn.type16 {
  border-radius: 30px;
}

.form-text.info-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 12px;
  display: none;
}

.container{
  /* padding-left:0px;
  padding-right:0px; */
}
.ReviewReport{
  font-size: 13px;
}

.container-fluid.menu {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 70px;
}

/* navbar top */
.navbar {
  background: #fff;
  /* border-bottom: 1px solid var(--ColorTypeD); */
  /* padding: 1rem 0.75rem; */
}

.navbar-brand {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.navbar-brand.hide {
  opacity: 0;
  pointer-events: none;
}

/* .navbar-toggler {
  padding: 0;
  font-size: 0;
  line-height: 1;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  transition: var(--bs-navbar-toggler-transition);
  width: 28px;
} */

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas {
  max-width: 85%;
}

.offcanvas.offcanvas-end {
  border-left: none;
}

.btn-close {
  background-size: 27px auto;
}

.offcanvas-header .btn-close {
  margin-right: 3px
}

/* modal */

.modal-content {
  border: 1px solid var(--ColorTypeD);
}

.modal-header {
  padding: 1.5rem 1.5rem 0;
  border-bottom: 0px;
}

.modal-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
  width: 100%;
}

.modal-body {
  padding: 0.5rem 1.5rem;
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 12px;
}

.modal-footer {
  border-top: 0px;
  padding: 1rem 1.5rem;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.3;
}

.btn-modal-secondary,
.btn-modal-primary {
  width: 50%;
  margin: 0.375rem 0;
}

.btn-modal-secondary {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 16px;
}

.btn-modal-secondary:hover,
.btn-modal-secondary.btn:first-child:active,
:not(.btn-check)+.btn-modal-secondary:active {
  color: var(--ColorTypeB);
}

.btn-modal-primary {
  font-weight: var(--Medium);
  color: var(--ColorTypeA);
  font-size: 16px;
}

.btn-modal-primary:hover,
.btn-modal-primary.btn:first-child:active,
:not(.btn-check)+.btn-modal-primary:active {
  color: var(--ColorTypeA);
}

/* navber bottom  */

.b-navbar {
  background: #fff;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  box-shadow: 0px -3px 10px 1px rgb(0 0 0 / 20%);
}

.b-navbar ul {
  padding: 0.75rem 1.5rem;
}

.b-navbar ul li {
  position: relative;
  width: 14%;
  max-width: 45px;
}

.b-navbar ul li a {
  display: block;
}

.b-navbar ul li a img:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
}

.b-navbar ul li.active a img:nth-child(1) {
  opacity: 0;
}

.b-navbar ul li.active a img:nth-child(2) {
  display: block;
}


/* 서프페이지 navbar */

.sub-navbar {
  padding: 1rem 0.75rem;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--ColorTypeD);
}

.sub-navbar .btn-close {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: auto;
  padding: 0px;
  margin: 0px;
  opacity: 1;
  border-radius: 0;
}

.sub-navbar .btn-close.ver2 {
  width: 25px;
}

.navbar-title {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.btn-back {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.addprd-inputbox.d-flex {
  gap: 0.5rem;
}

.btn.btn-refresh {
  width: 30px;
}

/* 사진선택 폼 */

.PhotoList .row {
  width: 100%;
  margin: 0;
  gap: 1.2rem;
}

.PhotoList .col-4 {
  width: calc((100% - 2.6rem)/3);
  padding: 0px;
  position: relative;
}

.PhotoList .col-4 .badge-delete {
  width: 16px;
  height: 16px;
  font-size: 0px;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}

.PhotoList .col-4 .PhotoImgbox {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .PhotoImgbox img {
  width: 100%;
  height: 100%;
}

.PhotoList .col-4 .badge-number {
  width: 19px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
}

.PhotoList-txt {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 14px;
}

.File-Box {
  background: #f6f6f6;
  border: 1px dashed var(--ColorTypeD);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File-Box img {
  max-width: 25px;
  margin: 0px auto;
}

.File-Box p {
  font-weight: var(--Medium);
  color: var(--ColorTyepB);
  font-size: 12px;
  margin-bottom: 0;
}

.File-Box.active {
  background: var(--ColorTypeC);
  border: 1px solid var(--ColorTypeC);
}

.File-Box img:nth-child(2) {
  display: none;
}

.File-Box.active img:nth-child(1) {
  display: none;
}

.File-Box.active img:nth-child(2) {
  display: block;
}

.File-Box.active p {
  color: #fff;
}

.form-check-input.CheckInput2.small {
  background-size: 12px auto;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius : 0px;
}

.form-check-input.CheckInput2.small:checked[type=checkbox] {
  border: 1px solid #ddd;
}

.form-check-input.CheckInput2:checked[type=checkbox] {
    background-image: url(https://www.flexdaycdn.net/public/images/static/login/check_color.png);
    background-color: #fff;
    border-color: transparent;
}

.form-check-input.CheckInput2 {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/check.png);
  background-size: 16px auto;
  border: 0px;
  margin-top: 0;
  margin-left: 0px;
  margin-right: 0.25rem;
  float: none;
  width: 28px;
  height: 28px;
  flex: none;
  box-shadow: none;
}

.CheckLabel {
  font-weight: var(--Regular);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.ModalShow{
  width:80%;
  margin:auto;
}

.PageInfoTxt1 {
  color: #B1B1B1;
  font-size: 8pt;
  background-color: #F7F7F7;
}

.PageInfoTxt2 {
  color: #B1B1B1;
  font-size: 0.8rem;
  background-color: #F7F7F7;
}

/* 2022.12.08 반응형 작업*/

body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.fixed-bottom {
  z-index: 99;
}

.responsiveTablet {
  max-width: 800px;
  margin: auto;
}

.responsiveTablet::before {
  content: "";
  width: 100%;
  max-width: 800px;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
  z-index: -1;
}

.responsiveInner {
  max-width: 500px;
}

.b-navbar {
  max-width: 800px;
  margin: auto;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.b-paybox {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.b-navbar.shadow-none {
  border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


@media screen and (max-width: 800px) {

  .responsiveTablet::before {
    display: none;
  }

}

@media screen and (max-width: 599px) {

  .responsiveInner {
    max-width: 320px;
  }

}