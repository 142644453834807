body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.MS .MSHeader {
    background-color: #fff;
    border-bottom: 1px solid transparent;
}

.MS .MSHeader .inner {
    width: 85%;
    /* max-width: 680px; */
    max-width: 1080px;
    margin: auto;
    padding: 3rem 0;
}

.MS .MSHeader.wide {
    border-color: var(--ColorTypeD);
}

.MS .MSHeader.wide .inner {
    max-width: 1080px;
}

.MS .MSHeader .flex-logo {
    width: 90%;
    max-width: 150px;
    margin-right: 3rem;
    display: inline-block;
    vertical-align: middle;
}

.MS .MSHeader .HeaderNav {
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
}

.MS .MSHeader .HeaderNav .nav-item {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 30px;
}

.MS .MSHeader .HeaderNav .nav-item:last-child {
    margin-right: 0;
}

.MS .MSHeader .HeaderNav .nav-link {
    font-weight: var(--Medium);
    font-size: 17px;
    color: var(--ColorTypeB);
    transition: none;
    padding: 0
}

.MS .MSHeader .HeaderNav .nav-link.active {
    color: var(--ColorTypeA);
}

.MS .MSHeader .HeaderNav .nav-link.active::before {
    content: "";
    position: absolute;
    right: -5px;
    top: -5px;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
}

.MS .MSHeader .com-logo {
    max-width: 65px;
}

.MS .Contents-inner {
    width: 85%;
    max-width: 1080px;
    margin: auto;
    padding-top: 9rem;
}

.MS .MStitbox>.tit {
    font-weight: var(--Bold);
    font-size: 28px;
    color: var(--ColorTypeB);
    word-break: break-word;
    line-height: 1.3;
}

.MS .MStitbox>.desc {
    font-weight: var(--Regular);
    font-size: 18px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.MS .btn-logout {
    font-weight: var(--Semibold);
    font-size: 14px;
    color: var(--ColorTypeB);
    text-decoration: underline;
    text-underline-position: under;
}

.MS .MSDownload {
    font-weight: var(--Semibold);
    font-size: 14px;
    color: var(--ColorTypeB);
}

.MS .MSDownload:hover {
    color: inherit;
}

.MS .MSDownload span {
    text-decoration: underline;
    text-underline-position: under;
}

.MS .MSDownload img.icon-down {
    width: 13px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.MS .MSModal .modal-dialog.modal-sm {
    --bs-modal-width: 450px;
    margin: auto;
}

.MS .MSModal.ver2 .modal-dialog.modal-sm {
    --bs-modal-width: 380px;
}

.MS .MSModal .modal-content {
    border-radius: 15px;
}

.MS .MSModal .modal-header {
    padding-top: 2rem;
}

.MS .MSModal .modal-title {
    font-weight: var(--Bold);
    font-size: 22px;
}

.MS .MSModal .modal-body {
    padding: 0 1.5rem 2rem;
    font-weight: var(--Regular);
    font-size: 18px;
}

.MS .MSModal .modal-footer {
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
}

.MS .MSModal .modal-footer .btn {
    font-size: 18px;
}

.MS .MSModal2 .modal-dialog.modal-sm {
    --bs-modal-width: 450px;
    margin: auto;
}

.MS .MSModal2 .modal-title {
    font-weight: var(--Semibold);
    font-size: 34px;
    letter-spacing: -1.45px;
    color: #33CCCC;
}

.MS .MSModal2 .modal-body {
    padding: 0.5rem 1.5rem 1.5rem;
    font-weight: var(--Regular);
    font-size: 16px;
}

.MS .MSModal2 .modal-footer {
    padding: 0 2.5rem 2rem;
}

.MS .MSModal2 .modal-footer .btn{
    border-radius: 30px;
    font-size: 18px;
}