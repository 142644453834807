
.MS-Login {
    min-height: 100vh;
}

.MS-Login .MSLoginBox {
    max-width: 400px;
}

.MS-Login .MSLoginBox .imgbox {
    margin-bottom: 6rem
}

.MS-Login .MSLoginBox .imgbox img.flex-logo {
    max-width: 320px;
}

.MS-Login .MSLoginBox .imgbox img.moneyshow-logo {
    max-width: 165px;
}

.MS-Login .MSLoginBox .form-control.InputType {
    font-size: 15px;
}

.MS-Login .MSLoginBox .btn-default {
    font-size: 18px;
}