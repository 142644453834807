/*Font NanumSquareNeo */

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-aLt.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-bRg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-cBd.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-dEb.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-eHv.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    transform: rotate(0.04deg);
}



body {
    margin: 0;
    font-family: 'NanumSquareNeo', sans-serif;
}

.MMS-wrap2 p {
    margin: 0;
}

.MMS-wrap2 {
    width: 100%;
    max-width: 512px;
    /* height: 1175px; */
    margin: auto;
    padding: 270px 5px 5px;
    box-sizing: border-box;
    background: url(https://www.flexdaycdn.net/public/images/ticket/inje_mms_bg2.png) no-repeat top center / 100% auto #faf1d4;
}

.MMS-wrap2 .MMS-Contents {
    padding: 60px 20px 35px;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    border: 3px solid #f7f1d7;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox {
    text-align: center;
    margin-bottom: 55px;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox.last {
    margin-bottom: 10px;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt1 span {
    background-color: #3d5afe;
    color: #fff;
    display: inline-block;
    padding: 8px 22px;
    border-radius: 30px;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt2 {
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    max-height: 70px;
    line-height: 35px;
    -webkit-line-clamp: 2;
    white-space: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-word;
    overflow: hidden;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt3 {
    font-size: 22px;
    font-weight: 400;
    color: #000000;
    max-height: 60px;
    line-height: 30px;
    -webkit-line-clamp: 2;
    white-space: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-word;
    overflow: hidden;
}

.MMS-wrap2 .MMS-Contents .MMS-Txtbox .prd-img {
    max-width: 110px;
    margin-top: 35px;
}

.MMS-wrap2 .MMS-Contents .MMS-QR {
    color: #000;
    margin: 0 auto 70px;
    max-width: 100%;
    width: 100%;
}

.MMS-wrap2 .MMS-Contents .MMS-QR .QR-Img {
    display: block;
    margin: 0 auto -10px;
    width: 285px;
    background-color: transparent;
    padding: 0 0;
}

.MMS-wrap2 .MMS-Contents .MMS-QR .QR-Number {
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    text-decoration: inherit;
    color: inherit;
}

.MMS-wrap2 .MMS-Contents .flex-logo {
    width: 186px;
    margin: auto;
    display: block;
}


@media screen and (max-width: 440px) {
    
}

@media screen and (max-width: 400px) {
    
}

@media screen and (max-width: 380px) {
    
}

@media screen and (max-width: 350px) {
    
}

/* 추가수정 */

.MMS-wrap2 {
    max-width: 800px;
    padding: 320px 5px 5px;
}

@media screen and (max-width: 599px) {

    .MMS-wrap2 {
        padding: 30vh 3px 3px;
    }

    .MMS-wrap2 .MMS-Contents {
        padding: 45px 15px 25px;
    }
    
    .MMS-wrap2 .MMS-Contents .MMS-Txtbox {
        margin-bottom: 40px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt1 {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt1 span {
        padding: 6px 16px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt2 {
        font-size: 22px;
        max-height: 56px;
        line-height: 28px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt3 {
        font-size: 18px;
        max-height: 50px;
        line-height: 25px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .prd-img {
        max-width: 85px;
        margin-top: 25px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-QR {
        margin: 0px auto 50px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-QR .QR-Img {
        width: 235px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-QR .QR-Number {
        font-size: 20px;
    }

    .MMS-wrap2 .MMS-Contents .flex-logo {
        width: 150px;
    }

}


@media screen and (max-width: 320px) {

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt1 {
        font-size: 12px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt2 {
        font-size: 18px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-Txtbox .sub-txt3 {
        font-size: 15px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-QR .QR-Img {
        width: 200px;
    }

    .MMS-wrap2 .MMS-Contents .MMS-QR .QR-Number {
        font-size: 18px;
    }

    .MMS-wrap2 .MMS-Contents .flex-logo {
        width: 135px;
    }

}