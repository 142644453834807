.TPayQRPage .QRBox {
    background-color: var(--ColorTypeE);
    position: relative;
}

.TPayQRPage::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    z-index: -1;
    min-height: 100vh;
}

.TPayQRPage .QRBox::before {
    content: "";
    width: 100%;
    height: 5px;
    background-color: rgba(0 0 0 / 10%);
    position: absolute;
    left: 0;
    top: 0;
}

.TPayQRPage .QRBox-inner {
    background-color: #fff;
}

.TPayQRPage .QRBox .imgbox img.logo {
    display: block;
height: 30px;
}

.TPayQRPage .QRBox .imgbox img.qr-img {
    display: block;
    max-width: 185px;
}

.TPayQRPage .QRBox .infobox .infoitem {
    border-bottom: 2px dotted var(--ColorTypeD);
}

.TPayQRPage .QRBox .infobox .infoitem .tit {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeC);
}

.TPayQRPage .QRBox .infobox .infoitem .desc {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: keep-all;
}

.TPayQRPage .QRBox .QRInfotxt {
    font-weight: var(--Regular);
    font-size: 17px;
    color: var(--ColorTypeC);
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.TPayQRPage .QRBox .QRInfotxt .mob {
    display: none;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPayQRPage .QRBox .infobox .infoitem .tit {
        font-size: 17px;
    }

    .TPayQRPage .QRBox .infobox .infoitem .desc {
        font-size: 18px;
    }

    .TPayQRPage .QRBox .QRInfotxt {
        font-size: 14px;
    }

    .TPayQRPage .QRBox .QRInfotxt .mob {
        display: block;
    }

}

@media screen and (max-width: 320px) {


}