.PaymentPage .container .Inner {
  width: 100%;
  max-width: 100%;
}

.Pos .Inner {
  width: 90%;
  margin: auto;
  max-width: 320px;
}

/* 결제-결제내역 */
.Pos .PayPriceTotal {
  border-bottom: 1px solid var(--ColorTypeD);
}

.Pos .PayPriceTotal .row .tit {
  font-weight: var(--Medium);
  font-size: 16px;
  color: var(--ColorTypeB);
}

.Pos .PayPriceTotal .row .price {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.Pos .PayPriceTotal .row .price .price-total {
  font-weight: var(--Semibold);
  font-size: 17px;
  word-break: break-word;
}

.Pos .PayPriceAdd {
  background-color: #f7f7f7;
  border-bottom: 1px solid var(--ColorTypeD);
}

.Pos .PayPriceAdd .row .tit {
  font-weight: var(--Medium);
  font-size: 16px;
  color: var(--ColorTypeB);
}

.Pos .PayPriceAdd .row .price {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.Pos .PayPriceAdd .row .price .price-add {
  font-weight: var(--Semibold);
  font-size: 20px;
  color: var(--ColorTypeA);
  word-break: break-word;
}

.Pos .PaymentList > .row > .col-sm-12 {
  position: relative;
}

.Pos .PaymentList > .row > .col-sm-12::after {
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0.05) 5%, transparent 90%);
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.7;
}

.Pos .PaymentList-Item {
  background: #f7f7f7;
  border-radius: 3px;
}

.Pos .PaymentList-Item .prd-titbox .name {
  font-weight: var(--Medium);
  font-size: 16px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.Pos .PaymentList-Item .prd-titbox .use {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeB);
}

.Pos .PaymentList-Item .prd-infobox .price {
  font-weight: var(--Semibold);
  font-size: 20px;
  color: var(--ColorTypeB);
  word-break: break-word;
  width: calc(100% - 110px);
}

.Pos .PaymentList-Item .prd-infobox .date {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeB);
  width: 100px;
}

.Pos .Payment-Confirm.d-flex .btn-light {
  font-size: 18px;
}

.Pos .Payment-Confirm.d-flex .btn-default {
  font-size: 18px;
}

.Pos .qr-scan {
  width: 20px;
}

/* 결제 - 결제완료 */
.Pos .PaymentResult {
  background-color: #f7f7f7;
  border-radius: 3px;
}

.Pos .PaymentResult-Item .tit {
  font-weight: var(--Medium);
  font-size: 16px;
  color: var(--ColorTypeB);
}

.Pos .PaymentResult-Item .price {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.Pos .PaymentResult-Item.add-price .tit {
  font-weight: var(--Semibold);
  color: var(--ColorTypeA);
}

.Pos .PaymentResult-Item .price span {
  font-weight: var(--Semibold);
  font-size: 18px;
}

.Pos .PaymentResult-Item.add-price .price {
  color: var(--ColorTypeA);
}

.Pos .Payment-info-txt {
  font-weight: var(--Medium);
  font-size: 14px;
  color: var(--ColorTypeB);
}

.Pos .Payment-info-txt a {
  border-bottom: 1px solid var(--ColorTypeB);
}

.Pos .Payment-info-txt a:hover {
  color: var(--ColorTypeB);
}

/* 결제 - 교환권 확인 */

.Pos .GitfConfirm .imgbox .txtbox::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #000;
  opacity: 0.5;
}

.Pos .GitfConfirm .imgbox .center-txt {
  font-weight: var(--Semibold);
  font-size: 35px;
  color: var(--ColorTypeB);
  line-height: 1.2;
  background-color: rgba(255, 255, 255, 0.6);
  width: 50%;
  height: 50%;
  border-radius: 50%;
}

.Pos .GitfConfirm .imgbox .alert-txt {
  font-weight: var(--Regular);
  font-size: 13px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  text-align: center;
  width: 100%;
}

.Pos .GitfConfirm .infobox .tit {
  font-weight: var(--Medium);
  font-size: 15px;
  color: var(--ColorTypeC);
}

.Pos .GitfConfirm .infobox .content {
  font-weight: var(--Medium);
  font-size: 15px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.Pos .Payment-info-txt2 {
  font-weight: var(--Medium);
  font-size: 13px;
  color: var(--ColorTypeC);
}

.Pos .Payment-Confirm .btn {
  width: 50%;
  font-size: 18px;
  height: 65px;
}

.Pos .Payment-Confirm .btn2 {
  width: 100%;
  font-size: 18px;
  height: 65px;
}

/* 모바일 반응형 */

@media screen and (max-width: 320px) {
  .PageTit {
    font-size: 22px;
  }

  .PageSib {
    font-size: 14px;
  }

  .Payment-Inputbox .price-txt {
    margin-top: 0.5rem !important;
  }

  .Pos .col-md-6.col-sm-12.mb-4-5.px-4-5 {
    padding: 0 1rem !important;
  }

  .Pos .Payment-Confirm.d-flex .btn-light {
    font-size: 15px;
  }

  .Pos .Payment-Confirm.d-flex .btn-default {
    font-size: 15px;
  }
}

/* 23.06.08 개선 */

.Pos2.PaymentPage .Pos2_Logout {
  position: absolute;
  top: 5%;
  right: 5%;
  width: auto;
  border-radius: 8px;
  height: 50px;
  padding: 0 2rem;
}

.Pos2.PaymentPage .Pos2_Logout:focus {
  border-color: var(--ColorTypeB);
  background-color: var(--ColorTypeB);
}

.Pos2 .PosInner {
  width: 90%;
  max-width: 800px;
  margin: auto;
}

.Pos2.PaymentPage .PageTit {
  font-size: 35px;
  font-weight: var(--Bold);
}

@media screen and (max-width: 768px) {
  .Pos2.PaymentPage .PageTit {
    font-size: 24px;
  }
}

.Pos2.PaymentPage .PageTit > img {
  margin-left: 1rem;
  max-width: 30px;
  margin-bottom: 0.5rem;
}

.Pos2.PaymentPage .PageSib {
  font-size: 20px;
}

.Pos2.PaymentPage .PageSib2 {
  font-weight: var(--Medium);
  color: var(--ColorTypeC);
  font-size: 28px;
}

.Pos2.PaymentPage .Payment-Contents .Payment-Inputbox .form-control {
  height: 65px;
  border-radius: 8px;
  font-size: 20px;
  padding: 0 2rem;
}

@media screen and (max-width: 768px) {
  .Pos2.PaymentPage .Payment-Contents .Payment-Inputbox .form-control {
    height: 48px;
    font-size: 16px;
  }
}

.Pos2.PaymentPage
  .Payment-Contents
  .Payment-Inputbox
  .form-control::placeholder {
  font-size: inherit;
}

.Pos2.PaymentPage .form-control.InputType ~ .price-txt {
  font-size: 20px;
}

.Pos2.PaymentPage .keypad_box {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 3rem;
}

.Pos2.PaymentPage .keypad_box .btn-secondary {
  background-color: #f3f4f6;
  border: 1px solid #6f7688;
  border-radius: 8px;
  color: var(--ColorTypeB);
  font-size: 27px;
  height: 75px;
}

.Pos2.PaymentPage .keypad_box .btn-secondary:active {
  background-color: #f3f4f6;
  color: var(--ColorTypeB);
  border-color: #6f7688;
}

.Pos2.PaymentPage .btnSubmit {
  height: 70px;
  border-radius: 8px;
  font-size: 22px;
}

.Pos2.PaymentPage .btnSubmit:focus {
  background-color: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

.Pos2.PaymentPage .Payment-info-txt {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
}

.Pos2.PaymentPage .Pos2_Prev {
  width: auto;
  padding: 0 3rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  border-width: 2px;
}

.Pos2.Pos .PayPriceTotal .row .tit {
  font-size: 27px;
}

.Pos2.Pos .PayPriceTotal .row .price {
  font-size: 27px;
}

.Pos2.Pos .PayPriceTotal .row .price .price-total {
  font-size: 30px;
}

.Pos2.Pos .PayPriceAdd .row .tit {
  font-size: 27px;
}

.Pos2.Pos .PayPriceAdd .row .price .price-add {
  font-size: 30px;
}

.Pos2.Pos .PayPriceAdd .row .price {
  font-size: 27px;
}

.Pos2.Pos .PaymentList {
  flex: 1;
  padding: 1.5rem 0;
  overflow: auto;
}

.Pos2.Pos .PaymentList::-webkit-scrollbar {
  width: 1rem;
}

.Pos2.Pos .PaymentList::-webkit-scrollbar-thumb {
  height: 30%;
  background: var(--ColorTypeC);
  border-radius: 10px;
}

.Pos2.Pos .PaymentList::-webkit-scrollbar-track {
  background: var(--ColorTypeE);
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 10px;
}

.Pos2.Pos .Payment-Confirm.d-flex {
  margin-bottom: 2rem;
  gap: 0 2rem;
}

.Pos2.Pos .Payment-Confirm.d-flex .btn-light:hover {
  color: var(--ColorTypeB);
}
.Pos2.Pos .Payment-Confirm.d-flex .btn-light,
.Pos2.Pos .Payment-Confirm.d-flex .btn-default {
  font-size: 25px;
  height: 70px;
  border-radius: 8px;
}

.Pos .Payment-Confirm.d-flex .btn-light:focus {
  border-color: var(--ColorTypeB);
  background-color: #fff;
}

.Pos .Payment-Confirm.d-flex .btn-default:focus {
  background-color: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

.Pos2.Pos .PaymentList-Item {
  padding: 2rem 4rem;
}

.Pos2.Pos .PaymentList-Item .prd-titbox .name {
  font-size: 27px;
}

.Pos2.Pos .PaymentList-Item .prd-titbox .use {
  font-size: 24px;
}

.Pos2.Pos .PaymentList-Item .prd-infobox .price {
  font-size: 34px;
  width: calc(100% - 185px);
}

.Pos2.Pos .PaymentList-Item .prd-infobox .date {
  font-size: 24px;
  width: 185px;
}

.Pos2.Pos .PaymentList-Item .del_btn {
  width: 35px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.Pos2_modal .modal-sm {
  --bs-modal-width: 500px;
}

.Pos2_modal .modal-content {
  padding: 2rem 0;
}

.Pos2_modal .modal-header {
  margin-bottom: 2rem;
}

.Pos2_modal .modal-title {
  font-size: 30px;
}

.Pos2_modal .btn-modal-secondary {
  font-size: 25px;
}

.Pos2_modal .btn-modal-primary {
  font-size: 25px;
}

.Pos2.Pos .PageTit2.done {
  font-size: 30px;
}

.Pos2.Pos .PaymentResult-Item .tit {
  font-size: 26px;
}

.Pos2.Pos.PaymentResult-Item .price {
  font-size: 23px;
}

.Pos2.Pos .PaymentResult-Item .price span {
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  .Pos2.PaymentPage .keypad_box {
    margin-top: 1.6rem;
    gap: 0.6rem 0;
  }
  .Pos2.PaymentPage .PageSib {
    font-size: 16px;
  }
  .Pos2.PaymentPage .PageSib2 {
    font-size: 20px;
  }
  .PosInner .posTopWrapper {
    margin-top: 1.5rem !important;
  }
  .Pos2.PaymentPage .Pos2_Prev {
    margin-bottom: 1.6rem;
  }
}

/* 포스 해상도 문제로 스크롤 필요 */
/* .showScrollbar {
  -ms-overflow-style: auto !important;
  scrollbar-width: inherit !important;
}

.showScrollbar::-webkit-scrollbar {
  display: block !important;
} */
