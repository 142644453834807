.MS-MobileTicket .navbar-title {
    line-height: 34px;
    font-size: 25px;
}

.MS-MobileTicket .MobileBg {
    background: url(https://www.flexdaycdn.net/public/images/moneyshow/mobile_ticket_bg.png) no-repeat center bottom/contain;
    width: 100%;
    max-width: 800px;
    height: 100%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
}

.MS-MobileTicket .Inner {
    max-width: 500px;
}

.MS-MobileTicket .MTBox-title {
    margin: 8rem 0;
}

.MS-MobileTicket .MTBox-title .tit {
    font-weight: var(--Semibold);
    font-size: 28px;
    word-break: keep-all;
}

.MS-MobileTicket .MTBox-title .sub {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 24px;
}

.MS-MobileTicket .MTBox-title .sub .color {
    color: var(--ColorTypeA);
}

.MS-MobileTicket .form-control.InputType {
    height: 55px;
    font-size: 17px;
}

.MS-MobileTicket .btn-default {
    height: 55px;
    font-size: 18px;
}

.MS-MobileTicket .modal-dialog.modal-sm {
    --bs-modal-width: 500px;
}

.MS-MobileTicket .modal-title {
    font-size: 25px;
}

.MS-MobileTicket .modal-body {
    font-size: 18px;
}

.MS-MobileTicket .btn-modal-secondary,
.MS-MobileTicket .btn-modal-primary {
    font-size: 20px;
}

.MS-MobileTicket .mob {
    display: none;
}



/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .MS-MobileTicket .navbar-title {
        font-size: 20px;
    }

    .MS-MobileTicket .Inner {
        max-width: 320px;
    }

    .MS-MobileTicket .MTBox-title {
        margin: 6rem 0;
    }

    .MS-MobileTicket .MTBox-title .tit {
        font-size: 20px;
    }

    .MS-MobileTicket .MTBox-title .sub {
        font-size: 16px;
    }

    .MS-MobileTicket .form-control.InputType {
        height: 50px;
        font-size: 13px;
    }

    .MS-MobileTicket .btn-default {
        height: 50px;
        font-size: 14px;
    }

    .MS-MobileTicket .modal-dialog.modal-sm {
        --bs-modal-width: 300px;
    }

    .MS-MobileTicket .modal-title {
        font-size: 18px;
    }

    .MS-MobileTicket .modal-body {
        font-size: 13px;
    }

    .MS-MobileTicket .btn-modal-secondary,
    .MS-MobileTicket .btn-modal-primary {
        font-size: 16px;
    }

    .MS-MobileTicket .modal-footer {
        padding: 0.5rem 1.5rem; 
    }

    .MS-MobileTicket .mob {
        display: block;
    }

}

@media screen and (max-width: 320px) {

    .MS-MobileTicket .MTBox-title {
        margin: 4rem 0;
    }

    .MS-MobileTicket .form-control.InputType {
        height: 40px;
    }

    .MS-MobileTicket .btn-default {
        height: 40px;
    }




}