.BackGroundOverLay{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 156px;
    margin-top: -110px;
    margin-left: -50px;
    filter: opacity(0.5) drop-shadow(rgb(255, 0, 255) 0px 0px 0px);
    animation-name: rotate;
    animation-duration: 0.7s;
    animation-delay: 2s;
}

.LoadingImg{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 156px;
    margin-top: -110px;
    margin-left: -50px;
    
    animation-duration: 1s;
    animation-name: logo;
    animation-iteration-count: infinite;
}

@keyframes logo {
    0%  {
        filter: opacity(0.2) drop-shadow(#00ff00 0px 0px 0px);
    }
    10%  {
        filter: opacity(0.2) drop-shadow(#0e0e0e 0px 0px 0px);
    }
    20%  {
        filter: opacity(0.3) drop-shadow(#ff0000 0px 0px 0px);
    }
    30%  {
        filter: opacity(0.4) drop-shadow(#0ff000 0px 0px 0px);
    }
    40%  {
        filter: opacity(0.5) drop-shadow(#00fff0 0px 0px 0px);
    }
    50%  {
        filter: opacity(0.6) drop-shadow(#000fff 0px 0px 0px);
    }
    60%  {
        filter: opacity(0.7) drop-shadow(#f00000 0px 0px 0px);
    }
    70%  {
        filter: opacity(0.8) drop-shadow(#0f0000 0px 0px 0px);
    }
    80%  {
        filter: opacity(0.9) drop-shadow(#00f000 0px 0px 0px);
    }
    90%  {
        filter: opacity(0.2) drop-shadow(#000f00 0px 0px 0px);
    }
    100% {
        filter: opacity(0.2) drop-shadow(#0000f0 0px 0px 0px);
    }
}