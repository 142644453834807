.demo .container-fluid.pt-70px {
    padding-top: 70px;
}

.demo .pt-4-5 {
    padding-top: 3rem !important;
}

.demo .pe-4-5 {
    padding-right: 3rem !important;
}

.demo .pb-4-5 {
    padding-bottom: 3rem !important;
}

.demo .ps-4-5 {
    padding-left: 3rem !important;
}

.demo .px-4-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.demo .py-4-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.demo .p-4-5 {
    padding: 3rem !important;
}

.demo .mt-4-5 {
    margin-top: 3rem !important;
}

.demo .me-4-5 {
    margin-right: 3rem !important;
}

.demo .mb-4-5 {
    margin-bottom: 3rem !important;
}

.demo .ms-4-5 {
    margin-left: 3rem !important;
}

.demo .mx-4-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.demo .my-4-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.demo .m-4-5 {
    margin: 3rem !important;
}


/* 메뉴 */
.demo .navbar {
    background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
    border-bottom: 0px;
    padding: 1.5rem 1rem;
}

.demo .navbar.bg-none {
    background: #fff;
    border-bottom: 1px solid var(--ColorTypeD);
}

.demo .Navbar-Toggler {
    padding: 0;
    font-size: 0;
    line-height: 1;
    color: inherit;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    transition: var(--bs-navbar-toggler-transition);
    width: 28px;
}

.demo .offcanvas .btn-close {
    box-sizing: content-box;
    width: 28px;
    height: 28px;
    background: no-repeat;
}

.demo .offcanvas .btn-close img.close {
    width: 25px;
}

.demo .offcanvas {
    --bs-offcanvas-width: 520px;
    max-width: 60%;
}

.demo .offcanvas-header {
    border-bottom: 2px solid var(--ColorTypeE);
}

.demo .offcanvas-header img.logo {
    width: 50px;
    margin-right: 15px;
}

.demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 65px);
    font-weight: var(--Medium);
    font-size: 24px;
    line-height: 1.2;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.demo .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
}

.demo .offcanvas-body .nav-link .imgbox {
    font-size: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.af {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
}

.demo .offcanvas-body .nav-link .imgbox img.img1 {
    width: 30px;
    margin-right: 18px;
    margin-left: 2px;
}

.demo .offcanvas-body .nav-link .imgbox img.img2 {
    width: 35px;
    margin-right: 15px;
}

.demo .offcanvas-body .nav-link .imgbox img.img3 {
    width: 30px;
    margin-right: 18px;
    margin-left: 2px;
}

.demo .offcanvas-body .nav-link span {
    width: calc(100% - 50px);
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
}

.demo .offcanvas-body .nav-link.active .imgbox img.be {
    opacity: 0;
}

.demo .offcanvas-body .nav-link.active .imgbox img.af {
    opacity: 1;
}

.demo .offcanvas-body .nav-link.active span {
    color: var(--ColorTypeA);
}

.demo .offcanvas-body .btn-default {
    font-size: 25px;
    height: 65px;
    border-radius: 50px;
}

.demo .sub-navbar {
    background: linear-gradient(to right, #FF195A, #FF5864, #FF655B);
    border-bottom: 0px;
    padding: 1.5rem 1rem;
}

.demo .navbar-title {
    line-height: 34px;
    color: #fff;
    font-size: 25px;
}

.demo .btn-back {
    width: 14px;
}

.demo .sub-navbar .btn-close {
    width: 25px;
}

.demo .form-control.InputType {
    height: 55px;
    font-size: 17px;
}

.demo .form-control.InputType~.price-txt {
    height: 55px;
    line-height: 55px;
    font-weight: var(--Regular);
    font-size: 17px;
    color: var(--ColorTypeB);
}

.demo .Input-placeholder {
    font-size: 17px;
}

.demo .modal-dialog.modal-sm {
    --bs-modal-width: 500px;
}

.demo .modal-title {
    font-size: 25px;
}

.demo .modal-title img.icon {
    display: block;
    width: 50px;
    margin: 0 auto 1rem;
}

.demo .modal-body {
    font-size: 18px;
}

.demo .btn-modal-secondary,
.demo .btn-modal-primary {
    font-size: 20px;
}

.demo .Inner {
    max-width: 500px;
}

.demo .btn-primary,
.demo .btn-default,
.demo .btn-light {
    height: 55px;
    font-size: 18px;
}

.demo .AlertBox.active {
    height: 60px;
}

.demo .AlertBox {
    font-size: 18px;
}


/* 데모 수정 */

.demo .btn.type11,
.demo .btn.type12,
.demo .btn.type13,
.demo .btn.type14 {
    height: auto;
    width: auto;
}




/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .demo .container-fluid.pt-70px {
        padding-top: 70px;
    }

    .demo .pt-4-5 {
        padding-top: 2rem !important;
    }

    .demo .pe-4-5 {
        padding-right: 2rem !important;
    }

    .demo .pb-4-5 {
        padding-bottom: 2rem !important;
    }

    .demo .ps-4-5 {
        padding-left: 2rem !important;
    }

    .demo .px-4-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }

    .demo .py-4-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .demo .p-4-5 {
        padding: 2rem !important;
    }

    .demo .mt-4-5 {
        margin-top: 2rem !important;
    }

    .demo .me-4-5 {
        margin-right: 2rem !important;
    }

    .demo .mb-4-5 {
        margin-bottom: 2rem !important;
    }

    .demo .ms-4-5 {
        margin-left: 2rem !important;
    }

    .demo .mx-4-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .demo .my-4-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .demo .m-4-5 {
        margin: 2rem !important;
    }

    .demo .navbar {
        padding: 1rem 0.75rem;
    }

    .demo .offcanvas {
        max-width: 75%;
    }

    .demo .offcanvas-header img.logo {
        width: 35px;
        margin-right: 5px;
    }

    .demo .offcanvas-header .offcanvas-title {
        width: calc(100% - 40px);
        font-size: 18px;
    }

    .demo .offcanvas-body {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img1 {
        width: 20px;
        margin-right: 13px;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img2 {
        width: 25px;
        margin-right: 12px;
    }

    .demo .offcanvas-body .nav-link .imgbox img.img3 {
        width: 20px;
        margin-right: 13px;
    }

    .demo .offcanvas-body .nav-link span {
        font-size: 20px;
    }

    .demo .offcanvas-body .btn-default {
        font-size: 18px;
        height: 50px;
    }

    .demo .sub-navbar {
        padding: 1rem 0.75rem;
    }

    .demo .navbar-title {
        font-size: 20px;
    }

    .demo .btn-back {
        width: 12px;
    }

    .demo .sub-navbar .btn-close {
        width: 18px;
    }

    .demo .form-control.InputType {
        height: 40px;
        padding: 0.75rem;
        font-size: 13px;
    }

    .demo .form-control.InputType~.price-txt {
        font-size: 13px;
        height: 40px;
        line-height: 40px;
    }

    .demo .Input-placeholder {
        font-size: 13px;
    }

    .demo .modal-dialog.modal-sm {
        --bs-modal-width: 300px;
    }

    .demo .modal-title {
        font-size: 18px;
    }

    .demo .modal-title img.icon {
        width: 35px;
        margin: 0 auto 0.5rem;
    }


    .demo .modal-body {
        font-size: 13px;
    }

    .demo .btn-modal-secondary,
    .demo .btn-modal-primary {
        font-size: 16px;
    }

    .demo .Inner {
        max-width: 320px;
    }

    .demo .btn-primary,
    .demo .btn-default,
    .demo .btn-light {
        height: 50px;
        font-size: 14px;
    }

    .demo .AlertBox.active {
        height: 50px;
    }

    .demo .AlertBox {
        font-size: 14px;
    }

}



@media screen and (max-width: 320px) {

    .demo .offcanvas-body .btn-default {
        height: 40px;
    }

    .demo .btn-primary,
    .demo .btn-default,
    .demo .btn-light {
        height: 40px;
    }

    .demo .form-checkbox {
        height: 40px;
    }



}


/* 2022.12.09 반응형 */

body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.demo {
    max-width: 800px;
    margin: auto;
}

.demo .navbar {
    max-width: 800px;
    margin: auto;
}

.demo .fixed-bottom {
    z-index: 99;
}


/* 23-01-09 수정 */

:root {
    --ColorTypeB: #000000;
}

.demo .navbar {
    background: #fff;
    border: 1px solid var(--ColorTypeD);
    border-top: 0;
}

.demo .sub-navbar {
    background: #fff;
    border: 1px solid var(--ColorTypeD);
    border-top: 0;
}

.demo .navbar-title {
    color: var(--ColorTypeB);
}

.demo::before {
    content: "";
    width: 100%;
    max-width: 800px;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-left: 1px solid var(--ColorTypeD);
    border-right: 1px solid var(--ColorTypeD);
    z-index: -1;
}

.demo .Navbar-Toggler {
    width: 38px;
}

.demo .btn-back {
    width: 17px;
}

.demo .offcanvas {
    max-width: 65%;
    --bs-offcanvas-width: 445px;
}

.demo .offcanvas-header img.logo {
    width: 60px;
    margin-right: 15px;
}

.demo .offcanvas .btn-close img.close {
    width: 20px;
}

.demo .offcanvas-header .offcanvas-title {
    width: calc(100% - 75px);
    font-weight: var(--Semibold);
}

.demo .offcanvas-header .offcanvas-title .small {
    font-size: 20px;
    font-weight: var(--Regular);
    display: block;
    margin-bottom: 0.25rem;
}

.demo .offcanvas-body {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.demo .offcanvas-body .nav-item.last {
    padding-top: 1.25rem;
    margin-top: 1rem;
    border-top: 1px solid #666666;
}

.demo .offcanvas-body .nav-link .imgbox {
    display: none;
}

.demo .offcanvas-body .nav-link {
    padding-bottom: 1.25rem !important;
}

.demo .offcanvas-body .nav-link span {
    width: 100%;
    position: relative;
    font-size: 20px;
}

.demo .offcanvas-body .nav-link span::after {
    content: "";
    background: url(https://www.flexdaycdn.net/public/images/ticket/arrow-right.png) no-repeat center / 100% 100%;
    width: 10px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.demo .offcanvas-body .btn-default {
    font-size: 20px;
    height: 60px;
    border-radius: 5px;
    background-color: #000;
}

.demo .offcanvas-body .btn-light {
    font-size: 20px;
    height: 60px;
    border-radius: 5px;
    border-color: #000000;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.6;
}

.demo .btn-light:hover,
.demo .btn-light.btn:first-child:active,
.demo :not(.btn-check)+.btn-light:active {
    color: var(--ColorTypeB);
    background-color: #fff;
    border-color: var(--ColorTypeB);
}

.demo input::-webkit-outer-spin-button,
.demo input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 모바일 반응형 */
@media screen and (max-width: 1024px) {

    .demo::before {
        display: none;
    }

    .demo .navbar {
        border-left: 0;
        border-right: 0;
    }

    .demo .sub-navbar {
        border-left: 0;
        border-right: 0;
    }

}


@media screen and (max-width: 599px) {

    .demo .Navbar-Toggler {
        width: 28px;
    }

    .demo .btn-back {
        width: 12px;
    }

    .demo .offcanvas .btn-close img.close {
        width: 16px;
    }

    .demo .offcanvas {
        max-width: 85%;
    }

    .demo .offcanvas-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .demo .offcanvas-header .offcanvas-title {
        font-size: 24px;
    }

    .demo .offcanvas-header .offcanvas-title .small {
        font-size: 15px;
    }

    .demo .offcanvas-header img.logo {
        width: 50px;
        margin-right: 10px;
    }

    .demo .offcanvas-header .offcanvas-title {
        width: calc(100% - 60px);
        font-size: 20px;
    }

    .demo .offcanvas-body .nav-item.last {
        margin-top: 0.5rem;
    }

    .demo .offcanvas-body .nav-link {
        padding-top: 0;
    }

    .demo .offcanvas-body .nav-link span {
        font-size: 18px;
    }

    .demo .offcanvas-body .btn-default {
        font-size: 16px;
        height: 45px;
    }

    .demo .offcanvas-body .btn-light {
        font-size: 16px;
        height: 45px;
    }

}


@media screen and (max-width: 320px) {

    .demo .offcanvas-header {
        padding: 1rem 1.5rem !important;
    }

    .demo .offcanvas-header img.logo {
        width: 45px;
        margin-right: 10px;
    }

    .demo .offcanvas-body .nav-link {
        padding-bottom: 1rem !important;
    }

    .demo .offcanvas-body .nav-link span {
        font-size: 16px;
    }

    .demo .offcanvas-header .offcanvas-title {
        width: calc(100% - 55px);
        font-size: 17px;
    }

    .demo .offcanvas-body .btn-default {
        font-size: 14px;
        height: 40px;
    }

    .demo .offcanvas-body .btn-light {
        font-size: 14px;
        height: 40px;
    }


}



/* 23.01.09 추가 */

/*GmarketSan*/
@font-face {
    font-family: 'GmarketSansLight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'GmarketSansBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    transform: rotate(0.04deg);
}

.gMarket.light {
    font-family: 'GmarketSansLight', 'Pretendard', sans-serif;
}

.gMarket {
    font-family: 'GmarketSansMedium', 'Pretendard', sans-serif;
}

.gMarket.bold {
    font-family: 'GmarketSansBold', 'Pretendard', sans-serif;
}



.datepicker-controls .view-switch {
    order: 0;
    justify-content: flex-start;
}

.datepicker-header .datepicker-controls .button:hover {
    background-color: transparent;
}

.datepicker-controls .button.prev-btn {
    order: 1;
}

.datepicker-controls .button.next-btn {
    order: 2;
}





/* 23-02-03 Footer 추가 */

.demo .Footer {
    position: relative;
    background-color: #000000;
    padding: 3rem 0;
}

.demo .Footer .footer-infobox {
    width: 90%;
    margin: auto;
}

.demo .Footer .footer-infobox .footer-img {
    width: 20%;
}

.demo .Footer .infobox1 {
    padding-top: 2rem;
}

.demo .Footer .infobox1 .infobox1-item {
    display: flex;
}

.demo .Footer .infobox1 .tit {
    flex: 0 0 auto;
    width: 28%;
    font-size: 17px;
    font-weight: var(--Regular);
    color: #fff;
    margin-bottom: 0;
}

.demo .Footer .infobox1 .desc {
    flex: 0 0 auto;
    width: 72%;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0.25rem;
}

.demo .Footer .infobox2 {
    margin-top: 2rem;
}

.demo .Footer .infobox2 .call-tit {
    font-size: 24px;
    color: #fff;
    margin-bottom: 0.25rem;
}

.demo .Footer .infobox2 .call-num {
    font-size: 34px;
    color: #fff;
    font-weight: var(--Semibold);
    margin-bottom: 0;
    line-height: 1;
}

.demo .Footer .infobox2 .call-desc {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

.demo .Footer .infobox2 .call-mail {
    font-size: 25px;
    color: #fff;
    font-weight: var(--Medium);
}

.demo .Footer .infobox3 {
    margin-top: 1.5rem;
}

.demo .Footer .infobox3 .infobox3-nav {
    display: flex;
    margin-bottom: 0.5rem;
}

.demo .Footer .infobox3 .infobox3-nav>li {
    font-size: 16px;
    color: #fff;
    position: relative;
    margin-right: 1rem;
    padding-right: 1rem;
}

.demo .Footer .infobox3 .infobox3-nav>li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.demo .Footer .infobox3 .infobox3-nav>li::after {
    content: "";
    position: absolute;
    right: 0;
    top: 18%;
    width: 1px;
    height: 64%;
    background-color: #fff;
}

.demo .Footer .infobox3 .infobox3-nav>li:last-child::after {
    display: none;
}

.demo .Footer .infobox3 .infobox3-nav>li>span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
}

.demo .Footer .infobox3 .infobox3-txt {
    font-size: 16px;
    color: #fff;
    word-break: keep-all;
}


@media screen and (max-width: 599px) {

    .demo .Footer {
        padding: 2.5rem 0 1.5rem;
    }

    .demo .Footer .infobox1 {
        padding-top: 1rem;
    }

    .demo .Footer .infobox1 .tit {
        width: 35%;
        font-size: 14px;
    }

    .demo .Footer .infobox1 .desc {
        width: 65%;
        font-size: 14px;
    }

    .demo .Footer .infobox1 .desc br {
        display: none;
    }

    .demo .Footer .infobox2 .call-tit {
        font-size: 20px;
    }

    .demo .Footer .infobox2 .call-num {
        font-size: 28px;
    }

    .demo .Footer .infobox2 .call-desc {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .demo .Footer .infobox2 .call-mail {
        font-size: 20px;
    }

    .demo .Footer .infobox3 .infobox3-nav {
        flex-wrap: wrap;
    }

    .demo .Footer .infobox3 .infobox3-nav>li {
        font-size: 14px;
        margin-right: 0.5rem;
        padding-right: 0.5rem;
    }

    .demo .Footer .infobox3 .infobox3-txt {
        font-size: 13px;
    }

}





/* 23.02.03 */

.demo .offcanvas-header .offcanvas-title .imgbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border-radius: 0.25rem;
    margin-left: 0.25rem;
}

.demo .offcanvas-header .offcanvas-title .imgbox.kakao {
    background: #FEE500
}

.demo .offcanvas-header .offcanvas-title .imgbox.naver {
    background: #5fc33a
}

.demo .offcanvas-header .offcanvas-title .imgbox.apple {
    background: var(--ColorTypeB);
}

.demo .offcanvas-header .offcanvas-title .imgbox .sns-icon {
    max-width: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.demo .offcanvas-header .offcanvas-title .imgbox.non-member .sns-icon {
    max-width: 100%;
}


@media screen and (max-width: 599px) {

    .demo .offcanvas-header .offcanvas-title .imgbox {
        width: 20px;
        height: 20px;
        margin-left: 0.15rem;
    }

    .demo .offcanvas-header .offcanvas-title .imgbox .sns-icon {
        max-width: 8px;
    }

}



/* 23-02-13 */

.demo .PrdTbl-excelbox {
    margin-bottom: 1rem;
}

.demo .PrdTbl-excelbox .PrdTbl-down {
    display: flex;
    align-items: center;
    gap: 0 0.25rem;
    font-size: 18px;
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
}

.demo .PrdTbl-excelbox .PrdTbl-down span {
    text-decoration: underline;
    text-underline-position: under;
}

.demo .PrdTbl-excelbox .PrdTbl-down img {
    max-width: 26px;
}

.demo .PrdTbl-excelbox.tablet {
    display: none;
    font-size: 16px;
    color: var(--ColorTypeC);
    margin-bottom: 2.5rem;
}

.demo .PrdTbl-excelbox.tablet br {
    display: none;
}


@media screen and (max-width: 900px) {

    .demo .PrdTbl-excelbox.pc {
        display: none;
    }

    .demo .PrdTbl-excelbox.tablet {
        display: block;
    }
}



@media screen and (max-width: 599px) {

    .demo .PrdTbl-excelbox .PrdTbl-down {
        font-size: 16px;
        padding-right: 1.5rem;
    }

    .demo .PrdTbl-excelbox .PrdTbl-down img {
        max-width: 22px;
        padding-bottom: 0;
    }

    .demo .PrdTbl-excelbox.tablet {
        font-size: 14px;
        margin-bottom: 2rem;
    }

    .demo .PrdTbl-excelbox.tablet br {
        display: block;
    }

}



/* iOS 노치 */

.demo .container-fluid.pt-70px {
    padding-top: calc(constant(safe-area-inset-top) + 70px);
    padding-top: calc(env(safe-area-inset-top) + 70px);
}

.demo>div>.container-fluid>.navbar,
.demo>div>.container-fluid>.sub-navbar {
    padding-top: calc(constant(safe-area-inset-top) + 1.5rem);
    padding-top: calc(env(safe-area-inset-top) + 1.5rem);
}

.demo .offcanvas-btnbox {
    padding-top: calc(constant(safe-area-inset-top) + 1.5rem) !important;
    padding-top: calc(env(safe-area-inset-top) + 1.5rem) !important;
}

.demo .offcanvas-body {
    padding-bottom: calc(constant(safe-area-inset-bottom) + 2rem) !important;
    padding-bottom: calc(env(safe-area-inset-bottom) + 2rem) !important;
}

.demo .Footer {
    padding-bottom: calc(constant(safe-area-inset-bottom) + 3rem);
    padding-bottom: calc(env(safe-area-inset-bottom) + 3rem);
}

.demo-modal .modal-header {
    padding-top: calc(constant(safe-area-inset-top) + 1.5rem);
    padding-top: calc(env(safe-area-inset-top) + 1.5rem);
}

.demo-modal .modal-body {
    padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
    padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
}


.demo .btn-back,
.demo .Navbar-Toggler,
.demo .sub-navbar>.btn-close {
    top: calc(constant(safe-area-inset-bottom) + 50%) !important;
    top: calc(env(safe-area-inset-bottom) + 50%) !important;
}


@media screen and (max-width: 599px) {

    .demo>div>.container-fluid>.navbar,
    .demo>div>.container-fluid>.sub-navbar {
        padding-top: calc(constant(safe-area-inset-top) + 1rem);
        padding-top: calc(env(safe-area-inset-top) + 1rem);
    }

    .demo .Footer {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
        padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
    }


}

/* 23-03-23 */

.demo .sub-navbar {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
}



/* datepicker 임시  */

.demo~.datepicker.datepicker-dropdown {
    padding-top: 10px;
}

.demo~.datepicker .datepicker-dropdown .datepicker-picker {
    box-shadow: none;
}

.demo~.datepicker .datepicker-picker {
    border-radius: 3px;
    border: 1px solid var(--ColorTypeB);
    padding: 1.25rem 1rem 1rem;
    font-size: 14px;
}

.demo~.datepicker .datepicker-grid {
    width: 15.75rem;
}

.demo~.datepicker .datepicker-controls .view-switch {
    font-size: 16px;
    font-weight: var(--Medium);
    margin-left: 0.5em;
    padding: 0;
    height: auto;
}

.demo~.datepicker .datepicker-header .datepicker-controls {
    align-items: center;
    margin-bottom: 0.75rem;
}

.demo~.datepicker .datepicker-controls .next-btn,
.demo~.datepicker .datepicker-controls .prev-btn {
    width: 12px;
    height: 8px;
    padding: 0;
    margin: 0 8px;
    font-size: 0;
}

.demo~.datepicker .button.prev-btn {
    background: url(https://www.flexdaycdn.net/public/images/ticket/datepicker_prev.png) no-repeat center / 100% auto;
}

.demo~.datepicker .button.next-btn {
    background: url(https://www.flexdaycdn.net/public/images/ticket/datepicker_next.png) no-repeat center / 100% auto;
}

.demo~.datepicker .datepicker-view .dow {
    font-weight: var(--Medium);
    height: auto;
    line-height: 1.75rem;
}

.demo~.datepicker .datepicker-view .dow:first-child {
    color: var(--ColorTypeA);
}

.demo~.datepicker .datepicker-view .dow:last-child {
    color: #293eb7;
}

.demo~.datepicker .datepicker-cell {
    height: auto;
    line-height: 1.75rem;
    margin: 0.1rem 1.75%;
    flex-basis: 10.28571%;
}

.demo~.datepicker .datepicker-cell.today:not(.selected):not(.disabled) {
    color: var(--ColorTypeB);
    font-weight: var(--Bold);
}

.demo~.datepicker .datepicker-cell.today:not(.selected) {
    background-color: #fff;
    color: var(--ColorTypeB);
}

.demo~.datepicker .datepicker-cell.disabled {
    color: var(--ColorTypeB);
    opacity: 0.3;
}

.demo~.datepicker .datepicker-cell.prev,
.demo~.datepicker .datepicker-cell.next {
    opacity: 0;
}

.demo~.datepicker .datepicker-cell.range-start,
.demo~.datepicker .datepicker-cell.range-start:hover {
    font-weight: 400;
    background-color: var(--ColorTypeA) !important;
    border-radius: 50%;
    color: #fff !important;
}

.demo~.datepicker .datepicker-cell.range {
    background-color: #fff;
    border-radius: 50%;
    color: var(--ColorTypeA) !important;
    font-weight: 400 !important;
}

.demo~.datepicker .datepicker-cell.range-end,
.demo~.datepicker .datepicker-cell.range-end:hover {
    font-weight: 400;
    background-color: var(--ColorTypeA) !important;
    border-radius: 50%;
    color: #fff !important;
}



/* ver2 */

.demo .offcanvas-body .nav-link span::after {
    background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/arrow-right.png);
}

.demo .offcanvas-body .nav-link.active span::after {
    background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/arrow-right2.png);
}


/*Font NanumSquareNeo */

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-aLt.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-bRg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-cBd.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-dEb.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'NanumSquareNeo';
    src: url('./font/NanumSquareNeoTTF-eHv.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    transform: rotate(0.04deg);
}


/*Font SBAggro */

@font-face {
    font-family: 'SBAggro';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroL.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'SBAggro';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroM.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    transform: rotate(0.04deg);
}

@font-face {
    font-family: 'SBAggro';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    transform: rotate(0.04deg);
}

.demo .navbar-brand {
    padding: 0;
    height: 35px;
    display: flex;
    gap: 0 0.75rem;
    align-items: center;
    flex: 1;
    text-align: left;
    margin: 0;
    padding-right: 0.75rem;
    width: calc(100% - 34px);
}

.demo .navbar-brand>.logo {
    max-width: 35px;
}

.demo .navbar-brand>.brand-txt {
    font-size: 25px;
    font-weight: var(--Semibold);
    position: relative;
    max-width: calc(100% - 35px - 4rem);
    overflow: hidden;
    text-overflow: ellipsis;
}

.demo .navbar-brand::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/nav_arrow.png) no-repeat center / 100%;
    margin-left: 0.25rem;
}

.demo .Navbar-Toggler {
    width: 34px;
}

.demo .Footer .footer-infobox .ver2 .accordion-button::after {
    background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/footer_arrow.png);
}

.demo .Footer .footer-infobox .footer-img {
    width: 130px;
}

.demo .Footer .infobox1 .tit {
    font-size: 18px;
}

.demo .Footer .infobox1 .desc {
    font-size: 18px;
}

.demo .Footer .infobox2 .call-num {
    font-size: 30px;
    margin-bottom: 8px;
}

.demo .Footer .infobox2 .call-desc {
    margin-bottom: 0;
}

.demo .Footer .infobox2 .call-mail {
    font-size: 20px;
    font-weight: var(--Regular);
}

.demo .Footer .infobox3 .infobox3-nav>li {
    font-size: 18px;
    margin-right: 1.5rem;
    padding-right: 1.5rem;
}

.demo .modal.show .modal-dialog.modal-sm.modal-brand {
    transform: none;
}

.demo .modal-dialog.modal-sm.modal-brand {
    --bs-modal-width: 100%;
    width: 100%;
    transform: translateY(50px);
}

.demo .modal-brand {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
}

.demo .modal-brand .modal-content {
    width: 100%;
    max-width: 800px;
    margin: auto;
    border: 0;
    border-radius: 0;
    background: #fff;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    overflow: hidden;
    will-change: transform;
}

.demo .modal-brand .modal-content .arrowDown {
    position: sticky;
    top: 0;
    text-align: center;
    background: linear-gradient(to bottom, #fff, transparent);
    padding: 0.75rem 0;
}

.demo .modal-brand .modal-content .arrowDown img {
    width: 30px;
}

.demo .modal-brand .modal-body {
    padding: 0 0 3rem;
    margin-top: 2rem;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
}

.demo .modal-brand .selectInputbox>input {
    display: none;
}

.demo .modal-brand .selectInputbox>label {
    padding: 0 3rem;
    position: relative;
    font-size: 30px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
    display: flex;
    gap: 0 0.5rem;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
}

.demo .modal-brand .selectInputbox>label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 8px;
    height: 100%;
}

.demo .modal-brand .selectInputbox>label>.btnBasic {
    width: auto;
    height: auto;
    font-size: 22px;
    border-radius: 40px;
    padding: 0.25rem 1.5rem;
    white-space: nowrap;
}

.demo .modal-brand .selectInputbox>input:checked+label {
    font-weight: var(--Semibold);
}

.demo .modal-brand .selectInputbox>input:checked+label::before {
    background-color: var(--ColorTypeA);
}

.demo .modal-brand .selectInputbox>input:checked+label>.btnBasic {
    background-color: var(--ColorTypeD);
    border-color: var(--ColorTypeD);
}

.demo .offcanvas-weekmenu {
    padding: 1.5rem 2rem;
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/week_img.png) no-repeat calc(100% - 1.5rem) top / auto 75% #f7f7f7;
}

.demo .offcanvas-weekmenu>a {
    display: flex;
    align-items: center;
    gap: 0 0.75rem;
}

.demo .offcanvas-weekmenu .txt {
    max-width: 135px;
}

.demo .offcanvas-weekmenu .arrow {
    max-width: 20px;
    margin-bottom: 3px;
}

.demo .modal-content {
    border-radius: 5px;
}

.demo .modal-header {
    padding: 2rem 1.5rem 0;
}

.demo .modal-title {
    line-height: 1.3;
}

.demo .modal-body {
    padding: 1rem 0.5rem;
    font-weight: var(--Regular);
}

@media screen and (max-width:599px) {

    .demo .navbar-brand {
        gap: 0 0.5rem;
    }

    .demo .navbar-brand>.brand-txt {
        font-size: 17px;
        max-width: calc(100% - 35px - 2rem);
    }

    .demo .navbar-brand::after {
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 0.1rem;
    }

    .demo .Navbar-Toggler {
        width: 26px;
    }

    .demo .Footer .footer-infobox .footer-img {
        width: 100px;
    }

    .demo .Footer .infobox1 .tit {
        font-size: 14px;
        width: 30%;
    }

    .demo .Footer .infobox1 .desc {
        font-size: 14px;
    }

    .demo .Footer .infobox2 .call-num {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .demo .Footer .infobox2 .call-mail {
        font-size: 15px;
    }

    .demo .Footer .infobox3 .infobox3-nav>li {
        font-size: 14px;
        margin-right: 1.25rem;
        padding-right: 1.25rem;
    }

    .demo .modal-brand .modal-content {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .demo .modal-brand .modal-content .arrowDown {
        padding: 0.25rem 0;
    }

    .demo .modal-brand .modal-content .arrowDown img {
        width: 18px;
    }

    .demo .modal-brand .modal-body {
        margin-top: 1.25rem;
        min-height: 10rem;
        gap: 1rem 0;
    }

    .demo .modal-brand .selectInputbox>label {
        padding: 0 1.5rem;
        font-size: 20px;
    }

    .demo .modal-brand .selectInputbox>label::before {
        width: 5px;
    }

    .demo .modal-brand .selectInputbox>label>.btnBasic {
        font-size: 15px;
        padding: 0.15rem 1rem;
    }

    .demo .offcanvas-weekmenu {
        padding: 1rem 2rem;
    }

    .demo .offcanvas-weekmenu>a {
        gap: 0 0.5rem;
    }

    .demo .offcanvas-weekmenu .txt {
        max-width: 100px;
    }

    .demo .offcanvas-weekmenu .arrow {
        max-width: 15px;
    }

    .demo .modal-body {
        padding-bottom: 0.5rem;
    }

}


@media screen and (max-width:320px) {

    .demo .Footer .infobox1 .tit {
        font-size: 12px;
    }

    .demo .Footer .infobox1 .desc {
        font-size: 12px;
    }

    .demo .Footer .infobox2 .call-tit {
        font-size: 18px;
    }

    .demo .Footer .infobox2 .call-num {
        font-size: 20px;
    }

    .demo .Footer .infobox2 .call-desc {
        font-size: 13px;
    }

    .demo .Footer .infobox3 .infobox3-nav>li {
        font-size: 12px;
    }

    .demo .modal-brand .modal-body {
        margin-top: 1rem;
        min-height: 8rem;
    }

    .demo .modal-brand .selectInputbox>label {
        font-size: 18px;
    }

    .demo .modal-brand .selectInputbox>label>.btnBasic {
        font-size: 13px;
    }

}