.Lsidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.Lsidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #f1f1f1;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.Lsidenav a:hover {
  color: #f1f1f1;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

.Rsidenav {
  height: 100%; /* 100% Full-height */
  /* width는 그대로 하되, left값 조정해서 토글되도록 변경 - 2023.06.30 */
  width: 250px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 999; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  left: 100vw;
  background-color: #ffffff; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  padding-bottom: 90px;
  transition: left 0.35s;
  /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.Rsidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #000000;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.Rsidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.closebtn {
  position: fixed;
  top: 0;
  right: 25px;
  font-size: 36px !important;
  margin-left: 50px;
  color: #000000;
  display: none;
}

.NavBackground {
  position: fixed; /* Stay in place */
  width: 100%;
  height: 100%;
  z-index: 1032;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-x: hidden; /* Disable horizontal scroll */
  /* margin-top:-42px; */
  top: 0;
  left: 0;
  transition: background-color 0.3s;
}
/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .Lsidenav {
    padding-top: 15px;
  }
  .Lsidenav a {
    font-size: 18px;
  }

  .Rsidenav {
    padding-top: 15px;
  }
  .Rsidenav a {
    font-size: 18px;
  }
}
